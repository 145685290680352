import { AppleAuthModes, ExpertiseID, GoogleAuthModes, IAppleAuthRes, IGoogleAuthBody, IGoogleAuthRes } from "../../Interfaces";
import { RefferalHandler, URLReferrerHandler, UtmCampainHandler } from "../../Utils/refferalHandler";
import { ytJobsApi } from "../clients";
import { PaginateQueryResponse } from "../interfaces";
import { GetChannelDetailResponse, IMatching } from "../interfaces/Channel";
import { GetTalentVideosListResponse } from "../interfaces/Talent";

//if we want to get all channels we don't have to add filter
export const getChannelsList = async ({
  limit,
  filter,
}: {
  limit: number;
  filter?: {
    isVerified: boolean;
    hasJob: boolean;
  };

}) => {
  const { data } = await ytJobsApi.get(`/companies`, {
    params: {
      limit,
      filter,
    },
  });

  return data;
};
// Matchings
export const getMatchingList = async ({
  limit,
  page,
  filter
}: {
  page: number;
  limit: number;
  filter?: Record<string, number | boolean | string>
}) => {
  const { data } = await ytJobsApi.get<PaginateQueryResponse<IMatching>>(`/matchings`, {
    params: {
      limit,
      page,
      filter: Object.getOwnPropertyNames(filter || {}).length ? filter : undefined
    }
  });
  return data;
};
export const getChannelDetail = async (
  id: string | undefined
): Promise<GetChannelDetailResponse> => {
  if (!id) throw new Error("Id not found");
  const { data } = await ytJobsApi.get(`/companies/${id}`);
  return data;
};
export const getCompanyCustomConfig = async (): Promise<{ showNPS: boolean }> => {
  const { data } = await ytJobsApi.get(`/custom-configs`);
  return data;
};
export const sendMessage = async ({
  message, talentId, jobId, subject
}: { message: string; subject: string; talentId: string; jobId?: string; }): Promise<{ success: boolean; message: string }> => {
  const body: { message: string; subject: string; talentId: string; jobId?: string; } = {
    message, talentId, subject
  }
  if (jobId) {
    body.jobId = jobId
  }
  const { data } = await ytJobsApi.post(`/company/messaging/send-message-talent`, body);
  return data;
};
export const GoogleAuthUtil = {
  url: `auth/oauth/auth-link`,
  modes: GoogleAuthModes,
  // ***** Channel ***** \\
  companyRegister: async function () {
    const body: IGoogleAuthBody = {
      mode: this.modes.CompanyRegis,
      referringSource: RefferalHandler.GetRefferer(),
      utmCampaign: UtmCampainHandler.GetUtm(),
      firstReferer: URLReferrerHandler.GetURLRef(),
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },
  companyLogin: async function () {
    const body: IGoogleAuthBody = {
      mode: this.modes.CompanyLogin,
      referringSource: RefferalHandler.GetRefferer(),
      utmCampaign: UtmCampainHandler.GetUtm(),
      firstReferer: URLReferrerHandler.GetURLRef(),
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },
  companyVerifyYT: async function () {
    const body: IGoogleAuthBody = {
      mode: this.modes.CompanyVerifyYT,
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },

  // **** Talent **** \\
  userVerifyYT: async function (channelUrl: string) {
    const body: IGoogleAuthBody = !!channelUrl ? {
      mode: this.modes.UserVerifyYT,
      channelUrl
    } : {
      mode: this.modes.UserVerifyYT,
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },
  userRegister: async function (functionalArea: ExpertiseID) {
    const body: IGoogleAuthBody = {
      mode: this.modes.UserRegis,
      functionalArea,
      referringSource: RefferalHandler.GetRefferer(),
      utmCampaign: UtmCampainHandler.GetUtm(),
      firstReferer: URLReferrerHandler.GetURLRef(),
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },
  userLogin: async function () {
    const body: IGoogleAuthBody = {
      mode: this.modes.UserLogin,
      referringSource: RefferalHandler.GetRefferer(),
      utmCampaign: UtmCampainHandler.GetUtm(),
      firstReferer: URLReferrerHandler.GetURLRef(),
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },
  userVerifyEmail: async function () {
    const body: IGoogleAuthBody = {
      mode: this.modes.UserVerifyEmail,
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },
}
export const AppleAuthUtil = {
  url: `auth/oauth/apple/auth-link`,
  modes: AppleAuthModes,

  userRegister: async function (functionalArea: ExpertiseID) {
    const body = {
      functionalArea,
      referringSource: RefferalHandler.GetRefferer(),
      utmCampaign: UtmCampainHandler.GetUtm(),
      firstReferer: URLReferrerHandler.GetURLRef(),
    };
    const { data } = await ytJobsApi.post<IAppleAuthRes>(this.url, body);
    return data;
  },
  userLogin: async function () {
    const body = {
      referringSource: RefferalHandler.GetRefferer(),
      utmCampaign: UtmCampainHandler.GetUtm(),
      firstReferer: URLReferrerHandler.GetURLRef(),
    };
    const { data } = await ytJobsApi.post<IAppleAuthRes>(this.url, body);
    return data;
  },
}
// Channel Video List
export const getChannelVideoList = async ({
  page,
  limit,
  channelId,
  filter
}: {
  page: number;
  limit: number;
  channelId: string | undefined;
  filter: string | undefined;
}): Promise<GetTalentVideosListResponse> => {
  const { data } = await ytJobsApi.get<GetTalentVideosListResponse>(
    `/companies/${channelId}/videos?`,
    {
      params: {
        limit,
        search: "",
        page,
        filter
      },
    }
  );
  return data;
};

// channel-support
export const sendSupportReq = async (body: string, type: string): Promise<{ success: boolean; message: string }> => {
  const { data } = await ytJobsApi.post(`/support-requests`, {
    body,
    url: window.location.href,
    type
  });
  return data;
};
