import styled from 'styled-components/macro';
import { deviceMin } from '../../../../../consts/device';
import { BsFillXCircleFill, /*BsFillCheckCircleFill*/ } from 'react-icons/bs';

export const NewVerifiedIcon = ({...props}) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="8" fill="#5DD891"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.65879 9.03386C8.46834 9.03386 10.8742 9.23763 10.8742 11.0005C10.8742 12.8576 8.07084 12.969 6.86834 12.9757L6.43175 12.9757C5.1496 12.969 2.44336 12.8582 2.44336 11.0116C2.44336 9.15253 5.24674 9.04098 6.44924 9.03429L6.59371 9.03387C6.61623 9.03386 6.63794 9.03386 6.65879 9.03386ZM6.65879 9.86673C5.11576 9.86673 3.27623 10.065 3.27623 11.0116C3.27623 11.7623 4.41448 12.1432 6.65879 12.1432C8.9031 12.1432 10.0414 11.7585 10.0414 11.0005C10.0414 10.2482 8.9031 9.86673 6.65879 9.86673ZM11.7197 8.7745C13.2222 8.99938 13.537 9.6951 13.537 10.2387C13.537 10.6435 13.3621 11.1915 12.5276 11.5074C12.4793 11.5258 12.4293 11.5346 12.3799 11.5346C12.2116 11.5346 12.0534 11.4319 11.9907 11.2659C11.909 11.051 12.0173 10.81 12.2322 10.729C12.7041 10.5502 12.7042 10.332 12.7042 10.2387C12.7042 9.92386 12.3316 9.70843 11.597 9.59904C11.3693 9.56462 11.2122 9.35251 11.2461 9.12542C11.2805 8.89777 11.4965 8.74507 11.7197 8.7745ZM6.65879 2.39478C8.23069 2.39478 9.51001 3.67406 9.51001 5.24597C9.51331 6.00332 9.21959 6.71848 8.68433 7.25707C8.15018 7.79621 7.43669 8.09438 6.67767 8.09716H6.65879C5.08633 8.09716 3.80705 6.81787 3.80705 5.24597C3.80705 3.67406 5.08633 2.39478 6.65879 2.39478ZM10.5942 3.01876C11.6852 3.19755 12.477 4.13092 12.477 5.23753C12.4748 6.3508 11.6436 7.30693 10.5431 7.46129C10.5236 7.46407 10.5042 7.46518 10.4848 7.46518C10.2804 7.46518 10.1022 7.3147 10.0728 7.10649C10.0411 6.87884 10.1994 6.66784 10.427 6.63619C11.12 6.53902 11.643 5.93769 11.6441 5.23697C11.6441 4.54069 11.1455 3.95324 10.4598 3.84108C10.2327 3.80332 10.0789 3.58956 10.1161 3.36246C10.1533 3.13536 10.3682 2.98267 10.5942 3.01876ZM6.65879 3.22765C5.54552 3.22765 4.63991 4.13325 4.63991 5.24597C4.63991 6.35868 5.54552 7.26429 6.65879 7.26429H6.676C7.21126 7.26207 7.71543 7.05163 8.09355 6.67017C8.47223 6.28983 8.67933 5.784 8.67713 5.24763C8.67713 4.13325 7.77151 3.22765 6.65879 3.22765Z" fill="white" stroke="white" strokeWidth="0.5"/>
  </svg>
);

export const Container = styled.div<{status?: string}>`
  overflow: hidden;
  width: 50%;
  border-radius: 2px;
  margin-bottom: 1rem;
  position: relative;
  ${({status}) => status === 'denied' ? `border: 3px solid var(--color-primary);` : `border: 1px solid white;`}
  flex-shrink: 0;
  margin-right: 0.5rem;
  height: auto;
  min-height: 100px;
  @media ${deviceMin.mobileM} {
    height: auto;
    width: 50%;
  }
  @media ${deviceMin.mobileL} {
    height: auto;
  }
  @media ${deviceMin.tabletS} {
    width: 40%;
  }
  @media ${deviceMin.tabletM} {
    margin-left: 0.5rem;
  }
  @media ${deviceMin.tabletL} {
    margin-left: 0.5rem;
  }
  @media ${deviceMin.laptopXS} {
    min-height: unset;
    width: 31.5%;
    height: auto;
    min-height: 108px;
    margin-right: 0;
    margin-left: 0;
  }
`;

export const Thumbnail = styled.img<{status?: string}>`
  width: 100%;
  margin-bottom: -13%;
  ${({status}) => status === 'denied' && `filter: blur(3px) !important;`}
`;
export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 4px;
  z-index: 2;
  &:before {
    content: '';
    background-color: white;
    position: absolute;
    inset: 3px;
    border-radius: 50%;
    z-index: 1;
  }
`;
export const DeniedIcon = styled(BsFillXCircleFill)`
  color: var(--color-primary);
  width: 32px;
  height: 32px;
  z-index: 3;
  position: relative;
`;
export const VerifiedIconContainer = styled.div`
  padding: 3px;
  padding-right: 5px;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 11px;
  font-size: 10px;
  font-weight: bold;
  background-color: var(--color-bg2);
  color: var(--color-gray2);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.8);
  z-index: 1;
`;
export const VerifiedIcon = styled(NewVerifiedIcon)`
  color: var(--color-success);
  width: 16px;
  height: 16px;
  z-index: 3;
  position: relative;
  margin-right: 2px;
`;
export const DeniedContainer = styled.div`
  position: absolute;
  inset: 0;
  padding: 8px;
  /* background-color: #28282844; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0000007c;
  > h3 {
    color: var(--color-force-gray6);
    font-family: "Open Sans", monospace;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    @media ${deviceMin.tabletM} {
      font-size: 14px;
    }
    @media ${deviceMin.laptopXS} {
      font-size: 18px;
    }
  }
`;
