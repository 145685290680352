import { UseQueryResult } from "react-query";
import { ChannelInfo } from "../../Interfaces/Channel";
import { TalentInfo } from "../../Interfaces/Talent";
import { PaginateQueryResponse } from "../../api/interfaces";
import { MailboxUtilsHook } from "./useMailbox";


// User Type Section
export type UserSenderReciverType = 'App\\User' | 'App\\Company';
interface MailThreadItemAppUserBase<U extends TalentInfo | ChannelInfo, T extends UserSenderReciverType> {
  id: number;
  type: T;
  object: U;
}
export type MailThreadItemAppUser =
  MailThreadItemAppUserBase<TalentInfo, 'App\\User'> |
  MailThreadItemAppUserBase<ChannelInfo, 'App\\Company'>;

export type Attachment = {
  url: string;
  fileName: string;
  id: string;
  mimeType: string;
}

// Thread Item's Type
export type MailThreadItem = {
  id: number;
  replyTo: null | number;
  subject: string;
  body: string;
  repliesCount: number;
  isOutreach: boolean;
  sender: MailThreadItemAppUser;
  receiver: MailThreadItemAppUser;
  attachments: Attachment[];
  isRead: boolean;
  createdAt: string; // iso
}


// Response OF Main list
export type MailListResponse = PaginateQueryResponse<MailThreadItem>;
export type ChatUploadFileResponse = {
  success: boolean;
  attachment: Attachment;
}
export type SendMessageRequest = {
  receiverType: 'company' | 'talent';
  receiverId: string;
  message: string;
  replyTo: string;
  subject: string;
  attachments: string[];
}
export type SendMessageResponse = {
  success: boolean;
  message: string;
}



// Query Label Keys
export const QueryLabels = {
  MAILBOX_LIST: 'MAILBOX_LIST',
  MAILBOX_ITEM_DETAIL: 'MAILBOX_ITEM_DETAIL'
}
export enum ListCategories {
  Inbox = 'Inbox',
  Sent = 'Sent',
  Archive = 'Archive'
}




// Components Part
export interface ThreadListSelect {
  list: UseQueryResult<MailListResponse, unknown>;
  selectedList: Record<number, number | undefined>;
  setSelectedList: React.Dispatch<React.SetStateAction<Record<number, number | undefined>>>;
}
export interface ThreadListProps extends ThreadListSelect {
  list: UseQueryResult<MailListResponse, unknown>;
  listType: ListCategories;
  setMenuOpen: (st: boolean) => void;
  utils: MailboxUtilsHook;
}
export interface ThreadListItemProps extends ThreadListSelect {
  list: UseQueryResult<MailListResponse, unknown>;
  listType: ListCategories;
  mailThreadItem: MailThreadItem;
  utils: MailboxUtilsHook;
}
export interface ThreadDetailsProps {
  details: UseQueryResult<{ data: MailThreadItem[]; }, unknown>;
  listType: ListCategories;
}
export interface ReplyUtilProps {
  details: UseQueryResult<{ data: MailThreadItem[]; }, unknown>;
  listType: ListCategories;
  close: () => void;
  subject: string;
  myAvatar: string;
  target: MailThreadItemAppUser | undefined;
}
export interface DetailMailItemProps {
  listType: ListCategories;
  thread: MailThreadItem;
}