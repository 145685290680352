
export const handleKeyDown = (e: React.SyntheticEvent) => {
  const nativeTarget = e.nativeEvent.target as HTMLElement;
  if (nativeTarget) {
    setTimeout(() => {
      nativeTarget.style.height = '30px';
      const computed = window.getComputedStyle(nativeTarget);
      const newHeight = parseInt(computed.getPropertyValue('border-top-width'), 10)
        + parseInt(computed.getPropertyValue('padding-top'), 10)
        + nativeTarget.scrollHeight + 2
        + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
      nativeTarget.style.height = `${newHeight}px`;
    });
  }
};