import { useLocation } from "react-router-dom";
import { PageContentContainer as Container } from "../../Layout";
import PrimaryItem from "./PrimaryItem";
import SecondaryItem from "./SecondaryItem";
import { PagesWrapper, StyledLogo, SignIn, SignUp } from "./StyledComponents";
import { ToggleTheme } from "../../../Elements/ToggleTheme";
import { NewTag } from "../../../Elements/Tags/NewTag";

interface Props {
  onlyLogo: boolean;
  onlySignInUp?: boolean;
}
const NotLoginContent: React.FC<Props> = ({ onlyLogo, onlySignInUp = false }) => {
  const location = useLocation();
  return (
    <Container style={{ height: "100%" }}>
      <PagesWrapper>
        <StyledLogo />
        {!onlyLogo && (
          <>
            <PrimaryItem
              destination={"/talent/signup"}
              data-testid="menu-channel-content"
              label="Join as Talent"
            />

            {/* <SecondaryItem destination="/" isActive={location.pathname === '/'} label="Home" /> */}
            <SecondaryItem
              destination="/signup"
              isActive={location.pathname === "/job/create"}
              label="Post a job"
            />
            <SecondaryItem isActive={location.pathname.startsWith('/job/search/')} destination="/job/search/all_categories" label="Jobs" />
            <SecondaryItem isActive={location.pathname.startsWith('/talent/search/')} destination='/talent/search/all_categories?page=1' label="Talent" />
            {/* <SecondaryItem destination="/feed" label="Feed" /> */}
            {/* <SecondaryItem destination='/top-talent' label='Leaderboards' /> */}
            <SecondaryItem destination='/videos'
              className='menu-navigation-action__video'
              label={
                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  Video
                  <NewTag />
                </div>
              }
            />
            <SecondaryItem destination='/forum'
              className='menu-navigation-action__video'
              label={
                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  Forum
                  <NewTag />
                </div>
              }
            // className='force-hidden'
            />
            <div style={{ flex: 1 }} />
            <ToggleTheme />
            <SignIn destination="/login?type=t" label="Login" />
            <SignUp destination="/signup" label="Sign up" />
          </>
        )}
        {onlySignInUp && (
          <>
            <div style={{ flex: 1 }} />
            {/* <ToggleTheme /> */}
            <SignIn destination="/login?type=t" label="Login" />
            <SignUp destination="/channel/signup" label="Sign up" />
          </>
        )}
      </PagesWrapper>
    </Container>
  );
};
export default NotLoginContent;
