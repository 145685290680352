import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";



export const YTErrorCatch = () => {
  const { search } = useLocation();
  const result = useMemo(() => (new URLSearchParams(search)).get('result'), [search]);
  const message = useMemo(() => (new URLSearchParams(search)).get('message'), [search]);
  const nav = useNavigate()
  useEffect(() => {
    if (result === '403' && message === 'permission_error') {
      nav(`/channel/verified-channels-description/yt-error`)
    }
  }, [message, result, nav])
  return null;
}