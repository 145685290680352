import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import HoverPopup from 'reactjs-popup';
import { device } from '../../../../consts/device';
// import { NewVerifiedIcon } from '../../../../Components/Talent/Vitrine/VitrinePoster/Variant/StyledComponents';
import { IBadges } from '../../../../Interfaces/Talent';
import RedDiamond from '../../../../Assests/Images/talent-badges/reddiamond.png';
import Diamond from '../../../../Assests/Images/talent-badges/diamond.png';
import Gold from '../../../../Assests/Images/talent-badges/Gold.png';
import Silver from '../../../../Assests/Images/talent-badges/Silver.png';
import Pearl from '../../../../Assests/Images/talent-badges/Pearl.png';
import { useNavigate } from 'react-router-dom';
import { getVal } from '../../../../api/fetchers/Talent';

interface IProps {
  bestBadge: keyof IBadges;
  linkToDescription?: boolean;
  size?: number;
}
export const getImageFromBadgeName = (bestBadge: keyof IBadges | null, size: number) => {
  switch (bestBadge) {
    case 'redDiamond':
      return <Img src={RedDiamond} size={size} />
    case 'blueDiamond':
      return <Img src={Diamond} size={size} />
    case 'gold':
      return <Img src={Gold} size={size} />
    case 'silver':
      return <Img src={Silver} size={size} />
    case 'bronze':
      return <Img src={Pearl} size={size} />
    case 'simpleVerified':
      return <Img src={Pearl} size={size} />
    default:
      return null;
  }
}
export const getTextFromBadgeName = (bestBadge: keyof IBadges | null) => {
  switch (bestBadge) {
    case 'redDiamond':
      return 'Red Diamond Badge'
    case 'blueDiamond':
      return 'Diamond Badge'
    case 'gold':
      return 'Gold Badge'
    case 'silver':
      return 'Silver Badge'
    case 'bronze':
      return 'Pearl Badge'
    case 'simpleVerified':
      return 'Pearl Badge'
    default:
      return null;
  }
}
export const MultiLevelIcons: React.FC<IProps> = ({
  bestBadge = null,
  linkToDescription = false,
  size = 16,
}) => {
  const navigate = useNavigate();
  const smartBadge = useCallback(() => getImageFromBadgeName(bestBadge, size), [bestBadge, size])
  const smartTooltip = useCallback(() => getTextFromBadgeName(bestBadge), [bestBadge]);
  const go2Desc = useCallback(() => linkToDescription ? navigate(`/talent/badges-description`) : undefined, [navigate, linkToDescription]);
  return (
    <HoverPopup
      trigger={() => (
        <InternalLink onClick={go2Desc} link={linkToDescription}>
          {smartBadge()}
        </InternalLink>
      )}
      position='bottom left'
      arrow={true}
      on={['hover']}
    >
      <Text>{smartTooltip()}</Text>
    </HoverPopup>
  );
};


const InternalLink = styled.span<{ link?: boolean; }>`
  margin-left: 4px;
  display: inline-flex;
  /* transform: translateY(3px); */
  ${({ link }) => link && `
    cursor: pointer;
    &:hover {
      transform: scale(1.01);
    }
  `}
`;
const Text = styled.p`
  font-size: 14px;
  line-height: 28px;
  background-color: var(--color-tooltip-bg);
  color: white;
  border-radius: 10px;
  padding: 8px 16px;
  margin-left: -16px;
  position: relative;
  &:before {
    /* content: ''; */
    position: absolute;
    top: -10px;
    width: 24px;
    height: 12px;
    left: 18px;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
    background-color: var(--color-tooltip-bg);
  }
  max-width: 350px;
  @media ${device.tabletS} {
    max-width: 290px;
    margin-left: -6rem;
    &:before {
      height: 25px;
      left: 6rem;
    }
  }
`;
const Img = styled.img<{ size: number; }>`
  display: inline-flex;
  align-self: center;
  ${({ size }) => `
    width: ${size}px;
    min-width: ${size}px;
    height: ${size}px;
    min-height: ${size}px;
  `}
`
interface IPropsW {
  abvsub: string;
  linkToDescription?: boolean;
  size?: number;
}
export const convertSUBs2BadgeName = (abvsub: string): keyof IBadges => {
  const subs = getVal(abvsub);
  if (subs >= 100000000) {
    return 'redDiamond';
  }
  else if (subs >= 10000000) {
    return 'blueDiamond';
  }
  else if (subs >= 1000000) {
    return 'gold';
  }
  else if (subs >= 100000) {
    return 'silver';
  }
  else if (subs >= 10000) {
    return 'bronze';
  }
  else {
    return 'simpleVerified';
  }
}
export const numericSubs2BadgeName = (subs: number): keyof IBadges => {
  if (subs >= 100000000) {
    return 'redDiamond';
  }
  else if (subs >= 10000000) {
    return 'blueDiamond';
  }
  else if (subs >= 1000000) {
    return 'gold';
  }
  else if (subs >= 100000) {
    return 'silver';
  }
  else if (subs >= 10000) {
    return 'bronze';
  }
  else {
    return 'simpleVerified';
  }
}
export const MultiLevelIconsWrapper: React.FC<IPropsW> = ({ abvsub, linkToDescription, size }) => {
  const badegType: keyof IBadges = useMemo(() => convertSUBs2BadgeName(abvsub), [abvsub]);
  return <MultiLevelIcons bestBadge={badegType} linkToDescription={linkToDescription} size={size} />;
}
export const getTitleIconByAbvSub = (abvsub: string) => {
  const ttype = convertSUBs2BadgeName(abvsub);
  return {
    title: getTextFromBadgeName(ttype),
    icon: getImageFromBadgeName(ttype, 22)
  }
}
export const MapBestBadge2Gradian = (badgeName: keyof IBadges) => {
  const rotation = 150;
  switch (badgeName) {
    case 'redDiamond':
      return css`background: linear-gradient(${rotation}deg, #FFD8D8 0%, #FF4A4A 100%);`
    case 'blueDiamond':
      return css`background: linear-gradient(${rotation}deg, #FADAFF 0%, #E64DFF 100%);`
    case 'gold':
      return css`background: linear-gradient(${rotation}deg, #F9E4B2 0%, #E3A302 100%);`
    case 'silver':
      return css`background: linear-gradient(${rotation}deg, #E1E5EB 0%, #9A9A9E 100%);`
    case 'bronze':
      return css`background: linear-gradient(${rotation}deg, #FFE2F37c 0%, #FDC0E4 100%);`
    case 'simpleVerified':
      return css`background: linear-gradient(${rotation}deg, #FFE2F37c 0%, #FDC0E4 100%);`
    default:
      return css``;
  }
}