import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import { device } from "../../../../consts/device";
import useUser from "../../../../hooks/useUser";
import { YtVideo } from "../../../../Interfaces/Talent";
import { useMutation, useQueryClient } from "react-query";
import { AxiosErrorHandler } from "../../../../api/utils";
import { AxiosError } from "axios";
import { IoIosCloseCircle } from "react-icons/io";
import YtIcon from "../../../../Elements/YtIcons";
import { modalValueContext } from "../ModalContext";
import { YtjobsIcons } from "../../../../Interfaces";
import { GoKebabVertical } from "react-icons/go";
import Popup from "reactjs-popup";
import { Body12Style } from "../../../../Elements/Typo";
import ShowReplaceModal from "../VitrinePoster/Variant/SwapThumbnailPopup/ShowReplaceModal";
import { AiFillAppstore } from "react-icons/ai";
import useDevice from "../../../../hooks/useDevice";
import { HTMLAttributes } from "react";
import { deleteYtVideo } from "../../../../api/fetchers/User/Talent";
import {
  DeniedContainer,
  DeniedIcon,
  IconContainer as IconWrapper,
  VerifiedIcon,
  VerifiedIconContainer
} from '../../Vitrine/VitrinePoster/Variant/StyledComponents'
import { BsArrowRight } from "react-icons/bs";
import { TalentQueryLabels } from "../../../../api/interfaces/Talent";

interface Props extends HTMLAttributes<HTMLDivElement> {
  video: YtVideo;
  isEditable?: boolean;
  editPopupHandler?: React.Dispatch<React.SetStateAction<boolean>>;
}

const VideosItemForShowAll: React.FC<Props> = ({
  video,
  isEditable = false,
  editPopupHandler,
  ...props
}) => {
  const { isLaptop } = useDevice();
  const [isShowReplaceModal, setIsShowReplaceModal] = useState<boolean>(false);
  const { setDescValue, setLinkValue, setVideoId } =
    useContext(modalValueContext);
  const { refetchTalentInfo, talentInfo } = useUser();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    () =>
      deleteYtVideo({ videoIid: video?.id, talentId: talentInfo?.id || "" }),
    {
      onSuccess: () => {
        refetchTalentInfo();
        queryClient.refetchQueries([TalentQueryLabels.getInfiniteTalentVideos])
        setIsShowReplaceModal(false);
      },
      onError: (error) => {
        AxiosErrorHandler(error as AxiosError);
      },
    }
  );
  return (
    <Container id="video-item-container" {...props}>
      {isShowReplaceModal && (
        <ShowReplaceModal
          setOpen={setIsShowReplaceModal}
          originVideoId={video?.id}
        />
      )}
      {isEditable && (
        <IconContainer>
          <StyledPopup
            trigger={() => (
              <KebabMenuButton className="button">
                <KebabMenu />
              </KebabMenuButton>
            )}
            position="bottom right"
            closeOnDocumentClick
            arrow={false}
          >
            {(close: () => void) => (
              <MenuContent>
                <MenuItem
                  onClick={() => {
                    if (editPopupHandler) {
                      editPopupHandler(true);
                    }
                    close();
                    setVideoId(video?.id);
                    setLinkValue(video?.url);
                    setDescValue(video?.description || "");
                  }}
                >
                  <EditIcon variant={YtjobsIcons.edit} />
                  <span>{"Edit"}</span>
                </MenuItem>

                {isLaptop && (
                  <MenuItem
                    onClick={() => {
                      close();
                      setIsShowReplaceModal(true);
                    }}
                  >
                    <AiFillAppstore style={{ width: "1rem", height: "1rem" }} />
                    <span>{"Add to cover"}</span>
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => {
                    close();
                    mutation.mutate();
                  }}
                >
                  <CloseIcon />
                  <span>{"Delete"}</span>
                </MenuItem>
              </MenuContent>
            )}
          </StyledPopup>
        </IconContainer>
      )}
      <ImageWrapper
        href={`/videos/${video?.id}`}
        status={video.confirmationStatus}
        id="image-wrapper"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={video?.thumbnail} id="image" alt={video?.title} status={video.confirmationStatus} />
        {
          video.confirmationStatus === 'denied' && (
            <DeniedContainer className="dnver dn">
              <IconWrapper>
                <DeniedIcon />
              </IconWrapper>
              <h3>
                Video Denied by <br /> Channel
              </h3>
            </DeniedContainer>
          )
        }
        {
          video.confirmationStatus === 'verified' && (
            <VerifiedIconContainer className="dnver ver">
              <VerifiedIcon /> Verified
            </VerifiedIconContainer>
          )
        }
        <div className="hover">
          <Action>
            Play Video
            <BsArrowRight />
          </Action>
        </div>
      </ImageWrapper>
      <TopText>
        <Title>
          <LogoWrapper>
            <ChannelLogo src={video?.channelThumbnail} alt="Channel logo" />
          </LogoWrapper>
          <VideoStatics>
            {video?.abvViews} views . {video?.abvLikes} Likes
          </VideoStatics>
        </Title>
        <VideoTitle>{video?.title}</VideoTitle>
        <ChannelName>{video?.channelTitle}</ChannelName>
        <Desc>{video?.description}</Desc>
      </TopText>{" "}
    </Container>
  );
};
export default VideosItemForShowAll;
const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 28%;
  gap: 1rem;
  @media ${device.mobileL} {
    width: 100%;
  }
`;
const ImageWrapper = styled.a<{ status?: string }>`
  height: 132px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 0.75rem;
  border-radius: 4px;
  ${({ status }) => status === 'denied' ? `border: 3px solid var(--color-primary);` : `border: 1px solid white;`}
  > div.hover {
    position: absolute;
    width: 100%;
    top: 0;
    left: -100%;
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    aspect-ratio: 16/9;
    background-color: var(--color-gray2-80);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    > div.hover {
      left: 0;
    }
  }
  @media ${device.laptopXS} {
    margin-bottom: 0.5rem;
    height: 119px;
  }
  @media ${device.tabletM} {
    margin-bottom: 0.5rem;
    height: 88px;
  }
  @media ${device.mobileL} {
    margin-bottom: 0.5rem;
    height: 218px;
  }
  @media ${device.mobileM} {
    margin-bottom: 0.5rem;
    height: 191px;
  }
  @media ${device.mobileS} {
    margin-bottom: 0.5rem;
    height: 160px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: max-content;
  width: calc(100% - 4px);
  margin: 1px 2px 0 2px;
  z-index: 3;
  position: absolute;
  top: 0;
  background: rgba(5, 5, 5, 0);
  //box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  //backdrop-filter: blur(1.5px);
  //-webkit-backdrop-filter: blur(4px);
  //border: 1px solid rgba(255, 255, 255, 0.18);
`;
const KebabMenu = styled(GoKebabVertical)`
  fill: white;
  margin: 2px;
  cursor: pointer;
`;
const KebabMenuButton = styled.button`
  display: flex;
  min-height: 22px;
  height: 22px;
  min-width: 22px;
  width: 22px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: var(--color-force-gray2);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  margin: 8px;
`;
const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
  }
  // use your custom style for ".popup-content"
  &-content {
  }
`;
const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--color-force-gray2);
  color: white;
  padding: 0.5rem;
  margin-top: -1rem;
  gap: 1rem;
  border-radius: 2px;
`;
const MenuItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;

  ${Body12Style}
  color: white;
  svg {
    margin-right: 0.5rem;
  }
`;
const CloseIcon = styled(IoIosCloseCircle)`
  width: 1.25rem;
  height: 1.25rem;
  color: white;
`;
const EditIcon = styled(YtIcon)`
  width: 1.25rem;
  height: 1.25rem;
  color: white;
  z-index: 1;
`;
const Image = styled.img<{ status?: string }>`
  width: 100%;
  border-radius: 5px;
  height: 100%;
  ${({ status }) => status === 'denied' && `filter: blur(3px) !important;`}
`;
const TopText = styled.div``;
const VideoTitle = styled.h3`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-gray3);
  @media ${device.mobileL} {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0.25rem;
  }
`;
const ChannelName = styled.h3`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-gray3);
  @media ${device.mobileL} {
    margin-bottom: 0.25rem;
  }
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  @media ${device.mobileL} {
    margin-bottom: 0.5rem;
  }
`;
const VideoStatics = styled.h3`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-gray1);
`;
const LogoWrapper = styled.div`
  height: 2rem;
  min-height: 2rem;
  width: 2rem;
  min-width: 2rem;
  margin-right: 0.5rem;
`;
const ChannelLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid #dde2eb;
  box-sizing: border-box;
  border-radius: 50%;
`;
const Desc = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-gray2);
  margin-bottom: 0.25rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  @media ${device.mobileL} {
    margin-bottom: 0.25rem;
  }
`;
const Action = styled.div`
  display: flex;
  width: max-content;
  color: var(--color-white);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.022px;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  > svg {
    color: var(--color-white);
    width: 24px;
    height: 24px;
  }
`