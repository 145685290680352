import styled, { css } from 'styled-components/macro';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ModalContainer,
  ModalWrapper,
  ModalGrayBlank,
  ModalContent,
  ModalGrayDash,
} from '../../../../../Styles/ModalStyles';
import { device, deviceMin } from '../../../../../consts/device';
import { TalentInfo } from '../../../../../Interfaces/Talent';
import { useVouchesData } from './useVouchesData';
import YtIcon from '../../../../../Elements/YtIcons';
import { UserRoles, YtjobsIcons } from '../../../../../Interfaces';
import { MdiIcons } from '../../../../../Elements/MdiIcons';
import { mdiChevronDown, mdiChevronUp, mdiClose } from '@mdi/js';
import { VoucheListItem } from './VoucheListItem';
import { ReviewListItem } from './ReviewListItem';
import { Button } from '../../../../../Elements/Button';
import useUser from '../../../../../hooks/useUser';
import RecomendationPopup from '../../PopupContents/RecommendationPopup';
import { useMutation, useQueryClient } from 'react-query';
import { VouchTalent } from '../../../../../api/fetchers/User/Talent';
import { TalentQueryLabels } from '../../../../../api/interfaces/Talent';
import { AxiosErrorHandler } from '../../../../../api/utils';
import { AxiosError } from 'axios';
import { useHiddenReviewData } from './useHiddenReviewData';

interface Props {
  close: () => void;
  talentInfo: TalentInfo | undefined;
  isOwner: boolean;
  defaultTab?: 1 | 2;
}
export const VoucheReviewListModal: React.FC<Props> = ({ close, talentInfo, isOwner, defaultTab = 1 }) => {

  const { isLoading, data } = useVouchesData(talentInfo)
  const { isLoading: hiddenReviewLoading, data: hiddenReviewData } = useHiddenReviewData(!!isOwner)
  const [activeTab, setActiveTab] = useState<1 | 2>(defaultTab)
  const { role, channelInfo } = useUser()
  const [isOpenVouchModal, setIsOpenVouchModal] = useState(false)
  const [recommendation, setRecommendation] = useState('')
  const queryClient = useQueryClient()
  const [showHiddens, setShowHiddens] = useState(true)
  const [showHiddenReview, setShowHiddenReview] = useState(true)

  const isContributed = useMemo(() => {
    if (role !== UserRoles.CHANNEL && !channelInfo && !talentInfo) return false;
    return !!talentInfo?.youtubeVideos.channels.some(c => String(c.company?.id || '') === String(channelInfo?.id || '-'))
  }, [role, channelInfo, talentInfo])

  const TextForFooter = useMemo(() => {
    if (activeTab === 1) {
      return `Reviews are written by talents' clients.`
    }
    if (activeTab === 2) {
      return `Talents can vouch for other talents.`
    }
  }, [activeTab])
  const TextForFooterAction = useMemo(() => {
    if (activeTab === 1) {
      return `Review`
    }
    if (activeTab === 2) {
      return `Vouch`
    }
  }, [activeTab])

  const isActionEnabled = useMemo(() => {
    if (activeTab === 1) {
      return (role === UserRoles.CHANNEL && isContributed)
    }
    else if (activeTab === 2) {
      return (role === UserRoles.TALENT && !isOwner)
    }
    else {
      return false
    }
  }, [isContributed, isOwner, role, activeTab])

  const shownVouches = useMemo(() => data?.vouches.filter(v => !v.isHidden) || [], [data])
  const hiddenVouches = useMemo(() => data?.vouches.filter(v => v.isHidden) || [], [data])
  const hiddenReviews = useMemo(() => hiddenReviewData, [hiddenReviewData])


  const mutation = useMutation(
    () => VouchTalent({ talentId: talentInfo?.id as string, recommendationText: recommendation }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([TalentQueryLabels.getTalentDetail, talentInfo?.id]);
        queryClient.refetchQueries([TalentQueryLabels.getVouchesList, talentInfo?.id]);
        setIsOpenVouchModal(false);
      },
      onError: (error) => {
        AxiosErrorHandler(error as AxiosError);
      },
    }
  );


  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <ModalContainer style={{ zIndex: 9999999 }}>
      <ModalWrapper>
        <ModalGrayBlank
          onClick={close}
        />

        <StyledModalContent>
          <ContentWrapper>
            <ModalGrayDash />

            {(isLoading || hiddenReviewLoading) && (
              <LoadingContainer><YtIcon variant={YtjobsIcons.loading} /></LoadingContainer>
            )}

            {/* Content */}
            {!(isLoading || hiddenReviewLoading) && (
              <MainContainer>

                {/* Head */}
                <Row>
                  <ModalTitle>Reviews and Vouches</ModalTitle>
                  <Spacer />
                  <MdiIcons path={mdiClose} style={{ cursor: 'pointer' }} onClick={close} />
                </Row>

                {/* Tabs */}
                <TabRow activeTab={activeTab}>
                  <div onClick={() => setActiveTab(1)}>Client Reviews ({talentInfo?.reviews.length || 0})</div>
                  <div onClick={() => setActiveTab(2)}>Vouch ({talentInfo?.vouchesCount || 0})</div>
                </TabRow>

                {/* Content */}
                <ContentContainer style={{
                  marginBottom: showHiddens ? 'unset' : -16
                }}>
                  {/* Reviews */}
                  {activeTab === 1 && (
                    <>
                      {!isOwner && (
                        <>
                          {(!!talentInfo?.reviews.length) ? (
                            <>
                              {talentInfo?.reviews.map(r => {
                                return (
                                  <ReviewListItem
                                    key={r.createdAt.toString()}
                                    review={r}
                                    isOwner={isOwner}
                                    talentInfo={talentInfo}
                                  />
                                )
                              })}
                            </>
                          ) : (
                            <NoItem>No client reviews yet</NoItem>
                          )}
                        </>
                      )}

                      {isOwner && (
                        <>
                          {(!!talentInfo?.reviews.length || !!hiddenReviews?.length) ? (
                            <>
                              {talentInfo?.reviews.map(r => {
                                return (
                                  <ReviewListItem
                                    key={r.createdAt.toString()}
                                    review={r}
                                    isOwner={isOwner}
                                    talentInfo={talentInfo}
                                  />
                                )
                              })}
                              {(!!hiddenReviews?.length) && (
                                <>
                                  <HiddenPart
                                    onClick={() => setShowHiddenReview(s => !s)}
                                  >
                                    {showHiddens && (<MdiIcons path={mdiChevronDown} />)}
                                    {!showHiddens && (<MdiIcons path={mdiChevronUp} />)}
                                    <p>Pending reviews:</p>
                                    <div className='text-desc'>To make pending reviews visible on your profile, add the collaborative videos from their channels to your portfolio.</div>
                                  </HiddenPart>
                                  {showHiddenReview && hiddenReviews.map(r => (
                                    <ReviewListItem
                                      key={r.createdAt.toString()}
                                      review={r}
                                      isOwner={isOwner}
                                      talentInfo={talentInfo}
                                    />
                                  ))}
                                </>
                              )}
                            </>
                          ) : (
                            <NoItem>No client reviews yet</NoItem>
                          )}
                        </>
                      )}

                    </>
                  )}
                  {/* Vouches */}
                  {activeTab === 2 && !!data && (
                    <>
                      {shownVouches.map(v => {
                        return (
                          <VoucheListItem
                            key={v.id}
                            vouche={v}
                            isOwner={isOwner}
                            talentInfo={talentInfo}
                          />
                        )
                      })}
                      {(!!hiddenVouches.length) && (
                        <>
                          <HiddenPart
                            onClick={() => setShowHiddens(s => !s)}
                          >
                            {showHiddens && (<MdiIcons path={mdiChevronDown} />)}
                            {!showHiddens && (<MdiIcons path={mdiChevronUp} />)}
                            <p>Hidden vouches:</p>
                          </HiddenPart>
                          {showHiddens && hiddenVouches.map(v => (
                            <VoucheListItem
                              key={v.id}
                              vouche={v}
                              isOwner={isOwner}
                              talentInfo={talentInfo}
                            />
                          ))}
                        </>
                      )}
                      {(hiddenVouches.length === 0 && shownVouches.length === 0) && (
                        <NoItem>No vouches yet</NoItem>
                      )}
                    </>
                  )}
                </ContentContainer>

                {/* Footer */}
                <Row style={{
                  borderTop: '1px solid var(--color-gray5)',
                  paddingTop: 16
                }}>
                  <FooterText>{TextForFooter}</FooterText>
                  <Spacer />
                  <FooterButton
                    variant='PRIMARY-BLACK'
                    disabled={!isActionEnabled}
                    onClick={() => {
                      if (TextForFooterAction === 'Review') {
                        window.open(`/review/${talentInfo?.id}`, '_blank')
                      }
                      if (TextForFooterAction === 'Vouch') {
                        setIsOpenVouchModal(true)
                      }
                    }}
                  >
                    {TextForFooterAction}
                  </FooterButton>
                </Row>

              </MainContainer>
            )}

            {(isOpenVouchModal) && (
              <RecomendationPopup
                setIsOpenModal={setIsOpenVouchModal}
                mutation={mutation}
                recommendation={recommendation}
                setRecommendation={setRecommendation}
              />
            )}


          </ContentWrapper>
        </StyledModalContent>
      </ModalWrapper>
    </ModalContainer>
  );
};

const StyledModalContent = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 0px 16px 72px 16px;
  max-height: 90%;
  @media ${deviceMin.tabletL} {
    width: 606px;
    max-width: 80%;
    padding: 24px;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: fit-content;
  width: 100%;
`
const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const LoadingContainer = styled.div`
  width: 100%;
  height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`
const ModalTitle = styled.div`
  user-select: none;
  color: var(--color-gray1);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`
const Spacer = styled.div`flex: 1`

const TabRow = styled(Row) <{ activeTab: 1 | 2 }>`
  width: 100%;
  border-bottom: 1px solid var(--color-gray5);
  gap: 16px;
  justify-content: flex-start;
  margin-top: -8px;
  > div {
    color: var(--color-gray2);
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    padding: 8px;
    cursor: pointer;
    user-select: none;
  }
  ${({ activeTab }) => activeTab && css`
    > div:nth-child(${activeTab}) {
      color: var(--color-gray1);
      font-weight: 600;
      border-bottom: 3px solid var(--color-gray2);
    }
  `}
`
const ContentContainer = styled.div`
  width: 100%;
  max-height: 40vh;
  height: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media ${device.mobileL} {
    max-height: 50vh;
  }
  @media ${deviceMin.laptopXS} {
    &::-webkit-scrollbar {
      width: 6px;
      background-color: var(--color-gray5);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray4);
      border-radius: 5px;
    }
  }
`
const NoItem = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  color: var(--color-gray3);
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
`
const FooterText = styled.div`
  color: var(--color-gray1);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  user-select: none;
`
const FooterButton = styled(Button)`
  height: 32px;
`
const HiddenPart = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid var(--color-gray5);
  background: var(--color-gray6);
  padding: 4px 8px;
  gap: 4px;
  cursor: pointer;
  > svg {
    height: 20px;
    width: 20px;
  }
  > p {
    color: var(--color-gray1);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  > div {
    width: 100%;
    color: var(--color-gray2);
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
`