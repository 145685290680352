import { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import { MdiIcons } from "../../Elements/MdiIcons";
import { mdiFormatListBulleted, mdiInvoiceSendOutline, mdiTicketConfirmation } from "@mdi/js";
import { device } from "../../consts/device";



export const SpecialButtonBase = styled.div`
  width: max-content;
  padding: 4px 8px;
  height: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  transition: transform 0.2s ease;
  &:hover {
    transform: scale(1.03);
  }
  > svg {
    width: 22px;
    height: 22px;
    > path {
      fill: var(--color-force-gray1);
    }
  }
  > p {
    color: var(--color-force-gray1);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    @media ${device.mobileL} {
      font-weight: 400;
      font-size: 14px;
    }
  }
`
export const PlansAction: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <SpecialButtonBase {...props} style={{ background: 'var(--color-force-primary-light1)' }}>
      <MdiIcons path={mdiInvoiceSendOutline} />
      <p>{children}</p>
    </SpecialButtonBase>
  )
}
export const ProposalsAction: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <SpecialButtonBase {...props} style={{ background: 'linear-gradient(148deg, #FFE092 12.55%, #E3A302 86.73%)' }}>
      <MdiIcons path={mdiTicketConfirmation} />
      <p>{children}</p>
    </SpecialButtonBase>
  )
}
export const OutreachList: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <SpecialButtonBase {...props} style={{ background: 'linear-gradient(180deg, #DCF0F9 0%, #9FD5ED 98.73%)' }}>
      <MdiIcons path={mdiFormatListBulleted} />
      <p>{children}</p>
    </SpecialButtonBase>
  )
}