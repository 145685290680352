import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import { device } from "../../../consts/device";
import { YtVideo } from "../../../Interfaces/Talent";
import { HTMLAttributes } from "react";
import {
  DeniedContainer,
  DeniedIcon,
  IconContainer as IconWrapper,
  VerifiedIcon,
  VerifiedIconContainer
} from '../../../Components/Talent/Vitrine/VitrinePoster/Variant/StyledComponents';
import { BsArrowRight } from "react-icons/bs";

interface Props extends HTMLAttributes<HTMLDivElement> {
  video: YtVideo;
}

export const VideoItemForTimeline: React.FC<Props> = ({
  video,
  ...props
}) => {
  const [overflowed, setOverflowed] = useState(false);
  const [showMore, setShow] = useState(false);
  const [item, setItem] = useState<HTMLDivElement>();
  const heightRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      setItem(node);
      setOverflowed(
        node.clientWidth < node.scrollWidth
        || node.clientHeight < node.scrollHeight
      )
    }
  }, [setOverflowed, setItem]);
  useEffect(() => {
    if (!showMore && item) {
      item.scrollTo(0, 0);
    }
  }, [showMore, item]);
  return (
    <Container id="video-item-container" {...props}>
      <ImageWrapper
        href={`/videos/${video?.id}`}
        status={video.confirmationStatus}
        id="image-wrapper"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={video?.thumbnail} id="image" alt={video?.title} status={video.confirmationStatus} />
        {
          video.confirmationStatus === 'denied' && (
            <DeniedContainer>
              <IconWrapper>
                <DeniedIcon />
              </IconWrapper>
              <h3>
                Video Denied by <br /> Channel
              </h3>
            </DeniedContainer>
          )
        }
        {
          video.confirmationStatus === 'verified' && (
            <VerifiedIconContainer>
              <VerifiedIcon /> Verified
            </VerifiedIconContainer>
          )
        }
        <div className="hover">
          <Action>
            Play Video
            <BsArrowRight />
          </Action>
        </div>
      </ImageWrapper>
      <TopText>
        <Title>
          <LogoWrapper>
            <ChannelLogo src={video?.channelThumbnail} alt="Channel logo" />
          </LogoWrapper>
          <VideoStatics>
            {video?.abvViews} views . {video?.abvLikes} Likes
          </VideoStatics>
        </Title>
        <VideoTitle>{video?.title}</VideoTitle>
        <ChannelName>{video?.channelTitle}</ChannelName>
        <Desc ref={heightRef} showMore={showMore}>
          {video?.description}
          {overflowed && (
            <Seemore onClick={() => setShow(s => !s)}>{!showMore && <>...</>}<span>see {showMore ? 'less' : 'more'}</span></Seemore>
          )}
        </Desc>
      </TopText>{" "}
    </Container>
  );
};


const Container = styled.div`
  display: flex;
  position: relative;
  gap: 1rem;
  width: 100%;
  margin-bottom: 12px;
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
const ImageWrapper = styled.a<{ status?: string }>`
  height: 167px;
  overflow: hidden;
  position: relative;
  margin-bottom: 12px;
  border-radius: 4px;
  aspect-ratio: 16/9;
  min-width: fit-content;
  ${({ status }) => status === 'denied' ? `border: 3px solid var(--color-primary);` : `border: 1px solid white;`}
  > div.hover {
    position: absolute;
    width: 100%;
    top: 0;
    left: -100%;
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    aspect-ratio: 16/9;
    background-color: var(--color-gray2-80);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    > div.hover {
      left: 0;
    }
  }
  @media ${device.laptopXS} {
    margin-bottom: 0.5rem;
    height: 119px;
  }
  @media ${device.tabletM} {
    margin-bottom: 0.5rem;
    height: 88px;
  }
  @media ${device.mobileL} {
    margin-bottom: 0.5rem;
    height: unset;
    width: 100%;
  }
`;
const Image = styled.img<{ status?: string }>`
  /* min-width: 267px; */
  width: 100%;
  height: 100%;
  border-radius: 5px;
  ${({ status }) => status === 'denied' && `filter: blur(3px) !important;`}
  @media ${device.tabletL} {
    /* min-width: 140px; */
  }
  @media ${device.mobileL} {
    width: 100%;
  }
`;
const TopText = styled.div`
  height: auto;
`;
const VideoTitle = styled.h3`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-gray3);
  @media ${device.mobileL} {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0.25rem;
  }
`;
const ChannelName = styled.h3`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-gray3);
  @media ${device.mobileL} {
    margin-bottom: 0.25rem;
  }
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  @media ${device.mobileL} {
    margin-bottom: 0.5rem;
  }
`;
const VideoStatics = styled.h3`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-gray1);
`;
const LogoWrapper = styled.div`
  height: 2rem;
  min-height: 2rem;
  width: 2rem;
  min-width: 2rem;
  margin-right: 0.5rem;
`;
const ChannelLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid #dde2eb;
  box-sizing: border-box;
  border-radius: 50%;
`;
const Desc = styled.p<{ showMore?: boolean; }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-gray2);
  margin-bottom: 0.25rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  max-height: 60px;
  max-width: 440px;
  ${({ showMore }) => showMore && css`
    max-height: 5000vh;
    padding-bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray4);
    }
    > span {
      position: unset;
      width: 100%;
      text-align: right;
    }
  `}
  position: relative;
  @media ${device.mobileL} {
    margin-bottom: 0.25rem;
  }
`;
const Seemore = styled.span`
  position: absolute;
  bottom: 2px;
  right: 0px;
  padding: 0 4px;
  background-color: var(--color-bg1);
  padding-left: 4px;
  cursor: pointer;
  white-space: nowrap;
  > span {
    color: var(--color-secondary);
    margin-left: 4px;
  }
`
const Action = styled.div`
  display: flex;
  width: max-content;
  color: var(--color-white);
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.022px;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  > svg {
    color: var(--color-white);
    width: 24px;
    height: 24px;
  }
`