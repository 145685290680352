import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components/macro";
import { device, deviceMin } from '../../../consts/device';
import useUser from '../../../hooks/useUser';
import {
  ModalContainer,
  ModalWrapper,
  ModalGrayBlank,
  ModalGrayDash,
  ModalContent,
} from '../../../Styles/ModalStyles';
import { MdClose } from "react-icons/md";
import { useMessage2Talent } from "./useMessage2Talent";
import { Button } from "../../../Elements/Button";
import { handleKeyDown } from "../../../Utils/TextAreaExpander";
import { useMutation } from "react-query";
import { sendMessage } from "../../../api/fetchers/Channel";
import ErrorToast from "../../../Elements/Toast/Error";
import { AxiosError } from "axios";
import { mdiCheck } from '@mdi/js';
import { MdiIcons } from "../../../Elements/MdiIcons";
import { useLocation, useNavigate } from "react-router-dom";
import { DiscoverPurchasePopup } from "../DiscoverPurchasePopup";
import SuccessToast from "../../../Elements/Toast/Success";
import { GoogleAnalytic } from "../../../Utils/ga";
import { useQuill } from "react-quilljs";
import MagicUrl from 'quill-magic-url';
import DOMPurify from 'dompurify';
import '../../../Styles/_quil.scss';


export const Message2Talent: React.FC = () => {

  const { quill, quillRef, Quill } = useQuill(
    {
      modules: {
        toolbar: [
          [],
        ],
        magicUrl: true,
      },
      placeholder: 'e.g. I am looking for a ...',
      theme: 'snow',  // or 'bubble',
    }
  )
  if (Quill && !quill) {
    try {
      Quill.register('modules/magicUrl', MagicUrl);
      const Link = Quill.import('formats/link');
      class MyLink extends Link {
        // @ts-ignore
        static create(value) {
          const node = super.create(value);
          node.setAttribute('target', '_blank');
          return node;
        }
      }
      Quill.register(MyLink, true);
    } catch (error) {
      console.error(error)
    }
  }

  const { channelInfo, refetchChannelInfo } = useUser();
  const { close, targetUser } = useMessage2Talent();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [showSendMessageResult, setShowSMResult] = useState(false);
  const [showPurchase, setShowPurchase] = useState(false);

  const { isLoading, mutate } = useMutation(
    ({ message, talentId, jobId, subjectText }: { message: string; subjectText: string; talentId: string; jobId?: string; }) => sendMessage({
      message, talentId, jobId, subject: subjectText
    }),
    {
      onSuccess: ({ success, message }) => {
        if (success) {
          refetchChannelInfo();
          if (!!targetUser?.jobId || pathname.startsWith('/channel/discovery-list')) {
            SuccessToast('Message sent! Continue conversation in Chats if they respond.');
            close();
            return;
          }
          setShowSMResult(true);
        } else {
          ErrorToast(message ? message : 'Something went wrong!');
        }
      },
      onError: (error: AxiosError) => {
        if (error.response?.data?.message) {
          ErrorToast(error.response?.data?.message);
        } else {
          ErrorToast('Something went wrong!');
        }
      }
    }
  );
  const submitSendMessage = useCallback(() => {
    if (!quill) return;
    const hyperMessage = DOMPurify.sanitize(quill.root.innerHTML)
    mutate({
      message: hyperMessage,
      subjectText: subject,
      talentId: targetUser?.id || '',
      jobId: targetUser?.jobId
    });
  }, [mutate, subject, /*message,*/ targetUser, quill]);
  const goDiscoveryList = useCallback(() => {
    close();
    navigate('/channel/discovery-list');
  }, [navigate, close]);
  const showPurchaseContent = useCallback(() => {
    setShowPurchase(true);
    setShowSMResult(false);
  }, [setShowPurchase, setShowSMResult]);

  const hasChatQuota = useMemo(() => !!channelInfo?.chatQuota, [channelInfo]);

  const [hyperText, setHyperText] = useState('')

  useEffect(() => {
    if (!quill) return;
    quill.on('text-change', function (delta, oldDelta, source) {
      if (source == 'user') {
        setHyperText((quill?.getText() || ''))
      }
    });
  }, [quill]);


  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  useEffect(() => {
    if (!showSendMessageResult && !((hasChatQuota && !showPurchase) || !!targetUser?.jobId)) {
      GoogleAnalytic.OpenUnlockDiscoveryPopop('on-after-messaging');
    }
  }, [showSendMessageResult, hasChatQuota, showPurchase, targetUser]);

  return (
    <ModalContainer>
      <ModalWrapper>
        <ModalGrayBlank onClick={close} />
        <StyledModalContent isDHPurchaseContent={!(showSendMessageResult || (hasChatQuota && !showPurchase) || !!targetUser?.jobId)}>
          <ModalGrayDash />
          {showSendMessageResult ? (
            // Show result to channel
            <>
              <Row>
                <Title>Message Sent Successfully!</Title>
                <HSpacer />
                <CloseIcon onClick={close} />
              </Row>
              <VSpacer />
              <Row style={{ gap: 8 }}>
                <MdiIcons path={mdiCheck} />
                <Subtitle>If the talent responds, you can continue the conversation in Chats.</Subtitle>
              </Row>
              <VSpacer />
              {!!channelInfo && !channelInfo?.canSearchAdvanced && (
                <>
                  <Row style={{ gap: 8 }}>
                    <MdiIcons path={mdiCheck} />
                    <Subtitle>Free messages left: {channelInfo.chatQuota}. <span onClick={showPurchaseContent}>Unlock Discover & Hire</span> for more.</Subtitle>
                  </Row>
                  <VSpacer />
                </>
              )}
              <Row style={{ gap: 8 }}>
                <MdiIcons path={mdiCheck} />
                <Subtitle>Talent added to your Discovery List in the &ldquo;Selected&ldquo; step.</Subtitle>
              </Row>
              <VSpacer />
              <Row>
                <HSpacer />
                <Action
                  onClick={goDiscoveryList}
                >Discovery List</Action>
              </Row>
            </>
          ) : (
            <>
              {(hasChatQuota && !showPurchase) || !!targetUser?.jobId ? (
                // Show send message utils
                <>
                  {(!targetUser?.jobId && !channelInfo?.canSearchAdvanced) && (
                    <Row style={{ marginBottom: 12 }}>
                      <RemainingMessages>{channelInfo?.chatQuota} Free Messages Left <span>-</span> <b onClick={() => setShowPurchase(true)}>Extend Now!</b></RemainingMessages>
                      <HSpacer />
                    </Row>
                  )}
                  <Row>
                    <Title>Message {targetUser?.name}</Title>
                    <HSpacer />
                    {(!!targetUser?.jobId || channelInfo?.canSearchAdvanced) && (<CloseIcon onClick={close} />)}
                  </Row>
                  <VSpacer />
                  <Subtitle>
                    Tip: Your message can encompass details about the opportunity, work terms, job
                    offer, proposal, or next steps. A clear, detailed, and personalized message boosts
                    your chances of receiving a response.
                  </Subtitle>
                  <VSpacer />
                  <Label>Subject</Label>
                  <SubjectArea
                    autoFocus={true}
                    placeholder={'e.g. I am looking for a full-time Video Editor ...'}
                    onChange={(e) => e.target.value.length <= 100 && setSubject(e.target.value)}
                    value={subject}
                  />
                  <Typo>{subject?.length || 0}/100</Typo>
                  <Label>Message</Label>
                  <EditorContainer>
                    <div ref={quillRef} />
                  </EditorContainer>
                  <MessageArea
                    onFocus={handleKeyDown}
                    onKeyDown={(e: React.SyntheticEvent<HTMLTextAreaElement, KeyboardEvent>) => {
                      if (
                        ((message as string)?.length >= 1000 && e.nativeEvent.key !== 'Backspace')
                      ) {
                        e.stopPropagation();
                        e.preventDefault();
                      }
                      handleKeyDown(e);
                    }}
                    placeholder={'e.g. I am looking for a full-time Video Editor ...'}
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    style={{ display: 'none' }}
                  />
                  <Typo>{hyperText?.length || 0}/1000</Typo>
                  <Row>
                    <Button
                      onClick={close}
                      variant="NAKE"
                    >
                      Cancel
                    </Button>
                    <HSpacer />
                    <Button
                      disabled={(hyperText.replace(' ', '').replace('\n', '')).length < 4 || !subject.length || isLoading}
                      isLoading={isLoading}
                      style={{ minWidth: 167 }}
                      onClick={submitSendMessage}
                    >
                      Send Message
                    </Button>
                  </Row>
                </>
              ) : (
                // Show Purchase Content
                <>
                  <PurchaseContainer>
                    <DiscoverPurchasePopup
                      isModal={false}
                      isCloseAble={true}
                      close={close}
                      subtitle={hasChatQuota ? undefined : `You've used all your free messages.\nTo explore more talents and streamline your hiring process, unlock Discover & Hire. Activate it for 30 days to efficiently connect with and evaluate potential team members.`}
                    />
                  </PurchaseContainer>
                </>
              )}
            </>
          )}
        </StyledModalContent>
      </ModalWrapper>
    </ModalContainer>
  );
};

const EditorContainer = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  > .ql-toolbar {
    display: none !important;
  }
  .ql-editor {
    border: 0.5px solid var(--color-gray4);
    border-radius: 16px;
    border-radius: 10px;
    padding: 8px 16px;
    &:focus {
      border: 0.5px solid var(--color-gray3);
    }
    &::before {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: var(--color-gray4);
      padding: 1px 16px;
    }
    > p {
      color: var(--color-gray1);
      font-size: 16px;
      font-weight: 600;
      font-family: "Open Sans", "Poppin", "Helvetica", "Arial", "sans-serif";
    }
    @media ${deviceMin.desktop} {
      &::-webkit-scrollbar {
        width: 8px;
        background-color: var(--color-gray5);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--color-gray4);
        border-radius: 5px;
      }
    }
    @media ${device.tabletS} {
      min-height: 250px;
      max-height: 250px;
      > p {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  @media ${device.tabletS} {
    min-height: 250px;
  }
`

const StyledModalContent = styled(ModalContent) <{ isDHPurchaseContent?: boolean }>`
  width: 550px;
  ${({ isDHPurchaseContent }) => isDHPurchaseContent && css`width: 1140px;`}
  padding: 1.5rem 1.25rem 1.5rem;
  max-height: 90vh;
  overflow-y: auto;
  @media ${device.mobileL} {
    padding: 1rem 1rem 7rem;
    width: 100%;
  }
`;
const Title = styled.h2`
  color: var(--color-gray1);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const Subtitle = styled.p`
  color: var(--color-gray2);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  > span {
    font-weight: 600;
    color: var(--color-primary);
    text-decoration-line: underline;
    cursor: pointer;
  }
`;
const CloseIcon = styled(MdClose)`
  width: 24px;
  height: 24px;
  color: var(--color-gray1);
  cursor: pointer;
  @media ${device.mobileL} {
    display: none;
  }
`
const Row = styled.div`
  display: flex;
  align-items: center;
`
const HSpacer = styled.div`
  flex: 1;
`
const VSpacer = styled.div`
  min-height: 16px;
`
const Label = styled.label`
  margin-bottom: 8px;
  color: var(--color-gray2);
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
`;
const inputStyles = css`
  line-height: 30px;
  margin-bottom: 0;
  width: 100%;
  min-height: 40px;
  color: var(--color-gray1);
  resize: none;
  font-size: 16px;
  font-weight: 600;
  font-family: "Open Sans", "Poppin", "Helvetica", "Arial", "sans-serif";
  border: 0.5px solid var(--color-gray4);
  border-radius: 10px;
  padding: 8px 16px;
  ::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-gray4);
  }
  &:focus {
    border: 0.5px solid var(--color-gray3);
  }
`
const SubjectArea = styled.input`
  ${inputStyles}
`;
const MessageArea = styled.textarea`
  ${inputStyles};
  min-height: 160px;
  @media ${deviceMin.desktop} {
    &::-webkit-scrollbar {
      width: 6px;
      background-color: var(--color-gray5);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray4);
      border-radius: 5px;
    }
  }
`;
const Typo = styled.p`
  font-size: 12px;
  color: var(--color-gray3);
  margin-top: 4px;
  margin-bottom: 1rem;
`;
const Action = styled(Button)`
  @media ${device.mobileL} {
    width: 100%;
  }
`
const PurchaseContainer = styled.div`
  > div {
    position: unset;
    transform: unset;
    padding: 0;
    box-shadow: unset;
    border: 0;
    max-height: unset;
    overflow: unset;
    > p {
      white-space: pre-line;
    }
  }
`
const RemainingMessages = styled.div`
  display: flex;
  width: max-content;
  white-space: nowrap;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--color-gray6);
  color: var(--color-gray1);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  cursor: default;
  > b {
    color: var(--color-secondary);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
  }
  > span {
    margin: 0 4px;
  }
`

