import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"

// this component should be in the BrowserRouter tag
export const Scroll2Top = () => {
  const { pathname, search } = useLocation();
  const KeyString = useRef<string>('');

  useEffect(() => {
    if (KeyString.current !== (pathname + search)) {
      if (pathname === '/forum' && KeyString.current && KeyString.current.startsWith('/forum/post')) {
        const postId = KeyString.current.split('/').pop();
        const element = document.getElementById(`forum--post-item--${postId}`) as HTMLDivElement;
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 100)
        }
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
      KeyString.current = (pathname + search);
    }
  }, [pathname, search])

  return null;
}