import React, { HTMLAttributes } from "react";
import styled from "styled-components/macro";
import { CenteredLoading } from "../../CenteredLoading";
import useUser from "../../../hooks/useUser";
import { Link } from "react-router-dom";
import { device } from "../../../consts/device";
import ChannelVerifiedIcon from "../../../Elements/Channel/ChannelVerifiedIcon";
import { MdiIcons } from "../../../Elements/MdiIcons";
import { mdiOpenInNew } from "@mdi/js";

interface Props extends HTMLAttributes<HTMLAnchorElement> {
  avatar: string;
  channelName: string;
  abvSubscribers: string | null;
  viewDirection: "VERTICAL" | "HORIZENTAL";
  isClickable?: boolean;
  channelId?: string;
  isVerified?: boolean;
  isCompany?: boolean;
  forceShowLink?: boolean;
  externalLink?: string;
}
const Type1: React.FC<Props> = ({
  isClickable = false,
  channelId,
  avatar,
  channelName,
  abvSubscribers,
  viewDirection,
  isCompany = false,
  forceShowLink = false,
  externalLink = '',
  ...props
}) => {
  const { isLoadingTalent } = useUser();

  return (
    <Container
      viewDirection={viewDirection}
      to={isClickable && channelId ? `/channel/${channelId}` : ""}
      {...props}
    >
      {(isLoadingTalent && !props.className?.includes('no-loading')) ? (
        <CenteredLoading />
      ) : (
        <>
          <ChannelLogo src={avatar} alt="channel logo" />
          {viewDirection === "HORIZENTAL" ? (
            <Texts>
              <ChannelNameContainer>
                <ChannelName id="channel-name">{channelName}</ChannelName>
                {props?.isVerified === true && <ChannelVerifiedIcon />}
              </ChannelNameContainer>
              {(!isCompany || forceShowLink) && (
                <Subscribers id="channel-subscriber">
                  {abvSubscribers ? abvSubscribers : "?"} subscribers
                  {forceShowLink && (
                    <a href={externalLink} target="_blank" rel="noreferrer"><StyledMdi path={mdiOpenInNew} /></a>
                  )}
                </Subscribers>
              )}
            </Texts>
          ) : (
            !isCompany && <Subscribers>{abvSubscribers ? abvSubscribers : "?"} </Subscribers>
          )}
        </>
      )}
    </Container>
  );
};
export default Type1;
const Container = styled(Link) <{ viewDirection: "VERTICAL" | "HORIZENTAL" }>`
  display: flex;
  text-align: center;
  width: 100%;
  margin-right: 0.5rem;
  ${({ viewDirection }) =>
    viewDirection === "VERTICAL" &&
    `flex-direction: column;
    align-items: center;`}
`;
const Texts = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;
const ChannelName = styled.h4`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-gray1);
  text-align: left;
  margin-right: 4px;
  margin-top: -3px;
`;
const Subscribers = styled.h3`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  color: var(--color-gray3);
  display: flex;
  align-items: center;
  gap: 8px;
  > a {
    display: flex;
    width: max-content;
    align-items: center;
  }
`;
const StyledMdi = styled(MdiIcons)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  > path {
    fill: var(--color-gray3);
  }
`
const ChannelLogo = styled.img`
  object-fit: contain;
  border: 1px solid #dde2eb;
  box-sizing: border-box;
  border-radius: 50%;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  margin-right: 0.5rem;
  @media ${device.mobileL} {
    margin: 0.3rem;
  }
`;
const ChannelNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
