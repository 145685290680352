import { FC, ReactElement, useCallback, useState } from "react";
import {
  Caption,
  ChannelContainer,
  ChannelName,
  Header,
  Img,
  Row,
  TitleSection,
} from '../../../Components/Talent/Detail/ProfileContent/StyledComponents';
import { getAvatarUrl } from "../../../Utils";
import { GetTalentDetailNotSuspended } from "../../../api/interfaces/Talent";
import { MultiLevelIcons } from "../../../Elements/Talent/Badges/Verified/MultiLevelIcons";
import logoPlaceholder from '../../../Assests/Images/logoPlaceholder';
import { convertExpertidToJobTitle } from "../../../Utils/job";
import Skeleton from '../../../Elements/Skeleton';
import styled, { css } from "styled-components";

interface Props {
  talentDetail: GetTalentDetailNotSuspended | undefined;
  isLoading: boolean;
}

export const TimelineHeader: FC<Props> = ({ talentDetail, isLoading }) => {

  return (
    <>

      {isLoading && (
        <Header>
          <Row>
            <Img src={logoPlaceholder} alt={`logoPlaceholder`} />
            <div>
              <ChannelContainer>
                <Skeleton
                  isLoading={true}
                  style={{ width: '4rem', marginBottom: '0.5rem' }}
                />
              </ChannelContainer>
              <Skeleton isLoading={true} />
            </div>
          </Row>
        </Header>
      )}

      {!isLoading && (
        <Header>
          <Row style={{ width: 'max-content' }}>
            <Img
              src={getAvatarUrl(talentDetail?.avatar) || logoPlaceholder}
              alt={`logo of ${talentDetail?.name || ''}`}
              loading='lazy'
            />
            <div>
              <ChannelContainer>
                <TitleSection>
                  <ChannelName style={{ display: 'flex' }}>
                    {talentDetail?.name || ''}
                    {!!talentDetail?.bestBadge && (
                      <MultiLevelIcons bestBadge={talentDetail.bestBadge} linkToDescription={true} />
                    )}
                  </ChannelName>
                </TitleSection>
              </ChannelContainer>
              <Row style={{ marginBottom: '0', marginTop: '-0.5rem' }}>
                <Caption>
                  {convertExpertidToJobTitle(talentDetail?.functionalArea)}
                </Caption>
              </Row>
            </div>
          </Row>
        </Header>
      )}

    </>
  );

}






interface IStepProps {
  children: ReactElement;
  tag: string;
}

export const Accordion: FC<IStepProps> = ({ children, tag }) => {
  const [expanded, setExpanded] = useState(true);
  const [height, setHeight] = useState('');
  const heightRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      setHeight(`calc(${node.offsetHeight}px + 100px)`);
      const resizeObserver = new ResizeObserver(() => { 
        setHeight(`calc(${node.offsetHeight}px + 100px)`);
      });
      resizeObserver.observe(node);
    }
  }, [setHeight]);
  return (
    <Continer>
      <InnerCont expand={expanded} maxHeight={height}>
        <Title onClick={() => setExpanded(!expanded)} collapsed={!expanded}>{tag}</Title>
        <ChildContain ref={heightRef}>
          {children}
        </ChildContain>
      </InnerCont>
    </Continer>
  );
}

const Continer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 16px;
`;
const InnerCont = styled.div<{ expand: boolean, maxHeight?: string; }>`
  overflow: hidden;
  max-height: 40px;
  flex: 1;
  transition: max-height 0.3s ease-in;
  ${({ expand, maxHeight }) => expand && `
    max-height: ${!!maxHeight ? maxHeight : '100vh'};
  `}
`
const Title = styled.div<{collapsed?: boolean}>`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  cursor: pointer;
  user-select: none;
  color: var(--color-gray2);
  border-radius: 0px 16px 16px 0px;
  display: inline-flex;
  min-width: 60px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* text-transform: uppercase; */
  border-left: 2px solid var(--color-secondary);
  background: var(--color-secondary);
  color: var(--color-white);
  ${({collapsed}) => collapsed && css`
    background: var(--color-gray6);
    color: var(--color-secondary);
  `}

`
const ChildContain = styled.div`
  width: 100%;
`;