import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { UseMutationResult } from 'react-query';
import styled from 'styled-components/macro';

import { device, deviceMin } from '../../../../consts/device';
import { Button } from '../../../../Elements/Button';
import { TextArea } from '../../../../Elements/TextArea';
import { Body18Style, H3, H4Style } from '../../../../Elements/Typo';
import {
  ModalContainer,
  ModalWrapper,
  ModalGrayBlank,
  ModalGrayDash,
  ModalContent,
  ModalContentBody,
} from '../../../../Styles/ModalStyles';

interface Props {
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation: UseMutationResult<any, unknown, void, unknown>;
  recommendation: string;
  setRecommendation: React.Dispatch<React.SetStateAction<string>>;
}
const RecomendationPopup: React.FC<Props> = ({
  setIsOpenModal,
  mutation,
  recommendation,
  setRecommendation,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <ModalContainer>
      <ModalWrapper>
        <ModalGrayBlank
          onClick={() => setIsOpenModal(false)}
          data-testid='gray-popup-section'
        />
        <StyledModalContent>
          <ModalGrayDash />
          <ContentHeader>
            <Title>Add a recommendation</Title>
          </ContentHeader>
          <StyledModalContentBody>
            <CustomDesc
              value={recommendation}
              placeholder='Write your recommendation here.'
              onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
                if (e?.currentTarget?.value.length <= 500) {
                  setRecommendation(e.currentTarget.value)
                }
              }}
            />
            <Typo>{recommendation?.length || 0}/500</Typo>

            <CTAS>
              <Cancel
                variant={'NAKE'}
                onClick={() => {
                  setIsOpenModal(false);
                }}
              >
                Cancel
              </Cancel>
              <Save
                disabled={recommendation.length < 1}
                isLoading={mutation.isLoading}
                onClick={() => mutation.mutate()}
              >
                Save
              </Save>
            </CTAS>
          </StyledModalContentBody>
        </StyledModalContent>
      </ModalWrapper>
    </ModalContainer>
  );
};
export default RecomendationPopup;

const StyledModalContent = styled(ModalContent)`
  overflow: auto;
  background-color: var(--color-bg1);
  max-height: 95%;
  @media ${deviceMin.tabletS} {
    width: 35rem;
    max-width: 80%;
    padding: 2rem 2rem 3rem;
  }
`;
const StyledModalContentBody = styled(ModalContentBody)`
  @media ${device.laptopXS} {
    font-weight: normal;
    font-size: 18px;
    line-height: 31px;
    white-space: pre-line;
  }
`;
const CustomDesc = styled(TextArea)`
  font-weight: normal;
  font-size: 18px;
  line-height: 31px;
  textarea {
    ${Body18Style}
    font-weight: normal;
    font-size: 18px;
    line-height: 31px;
  }
`;
const CTAS = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  @media ${deviceMin.tabletS} {
    font-size: 18px;
    line-height: 33px;
    flex-direction: row;
  }
`;
const Cancel = styled(Button)`
  width: 100%;
  height: 3.5rem;
  @media ${deviceMin.tabletS} {
    width: 15%;
  }
`;
const Save = styled(Button)``;
const ContentHeader = styled.div`
  display: flex;
  height: 2rem;
  margin-bottom: 3rem;
`;
const Title = styled(H3)`
  margin-bottom: 3rem;
  font-weight: normal;
  color: var(--color-gray2);
  span {
    font-weight: 600;
    color: var(--color-gray1);
  }
  //desktop
  @media ${deviceMin.tabletS} {
    ${H4Style}
    line-height: 32px;
    font-weight: normal;
    color: var(--color-gray2);
  }
`;
const Typo = styled.p`
  font-size: 12px;
  color: var(--color-gray3);
  margin-bottom: 1rem;
  margin-top: 4px
`