import { isItOnLive } from "../api/utils";

export class GoogleAnalytic {
  private static FireEvent = (
    category = 'Buttons',
    action: string,
    label: string,
    value = 1
  ) => {
    // @ts-ignore
    if (window && window['gtag'] && typeof window.gtag === 'function' && isItOnLive()) {
      try {
        // // @ts-ignore
        // window.gtag('send', {
        //   hitType: 'event',
        //   eventCategory: category,
        //   eventAction: action,
        //   eventLabel: label,
        //   value,
        // });
        // @ts-ignore
        window.gtag('event', action, {
          event_category: category,
          event_label: label,
          event_value: value
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('ga event fired: ', { category, action, label, value });
    }
  };

  public static ShareJobCountFireEvent = (fromType: 'twitter' | 'fb' | 'copy' | 'discord') => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'share-job-button-clicked-by-channel',
      fromType
    );
  };
  public static ShareReviewCountFireEvent = (fromType: 'twitter' | 'fb' | 'copy' | 'discord' | 'download') => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'share-review-button-clicked-by-talent',
      fromType
    );
  };
  public static ShareBadgeCountFireEvent = (fromType: 'twitter' | 'fb' | 'copy' | 'discord' | 'download') => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'share-profile-button-clicked-by-talent',
      fromType
    );
  };
  public static ShareReelFireEvent = (fromType: 'twitter' | 'fb' | 'copy' | 'discord' | 'download') => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'share-reel-button-clicked-by-talent',
      fromType
    );
  };
  public static ShareTimelineFireEvent = (fromType: 'twitter' | 'fb' | 'copy' | 'discord' | 'download') => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'share-Timeline-button-clicked-by-talent',
      fromType
    );
  };
  public static ShareProfileCountFireEvent = (fromType: 'twitter' | 'fb' | 'copy' | 'discord' | 'download') => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'share-profile-button-clicked-by-talent-on-share-profile-page',
      fromType
    );
  };
  public static ShareFromShareCenter = (fromType: 'profile' | 'review' | 'badge' | 'copyForReview' | 'copyForBadge' | 'business-card' | 'Reel' | 'Timeline') => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      ('shareSpace-' + fromType),
      fromType
    );
  };
  public static NotificationSeen = (fromType: string) => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'notification-seen-by-talent-on-notification-center',
      fromType
    );
  };
  public static NotificationSeenChannel = (fromType: string) => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'notification-seen-by-channel-on-notification-center',
      fromType
    );
  };
  public static NotificationCallToAction = (fromType: string) => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'notification-callToAction-by-talent-on-notification-center',
      fromType
    );
  };
  public static NotificationCallToActionChannel = (fromType: string) => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'notification-callToAction-by-channel-on-notification-center',
      fromType
    );
  };
  public static AskReviewCountFireEvent = (type: 'ask-to-verify' | 'ask-to-review') => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'ask-review-or-verify-by-talent-popup-opened',
      type,
    );
  };
  public static BadgeLessUserApplyRestrictions = () => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'badgeless-restriction-modal',
      ''
    );
  };
  public static BadgeLessUserApplyLinkCopy = () => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'badgeless-apply-modal-link-copied',
      ''
    );
  };
  public static SuspiciouseVideoAdded = () => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'video-verification-suggestion-modal-showed',
      'suspiciouse video added!',
    );
  };
  public static AskLinkCopiedFireEvent = (type: 'ask-to-verify' | 'ask-to-review') => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'ask-review-or-verify-by-talent-link-copied',
      type,
    );
  };
  public static AskLinkCopiedFromBadgesDescription = () => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'ask-link-copied-badges-description',
      'ask-link-copied-badges-description'
    );
  };
  // too many attemps
  public static TooManyAttemps = () => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'too-many-attemps',
      'talent-profile',
    );
  };
  // open purchase modal on Discovery-List
  public static OpenUnlockDiscoveryPopop = (type: 'on-discovery-list' | 'on-talent-search' | 'on-discovery-talent' | 'on-after-messaging' = 'on-discovery-list') => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      `paywall-${type}`,
      type,
    );
  };
  // confirmed info modal open
  public static ConfirmedInfoModal = (byUser: string) => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'open-confirmed-info-modal-on-talent-profile',
      `open by: ${byUser}`,
    );
  };

  // Hire in ATS
  public static HireModalInATS = () => {
    GoogleAnalytic.FireEvent(
      'ATSFlow',
      'hire-button-clicked-in-offered-tab',
      ``,
    );
  };
  public static HireModalInATSAcceptSentNotify = () => {
    GoogleAnalytic.FireEvent(
      'ATSFlow',
      'submit-hire-action-with-send-notification',
      ``,
    );
  };
  public static HireModalInATSRejectSentNotify = () => {
    GoogleAnalytic.FireEvent(
      'ATSFlow',
      'submit-hire-action-with-send-notification-by-myself',
      ``,
    );
  };
  public static HireModalInATSCancel = () => {
    GoogleAnalytic.FireEvent(
      'ATSFlow',
      'cancel-hire-action',
      ``,
    );
  };

  // Some Firing events from past
  public static ScrollTalentNotification = () => {
    GoogleAnalytic.FireEvent(
      'talent-notification',
      'talent-notification-scrolled',
      `scroll`,
    );
  };
  public static ScrollChannelNotification = () => {
    GoogleAnalytic.FireEvent(
      'channel-notification',
      'channel-notification-scrolled',
      `scroll`,
    );
  };
  public static FeedScroll = () => {
    GoogleAnalytic.FireEvent(
      'feed',
      'feed-scrolled',
      `Scroll Feed Page`,
    );
  };
  public static TeammateBannerFChannel = () => {
    GoogleAnalytic.FireEvent(
      'Banners',
      'teammate-banner-clicked',
      `Teammate verification banner clicked`,
    );
  };
  public static VerifyBannerFChannel = () => {
    GoogleAnalytic.FireEvent(
      'Banners',
      'channel-verification-banner-clicked',
      `Channel verification banner clicked`,
    );
  };
  public static ChangeEmailBanner = () => {
    GoogleAnalytic.FireEvent(
      'Banners',
      'channel-change-email-banner-clicked',
      `Channel change email banner clicked`,
    );
  };
  public static clickNewYearBanner = () => {
    GoogleAnalytic.FireEvent(
      'Banners',
      'new-year-smart-banner-clicked',
      `new-year-smart-banner-clicked`,
    );
  };
  public static StartDiscussionClick = () => {
    GoogleAnalytic.FireEvent(
      'Buttons',
      'start-discussion-clicked',
      `Start Discussion Clicked`,
    );
  };
  public static HireMe = (talentId: string | undefined) => {
    GoogleAnalytic.FireEvent(
      'HireMe',
      'button-clicked',
      `Talent Id: ${talentId}`,
    );
  };
  public static Bookme = (talentId: string | undefined) => {
    GoogleAnalytic.FireEvent(
      'BookMe',
      'button-clicked',
      `Talent Id: ${talentId}`,
    );
  };


  // Cart icon on Menu for channels
  public static CartIconShown = () => {
    GoogleAnalytic.FireEvent(
      'ChannelCart',
      'cart-icon-showed',
      `Channel Id`,
    );
  };
  public static CartIconClick = () => {
    GoogleAnalytic.FireEvent(
      'ChannelCart',
      'cart-icon-clicked',
      `Channel Id`,
    );
  };
  public static CartIconModalPublishClick = () => {
    GoogleAnalytic.FireEvent(
      'ChannelCart',
      'cart-icon-modal-click-publish',
      `Channel Id`,
    );
  };
}

export const GA4LoginByUser = (type: 'talent' | 'company', id: string) => {
  const user_id = `${type === 'talent' ? 't' : 'c'}${id}`
  // @ts-ignore
  if (window && window['gtag'] && typeof window.gtag === 'function') {
    try {
      // @ts-ignore
      window.gtag('event', 'userLogin', {
        event: "userLogin",
        user_id
      });
    } catch (e) {
      console.log(e);
    }
  } else {
    console.log('ga4 userlogin fired for: ', user_id);
  }
};