import React from 'react';
import useDevice from '../../hooks/useDevice';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

interface Props {
  onlyLogo?: boolean;
  onlySignInUp?: boolean;
}
const SmartMenu: React.FC<Props> = ({ onlyLogo = false, onlySignInUp = false }) => {
  const { isLaptop } = useDevice();
  if (isLaptop) return <DesktopMenu onlyLogo={onlyLogo} onlySignInUp={onlySignInUp} />;
  return <MobileMenu onlyLogo={onlyLogo} onlySignInUp={onlySignInUp} />;
};
export default SmartMenu;
