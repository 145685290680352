import { FC, HTMLAttributes, ReactNode, useMemo, useState } from "react";
import { TalentInfo, YtVideo } from "../../../../../Interfaces/Talent";
import styled, { css } from "styled-components";
import { device } from "../../../../../consts/device";
import useDevice from "../../../../../hooks/useDevice";
import { getImageFromBadgeName } from "../../../../../Elements/Talent/Badges/Verified/MultiLevelIcons";
import { Button } from "../../../../../Elements/Button";
import { MdiIcons } from "../../../../../Elements/MdiIcons";
import { mdiShareVariant } from "@mdi/js";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Pagination, Scrollbar } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import ChannelVerifiedIcon from "../../../../../Elements/Channel/ChannelVerifiedIcon";
import { GetTalentDetailNotSuspended } from "../../../../../api/interfaces/Talent";
import ChannelThumbnail from "../../../../Channel/ChannelThumbnail";
import { isACompany } from "../../../../../Utils/channel";
import { ShareLinksPopup } from "../../PopupContents/ShareLinks";
import { useNavigate } from "react-router-dom";
import Texture from '../../../../../Assests/Images/texture/profile-video.svg';
import { ActivityBoard } from "../../../../../Pages/Talent/Timeline/ActivityBoard";
import { VoucheReviewListModal } from "./VoucheReviewList";
import { NameStickerAlert } from "../StyledComponents";
import useUser from "../../../../../hooks/useUser";
import { UserRoles } from "../../../../../Interfaces";
import VideosItemForShowAll from "../../../Vitrine/ShowAll/VideosItemForShowAll";
import { TypoSeeMore } from "../../../../../Elements/TypoWithSeeMore";


const ShareIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#5DD891" />
    <g clipPath="url(#clip0_1642_26640)">
      <path d="M26.656 8.93007L14.464 21.1221C13.9984 21.5852 13.6292 22.1361 13.3779 22.7429C13.1266 23.3497 12.9982 24.0003 13 24.6571V26.0001C13 26.2653 13.1054 26.5196 13.2929 26.7072C13.4804 26.8947 13.7348 27.0001 14 27.0001H15.343C15.9998 27.0019 16.6504 26.8735 17.2572 26.6222C17.864 26.3709 18.4149 26.0017 18.878 25.5361L31.07 13.3441C31.6544 12.7582 31.9826 11.9645 31.9826 11.1371C31.9826 10.3096 31.6544 9.5159 31.07 8.93007C30.4757 8.36197 29.6852 8.04492 28.863 8.04492C28.0408 8.04492 27.2503 8.36197 26.656 8.93007V8.93007ZM29.656 11.9301L17.464 24.1221C16.9001 24.6825 16.138 24.998 15.343 25.0001H15V24.6571C15.0021 23.8621 15.3176 23.0999 15.878 22.5361L28.07 10.3441C28.2836 10.14 28.5676 10.0262 28.863 10.0262C29.1584 10.0262 29.4424 10.14 29.656 10.3441C29.866 10.5546 29.9839 10.8398 29.9839 11.1371C29.9839 11.4344 29.866 11.7196 29.656 11.9301V11.9301Z" fill="white" />
      <path d="M31 16.979C30.7348 16.979 30.4804 17.0844 30.2929 17.2719C30.1054 17.4594 30 17.7138 30 17.979V23H26C25.2044 23 24.4413 23.3161 23.8787 23.8787C23.3161 24.4413 23 25.2044 23 26V30H13C12.2044 30 11.4413 29.6839 10.8787 29.1213C10.3161 28.5587 10 27.7957 10 27V13C10 12.2044 10.3161 11.4413 10.8787 10.8787C11.4413 10.3161 12.2044 10 13 10H22.042C22.3072 10 22.5616 9.89464 22.7491 9.70711C22.9366 9.51957 23.042 9.26522 23.042 9C23.042 8.73478 22.9366 8.48043 22.7491 8.29289C22.5616 8.10536 22.3072 8 22.042 8H13C11.6744 8.00159 10.4036 8.52888 9.46622 9.46622C8.52888 10.4036 8.00159 11.6744 8 13L8 27C8.00159 28.3256 8.52888 29.5964 9.46622 30.5338C10.4036 31.4711 11.6744 31.9984 13 32H24.343C24.9999 32.0019 25.6507 31.8735 26.2576 31.6222C26.8646 31.3709 27.4157 31.0017 27.879 30.536L30.535 27.878C31.0008 27.4149 31.37 26.864 31.6215 26.2572C31.873 25.6504 32.0016 24.9998 32 24.343V17.979C32 17.7138 31.8946 17.4594 31.7071 17.2719C31.5196 17.0844 31.2652 16.979 31 16.979ZM26.465 29.122C26.063 29.523 25.5547 29.8006 25 29.922V26C25 25.7348 25.1054 25.4804 25.2929 25.2929C25.4804 25.1054 25.7348 25 26 25H29.925C29.8013 25.5535 29.524 26.0609 29.125 26.464L26.465 29.122Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_1642_26640">
        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);


export const TalentExprienceOverview: FC<{
  talentDetail: TalentInfo
}> = ({ talentDetail }) => {
  const info = useMemo(() => {
    if (!talentDetail) return undefined;
    return {
      videos: talentDetail.youtubeVideos.statistics.counts,
      views: talentDetail.youtubeVideos.statistics.abvViews,
      likes: talentDetail.youtubeVideos.statistics.abvLikes
    }
  }, [talentDetail])
  return (
    <TalentExprienceOverviewContianer className="talent-overview">
      <div className="item">{info?.videos} Videos</div>
      <div className="divider" />
      <div className="item">{info?.views} Views</div>
      <div className="divider" />
      <div className="item">{info?.likes} Likes</div>
    </TalentExprienceOverviewContianer>
  )
}
const TalentExprienceOverviewContianer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 8px;
  border: 1px solid var(--color-gray5);
  background: var(--color-gray6);
  height: 54px;
  padding: 0 16px;
  .divider {
    width: 1px;
    height: 26px;
    background-color: var(--color-gray5);
  }
  .item {
    color: var(--color-gray1);
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
  }
  @media ${device.mobileL} {
    border-radius: 0px;
    margin-top: -16px;
    margin-left: -16px;
    width: 100vw;
    margin-bottom: 16px;
    .divider {
      height: 21px;
    }
    .item {
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
    }
  }
`

export const Viewport: FC<{
  viewType: 'mobile' | 'biggerThanMobile'
}> = ({ children, viewType }) => {
  const { isLaptop, isMobile } = useDevice();
  return (
    <>
      {viewType === 'mobile' && isMobile && (children)}
      {viewType === 'biggerThanMobile' && isLaptop && (children)}
    </>
  )
}


interface ProfileInfoSectionProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  titleComponent: ReactNode;
  onClickTitle: () => void;
}
export const ProfileInfoSection: FC<ProfileInfoSectionProps> = ({ onClickTitle, title, children, titleComponent, ...props }) => (
  <ProfileInfoSectionCont className="talent-profile-section">
    <div className="title">
      <h6 onClick={onClickTitle}>{title}</h6>
      {titleComponent}
    </div>
    <div className="content" {...props}>{children}</div>
  </ProfileInfoSectionCont>
)
const ProfileInfoSectionCont = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 16px; */
  /* padding-bottom: 16px; */
  border: 1px solid var(--color-gray5);
  border-radius: 8px;
  > div.title {
    display: flex;
    position: relative;
    height: 54px;
    width: 100%;
    padding: 0 16px;
    align-items: center;
    background-color: var(--color-gray6);
    border-bottom: 1px solid var(--color-gray5);
    border-radius: 8px 8px 0 0;
    > h6 {
      color: var(--color-gray2);
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      cursor: pointer;
      user-select: none;
      padding: 16px 0;
      flex: 1;
    }
  }
  .content {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }
  @media ${device.mobileL} {
    margin-bottom: 16px;    
  }
`




// Verified Clients Section
interface SectionProps {
  talentInfo: TalentInfo | undefined;
  isOwner: boolean;
  fromLanding?: boolean;
}
export const VerifiedClientsSections: FC<SectionProps> = ({ talentInfo }) => {

  const { isMobile } = useDevice();
  const nav = useNavigate();

  const VerifiedClients = useMemo(
    () => (talentInfo?.youtubeVideos.channels.filter(c => c.status === 'verified') || [])
    , [talentInfo])

  const TitleComponent = useMemo(() => {
    if (!talentInfo || !talentInfo.badges) return undefined;
    const badges = Object.entries(talentInfo.badges).filter((b) => !!b[1])
    if (!badges.length) return undefined
    return (
      <Row>
        {!!talentInfo.badges.redDiamond && (
          <p> {getImageFromBadgeName('redDiamond', 20)} {talentInfo.badges.redDiamond}</p>
        )}
        {!!talentInfo.badges.blueDiamond && (
          <p> {getImageFromBadgeName('blueDiamond', 20)} {talentInfo.badges.blueDiamond}</p>
        )}
        {!!talentInfo.badges.gold && (
          <p> {getImageFromBadgeName('gold', 20)} {talentInfo.badges.gold}</p>
        )}
        {!!talentInfo.badges.silver && (
          <p> {getImageFromBadgeName('silver', 20)} {talentInfo.badges.silver}</p>
        )}
        {(!!talentInfo.badges.bronze || !!talentInfo.badges.simpleVerified) && (
          <p> {getImageFromBadgeName('bronze', 20)} {(talentInfo.badges.bronze || 0) + (talentInfo.badges.simpleVerified || 0)}</p>
        )}
      </Row>
    )
  }, [talentInfo])

  return (
    <>
      <ProfileInfoSection
        title={`${VerifiedClients.length} Verified Clients`}
        titleComponent={!isMobile ? TitleComponent : undefined}
        onClickTitle={() => nav(`/talent/vitrine/${talentInfo?.id}`)}
      >
        {!!VerifiedClients.length && (
          <StyledSwiper
            modules={[Pagination, Scrollbar]}
            slidesPerView={isMobile ? 2 : 4}
            slidesPerGroup={isMobile ? 2 : 4}
            scrollbar={{ draggable: true }}
            pagination={{ clickable: true }}
            isCentered={(isMobile ? 2 : 4) >= VerifiedClients.length}
            onClick={() => nav(`/talent/vitrine/${talentInfo?.id}`)}
            lazy
          >
            {VerifiedClients.map((vc, i) => (
              <SwiperSlide key={vc.id} className="my-slider">
                <AvatarContaner
                  hasLink={!!vc.company}
                  isLast={VerifiedClients.length === i + 1}
                >
                  <img src={vc.avatar} />
                  <div>
                    <p>{vc.name}</p>
                    {vc?.company?.isVerified && <ChannelVerifiedIcon linkOnHover={true} />}
                  </div>
                  <p>{vc.abvSubscribers} subscribers</p>
                </AvatarContaner>
              </SwiperSlide>
            ))}
          </StyledSwiper>
        )}

        {!VerifiedClients.length && (
          <NoItemText>No verified clients yet</NoItemText>
        )}

      </ProfileInfoSection>
    </>
  )
}
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  > p {
    color: var(--color-gray1);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`
const StyledSwiper = styled(Swiper) <{ isCentered: boolean; hasSlides?: boolean; }>`
  width: 100%;
  margin-bottom: -10px;
  display: flex;
  justify-content: center;
  --color-of-section: var(--color-bg1);
  background-color: var(--color-of-section);
  &:hover {
    --color-of-section: var(--color-gray6);
    cursor: pointer;
    .swiper-wrapper {
      > div > div {
        background-color: var(--color-of-section);
        cursor: pointer;
      /* width: calc(100% - 24px); */
      }
    }
  }
  .swiper-pagination {
    bottom: 10px;
    margin-bottom: 10px;
    .swiper-pagination-bullet {
      background-color: var(--color-gray2);
      /* transition: transform 0.1s ease-in-out; */
      &:hover {
        transform: scale(2);
      }
    }
    .swiper-pagination-bullet-active {
      background-color: var(--color-gray2);
      &:hover {
        transform: scale(1);
      }
    }
  }
  .swiper-wrapper {
    padding: 16px 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ${({ isCentered }) => isCentered && css`justify-content: center;`}
    > div > div {
      /* width: calc(100% - 24px); */
    }
    @media ${device.mobileL} {
      ${({ isCentered }) => isCentered && css`justify-content: center;`}
    }
  }
  .swiper-slide {
    text-align: center;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    ${({ hasSlides }) => !hasSlides && css`
      margin-bottom: 16px;
      @media ${device.mobileL} {
        margin-bottom: 24px !important;
      }
    `}
    @media ${device.mobileL} {
      margin-bottom: 32px;
    }
  }
  @media ${device.mobileL} {
    .swiper-wrapper {
      > div > div {
        /* width: calc(100% - 24px); */
      }
    }
  }
`;
const NoItemText = styled.div`
  color: var(--color-gray3);
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  width: 100%;
  padding: 16px 0;
`
const AvatarContaner = styled.div<{ hasLink: boolean; isLast: boolean }>`
  width: 100%;
  height: 128px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 8px 0;
  border-right: 1px solid var(--color-gray5);
  --AvatarContaner-img-size: 72px;
  cursor: pointer;
  ${({ hasLink }) => hasLink && css`
    &:hover {
      /* cursor: pointer; */
      /* background-color: var(--color-gray6); */
    }
  `}
  ${({ isLast }) => isLast && css`
    border: 0;
  `}
  > img {
    width: var(--AvatarContaner-img-size);
    min-width: var(--AvatarContaner-img-size);
    height: var(--AvatarContaner-img-size);
    min-height: var(--AvatarContaner-img-size);
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid var(--color-gray55);
    box-shadow: 0px 0px 17px 1px #00000017
  }
  > div {
    display: flex;
    align-items: center;
    gap: 6px;
    width: 100%;
    justify-content: center;
    > p {
      color: var(--color-gray2);
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  > p {
    color: var(--color-gray3);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin-top: -8px;
  }
`




// Vouch & Review Section
export const VoucheReviewSections: FC<SectionProps> = ({ isOwner, talentInfo, fromLanding = false }) => {

  const { isMobile } = useDevice()
  const { role } = useUser()
  const [showShare, setShowShare] = useState(false);
  const [showVoucheReview, setShowVoucheReview] = useState(false);
  const [defaultTab, setDefaultTab] = useState<1 | 2>(1);

  const allReview = useMemo(() => talentInfo?.reviews, [talentInfo])

  return (
    <>
      <ProfileInfoSection
        // style={{padding: '16px 0'}}
        title={`${talentInfo?.reviews.length || 0} Client Reviews${!fromLanding ? `  |  ${talentInfo?.vouchesCount || 0} Vouch` : ``}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        titleComponent={(!talentInfo || !isOwner) ? undefined : (
          <InviteButton
            variant="PRIMARY-BLACK"
            onClick={() => setShowShare(true)}
          >
            {isMobile ? 'Invite' : 'Invite Clients to Review'}
          </InviteButton>
        )}
        onClickTitle={() => {
          setShowVoucheReview(true)
        }}
      >
        {!!allReview && !!allReview?.length && (
          <StyledSwiper
            modules={[Pagination, Scrollbar]}
            slidesPerView={isMobile ? 1 : 2.2}
            scrollbar={{ draggable: true }}
            pagination={{ clickable: true }}
            isCentered={1 >= allReview.length}
            hasSlides={!(allReview.length <= (isMobile ? 1 : 2))}
            lazy
          >
            {allReview.map((rev, i) => {
              const channel = rev.reviewer;
              return (
                <SwiperSlide
                  key={rev.createdAt.toString()}
                  className="my-slider"
                >
                  <VouchReviewContainer
                    isLast={allReview.length === i + 1}
                    onClick={(e) => {
                      e.stopPropagation()
                      setShowVoucheReview(true)
                    }}
                  >
                    <AvatarRow>
                      {!!channel && (
                        <StyledChannelThumbnail
                          variant='TYPE1'
                          channelName={channel?.name}
                          isCompany={isACompany(channel)}
                          abvSubscribers={channel?.abvSubscribers}
                          avatar={channel?.avatar}
                          viewer='OTHERS'
                          isActive={true}
                          isVerified={channel?.isVerified}
                          location='OTHER_PROFILE_PAGE'
                          channelId={channel?.id}
                          isClickable={false}
                        />
                      )}
                      {(isOwner) && (
                        <div style={{ cursor: 'pointer' }} onClick={(e) => {
                          e.stopPropagation();
                          window.open(`/talent/share-review/${talentInfo?.id}/${channel?.id}`, '_blank')
                        }}>
                          <MdiIcons path={mdiShareVariant} />
                        </div>
                      )}
                    </AvatarRow>
                    <Desc text={rev.body} />
                  </VouchReviewContainer>
                </SwiperSlide>
              )
            })}
          </StyledSwiper>
        )}

        {!!allReview && !allReview?.length && (
          <>
            <NoItemText>No client reviews yet</NoItemText>
            {!!talentInfo?.vouchesCount && (
              <InviteButton
                style={{ marginBottom: 16 }}
                variant="PRIMARY-BLACK"
                onClick={() => setShowVoucheReview(true)}
              >See Vouch{talentInfo?.vouchesCount > 1 ? 'es' : ''}</InviteButton>
            )}
            {(!talentInfo?.vouchesCount && (role === UserRoles.TALENT && !isOwner)) && (
              <InviteButton
                style={{ marginBottom: 16 }}
                variant="PRIMARY-BLACK"
                onClick={() => {
                  setDefaultTab(2)
                  setShowVoucheReview(true)
                }}
              >Vouch</InviteButton>
            )}
          </>
        )}
      </ProfileInfoSection>
      {showShare && (
        <ShareLinksPopup
          text='Send this link to your clients and invite them to acknowledge your work by writing a review. You must have at least one video from their channel on your profile.'
          link={`https://ytjobs.co/review/${talentInfo?.id}?utm_campaign=afr&utm_ref=talent`}
          title='Ask for Review'
          type='REVIEW'
          getIcon={ShareIcon}
          close={() => setShowShare(false)}
        />
      )}
      {showVoucheReview && (
        <VoucheReviewListModal
          close={() => {
            setShowVoucheReview(false)
            setDefaultTab(1)
          }}
          isOwner={isOwner}
          talentInfo={talentInfo}
          defaultTab={defaultTab}
        />
      )}
    </>
  )
}
const InviteButton = styled(Button)`
  height: 32px;
`
const VouchReviewContainer = styled.div<{ isLast: boolean; }>`
  width: 100%;
  min-height: 162px;
  height: 162px;
  border-right: 1px solid var(--color-gray5);
  padding: 0 16px;
  ${({ isLast }) => isLast && css`
    border: 0;
  `}
`
const AvatarRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const StyledChannelThumbnail = styled(ChannelThumbnail)`
  width: max-content;
  img {
    width: 48px;
    height: 48px;
  }
`;
const Desc = styled(TypoSeeMore)`
  color: var(--color-gray2);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 157.143% */
  width: 100%;
  text-align: left;
  margin-top: 8px;
  -webkit-line-clamp: 4;
  max-height: unset;
  > span {
    pointer-events: none;
    background-color: inherit;
    /* var(--color-of-section) */
    > div {
      background: linear-gradient(90deg, #00000000 0%, var(--color-of-section) 85%);
    }
    > span {
      background-color: var(--color-of-section);
    }
  }
  @media ${device.mobileL} {
    -webkit-line-clamp: 4;
  }
`






export const PortfolioSections: FC<SectionProps> = ({ isOwner, talentInfo }) => {

  const { isMobile } = useDevice()

  const navigate = useNavigate()

  const videos: (YtVideo | null)[] | undefined = useMemo(() => {
    if (!talentInfo?.youtubeVideos.videos) return undefined;
    const vs = talentInfo?.youtubeVideos.videos.slice(0, 6);
    if (vs.length === 6) return vs;
    return [...vs, ...Array(6 - vs.length).fill(null)]
  }, [talentInfo])

  const darkerIndex = useMemo(() => {
    if (!videos) return 1000;
    for (let index = 0; index < videos.length; index++) {
      if (videos[index] === null) return index;
    }
    return 1000;
  }, [videos]);

  return (
    <>
      <ProfileInfoSection
        title={`Portfolio`}
        onClickTitle={() => navigate(`/talent/vitrine/${talentInfo?.id}`)}
        titleComponent={
          (!talentInfo || !isOwner || (!((videos?.filter(Boolean).length || 0) >= 6)))
          ? (
            isOwner && (videos?.filter(Boolean).length || 0) <= 5 && (
              <div><StyledNameStickerAlert>To complete your profile, link at {(isMobile || true) && <br />}least 6 videos to your portfolio!</StyledNameStickerAlert></div>
            )
          ) : (
            <div style={{ cursor: 'pointer' }} onClick={(e) => {
              e.stopPropagation();
              window.open(`/talent/share-profile-new/${talentInfo?.id}`, '_blank')
            }}>
              <MdiIcons path={mdiShareVariant} />
            </div>
          )}
      >
        {!isMobile && (
          <GridContainer
            onClick={() => navigate(`/talent/vitrine/${talentInfo?.id}`)}
          >
            {videos?.map((v, i) => {
              return (
                <GridItem key={i} isDarker={i === darkerIndex && isOwner} hasImg={!!v}>
                  {!!v && (
                    // <img src={v.thumbnail} />
                    <VideosItemForShowAll
                      video={v}
                    />
                  )}
                  {i === darkerIndex && isOwner && (
                    <div className="add">+</div>
                  )}
                </GridItem>
              )
            })}
          </GridContainer>
        )}
        {isMobile && (
          <StyledSwiper
            modules={[Pagination, Scrollbar]}
            slidesPerView={2.3}
            spaceBetween={8}
            scrollbar={{ draggable: true }}
            pagination={{ clickable: true }}
            isCentered={false}
            onClick={() => navigate(`/talent/vitrine/${talentInfo?.id}`)}
            lazy
          >
            {videos?.map((v, i) => {
              return (
                <SwiperSlide key={i} className="my-slider">
                  <GridItem isDarker={i === darkerIndex && isOwner} hasImg={!!v}>
                    {!!v && (
                      // <img src={v.thumbnail} />
                      <VideosItemForShowAll
                        video={v}
                      />
                    )}
                    {i === darkerIndex && isOwner && (
                      <div className="add">+</div>
                    )}
                  </GridItem>
                </SwiperSlide>
              )
            })}
          </StyledSwiper>
        )}
      </ProfileInfoSection>
    </>
  )
}
const GridContainer = styled.div`
  display: grid;
  width: 100%;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  padding: 16px 16px;
  &:hover {
    cursor: pointer;
    background-color: var(--color-gray6);
  }
`
const GridItem = styled.div<{ isDarker?: boolean; hasImg?: boolean }>`
  height: unset;
  width: 100%;
  aspect-ratio: 16/9;
  background-image: url(${Texture});
  background-size: cover;
  border-radius: 4px;
  border: 1px dashed var(--color-gray3);
  backdrop-filter: blur(4px);
  background-color: var(--color-gray6);
  cursor: pointer;
  position: relative;
  ${({ isDarker }) => isDarker && css`
    background-color: var(--color-gray5);
  `}
  ${({ hasImg }) => hasImg && css`
    border-color: transparent;
    background-image: unset;
  `}
  > img {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    border-radius: 4px;
    object-fit: cover;
  }
  > div.add {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--color-gray2);
    color: var(--color-gray6);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    @media ${device.mobileL} {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }
  }
  #video-item-container {
    width: 100%;
    height: 100%;
    > a {
      margin: 0 !important;
      height: 100%;
      pointer-events: none;
      > div.hover {
        display: none;
      }
      > img {
        /* height: unset; */
        /* aspect-ratio: 16/9; */
      }
      > div:first-child {
        @media ${device.mobileL} {
          width: 24px;
          height: 24px;
          h3 {
            font-size: 12px;
          }
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    > div {
      display: none;
    }
  }

  @media ${device.mobileL} {
    #video-item-container {
      > a {
        div.dnver {
          h3 {
            font-size: 12px;
          }
          > div {
            width: 24px;
            height: 24px;
            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

`
const StyledNameStickerAlert = styled(NameStickerAlert)`
  padding: 4px 8px;
  bottom: -16px;
  left: 100px;
  transform-origin: 0 0;
  transform: rotate(-3deg);
  @media ${device.mobileL} {
    left: 100px;
    bottom: -16px;
  }
`




export const TimeLineSections: FC<SectionProps> = ({ isOwner, talentInfo }) => {

  const navigate = useNavigate()

  return (
    <>
      <ProfileInfoSection
        title={`Timeline`}
        onClickTitle={() => {
          navigate(`/talent/timeline/${talentInfo?.id}`)
        }}
        titleComponent={(!talentInfo || !isOwner) ? undefined : (
          <div style={{ cursor: 'pointer' }} onClick={(e) => {
            e.stopPropagation();
            window.open(`/talent/share-timeline/${talentInfo?.id}`, '_blank')
          }}>
            <MdiIcons path={mdiShareVariant} />
          </div>
        )}
      >
        <ActivityContianer onClick={() => {
          navigate(`/talent/timeline/${talentInfo?.id}`)
        }}>
          <ActivityBoard
            loadingUserDetail={false}
            talentDetail={talentInfo as GetTalentDetailNotSuspended}
            onlyHistoryBoard={true}
          />
        </ActivityContianer>
      </ProfileInfoSection>
    </>
  )
}
const ActivityContianer = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px 8px;
  &:hover {
    cursor: pointer;
    background-color: var(--color-gray6);
  }
  > div {
    width: unset;
  }
  .activity-empty-box {
    background-color: var(--color-gray5) !important;
  }
  .activity-row-item {
    border-color: transparent !important;
  }
`