import React, { HTMLAttributes } from 'react';

import styled from 'styled-components/macro';
import useUser from '../../hooks/useUser';
import { UserRoles } from '../../Interfaces';
import {
  Row,
  MailboxIcon,
  CopyRight,
  TwitterIcon,
  Hr,
  SectionContainer,
  StyledLink,
  Title,
  RightSide, StyledLogoMobile, RSSIcon, RowP, SimpleRow, TelegramIcon,
} from './StyledComponents';

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}
const Footer: React.FC<FooterProps> = ({ className, ...props }) => {
  const { role, channelInfo } = useUser();
  return (
    <Container {...props} className={className} id='MobileFooterContainer'>
      <SectionContainer>
        <Title>Home</Title>
        <StyledLink to='/job/search/all_categories'>Jobs</StyledLink>
        <StyledLink to='/talent/search/all_categories?page=1'>Talent</StyledLink>
        {(role === UserRoles.NOT_LOGIN || (!!channelInfo)) && (
          <StyledLink to='/forum'>Forum</StyledLink>
        )}
        <StyledLink to='/feed'>Feed</StyledLink>
        <StyledLink to='/faq'>FAQ</StyledLink>
        {
          role === UserRoles.CHANNEL && (
            <StyledLink to='/channel/referral'>Referral</StyledLink>
          )
        }
        {
          role === UserRoles.CHANNEL && !!channelInfo?.isVerified && (
            <StyledLink to='/channel/pricing'>Pricing</StyledLink>
          )
        }
      </SectionContainer>
      <SectionContainer style={{display: 'none'}}>
        <Title>Login</Title>
        <StyledLink to={role === UserRoles.CHANNEL ? '/job/create' : '/signup'}>
          Post a Job
        </StyledLink>
        <StyledLink to='/talent/signup'>Join as Talent</StyledLink>
      </SectionContainer>
      <SectionContainer>
        <Title>Jobs</Title>
        <StyledLink to='/job/search/video_editor'>
          Video Editor <Span>Jobs</Span>
        </StyledLink>
        <StyledLink to='/job/search/creative_director'>
          Creative Director <Span>Jobs</Span>
        </StyledLink>
        <StyledLink to='/job/search/thumbnail_designer'>
          Thumbnail Designer <Span>Jobs</Span>
        </StyledLink>
        <StyledLink to='/job/search/channel_manager'>
          Channel Manager <Span>Jobs</Span>
        </StyledLink>
        <StyledLink to='/job/search/youtube_strategist'>
          Youtube Strategist <Span>Jobs</Span>
        </StyledLink>
        <StyledLink to='/job/search/scriptwriter'>
          Scriptwriter <Span>Jobs</Span>
        </StyledLink>
        <StyledLink to='/job/search/youtube_producer'>
          Youtube Producer <Span>Jobs</Span>
        </StyledLink>
      </SectionContainer>
      <SectionContainer>
        <Title>Talent</Title>
        <StyledLink to='/talent/search/video_editor'>Video Editor</StyledLink>
        <StyledLink to='/talent/search/creative_director'>
          Creative Director
        </StyledLink>
        <StyledLink to='/talent/search/thumbnail_designer'>
          Thumbnail Designer
        </StyledLink>
        <StyledLink to='/talent/search/channel_manager'>
          Channel Manager
        </StyledLink>
        <StyledLink to='/talent/search/youtube_strategist'>
          Youtube Strategist
        </StyledLink>
        <StyledLink to='/talent/search/scriptwriter'>Scriptwriter</StyledLink>
        <StyledLink to='/talent/search/youtube_producer'>
          Youtube Producer
        </StyledLink>
      </SectionContainer>
      <Hr />
      <BottomSide>
        <div>
          <StyledLogoMobile variant='LIGHT' />
          <Row href='/privacy-policy'>
            <p style={{ marginTop: '0.3rem' }}>Privacy Policy</p>
          </Row>
          <Row href='/terms-and-conditions'>
            <p style={{ marginTop: '0.3rem' }}>Terms & Conditions</p>
          </Row>
          <Row href='#' className='termly-display-preferences'>
            <p style={{ marginTop: '0.3rem' }}>Consent Preferences</p>
          </Row>
        </div>
        <Links>
          <Row
            href='mailto:support@ytjobs.co'
            style={{ marginBottom: '0.7rem' }}
          >
            <MailboxIcon />
            <p>support@ytjobs.co</p>
          </Row>
          <SimpleRow style={{ gap: 13 }}>
            <RowP href='https://twitter.com/intent/follow?screen_name=yt_jobs'>
              <TwitterIcon />
            </RowP>
            <RowP href='https://t.me/yt_jobs_co'>
              <TelegramIcon />
            </RowP>
            <RowP href='https://app.ytjobs.co/feeds'>
              <RSSIcon />
            </RowP>
          </SimpleRow>
        </Links>
        <CopyRight>Copyright © {(new Date().getFullYear())} ytjobs.co, All Rights Reserved</CopyRight>
      </BottomSide>
    </Container>
  );
};
export default Footer;

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
  background: #1e1e1f;
  margin-top: auto;
`;
const BottomSide = styled(RightSide)`
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 1rem;
`;
const Links = styled.div``;

const Span = styled.span`
  color: var(--color-gray3);
`;
