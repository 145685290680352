import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { device, deviceMin } from "../../../consts/device";
import { Button } from "../../../Elements/Button";
import { H2, H4Style } from "../../../Elements/Typo";
import {
  ModalContainer,
  ModalWrapper,
  ModalGrayBlank,
  ModalContent,
  ModalGrayDash,
} from "../../../Styles/ModalStyles";
import { MdOutlineClose } from 'react-icons/md';
import { mdiCheckBold } from '@mdi/js';
import { MdiIcons } from "../../../Elements/MdiIcons";
import { useQuery } from "react-query";
import { UserRoles } from "../../../Interfaces";
import { getSearchProduct, getLinkSearchProduct, getLinkMonthProduct } from "../../../api/fetchers/User/Channel";
import useUser from "../../../hooks/useUser";
import { mdiCrown } from '@mdi/js';
import { getTalentProductList, getTalentSubscriptions, getUserProductPackges } from "../../../Pages/ChannelList/api";
import { TalentQueryLabels } from "../../../api/interfaces/Talent";
import { useBuyProductAction } from "../../../Pages/ChannelList/useData";

interface Props {
  close?: () => void;
  isCloseAble?: boolean;
  title?: string;
  subtitle?: string;
  isModal?: boolean;
  modalType?: 'old' | 'new'
}
export const DiscoverPurchasePopup: React.FC<Props> = ({
  close = () => undefined,
  isCloseAble = false,
  title,
  subtitle,
  isModal = true,
  modalType = 'old'
}) => {

  /** */
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isSubmitting1MP, setIsSubmitting1MP] = useState<boolean>(false)
  const [tab, setTab] = useState(0)
  const { role } = useUser()
  const { mutate, isLoading: isLoadingSub } = useBuyProductAction()
  const { data, isLoading } = useQuery<{
    price: number;
    discountedPrice: number;
  }>(['SEARCH_AND_HIRE_GET_PRODUCT'], () => getSearchProduct(), {
    enabled: role === UserRoles.CHANNEL
  });

  // Package Spot
  const productPackageQuery = useQuery(
    [TalentQueryLabels.get1mPackage],
    () => getUserProductPackges(),
    {
      enabled: role === UserRoles.CHANNEL
    }
  )

  // May subs
  const { data: subsList } = useQuery(
    [TalentQueryLabels.getSubscriptions],
    () => getTalentSubscriptions(),
    {
      enabled: role === UserRoles.CHANNEL
    }
  )

  // Subscription
  const query = useQuery(
    [TalentQueryLabels.getProductList],
    () => getTalentProductList(),
    {
      enabled: role === UserRoles.CHANNEL
    }
  )

  const subscriptionItem = useMemo(() => {
    if (!query.data || !Array.isArray(query.data)) return undefined
    return query.data.find(c => c.name === '3-Month Recruiter Package')
  }, [query])

  const isSubPurchased = useMemo(() => {
    if (!subsList) return true
    return subsList.subscriptions.some(s => (s.status === 'active' && s.products.some(p => p.name === '3-Month Recruiter Package')))
  }, [subsList])


  const Submit = useCallback(function () {
    setIsSubmitting(true);
    getLinkSearchProduct().then((res) => {
      if (res && res.paymentUrl) {
        window.location.href = res.paymentUrl;
      }
    }).finally(() => setIsSubmitting(false));
  }, []);

  const Submit1MonthPackage = useCallback(function () {
    setIsSubmitting1MP(true);
    getLinkMonthProduct().then((res) => {
      if (res && res.paymentUrl) {
        window.location.href = res.paymentUrl;
      }
    }).finally(() => setIsSubmitting1MP(false));
  }, []);
  /** */

  useEffect(() => {
    if (isModal) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "unset";
      };
    }
  }, [isModal]);

  const contentNew = useCallback(() => {
    return (
      <Container>
        <TabRow>
          <TabItem isActive={tab === 0} onClick={() => setTab(0)}>One-time</TabItem>
          <TabItem isActive={tab === 1} onClick={() => setTab(1)}>Subscription</TabItem>
        </TabRow>
        <NewFContainer isModal={isModal} className="purchase-modal-content">
          {tab === 0 && (
            <>
              <Card>
                <div className="title">Basic</div>
                <div className="col">
                  <div className="row">
                    <MdiIcons path={mdiCheckBold} /> <div className="text">5 messages to talents</div>
                  </div>
                  <div className="row">
                    <MdiIcons path={mdiCheckBold} /> <div className="text">Limited talent browsing</div>
                  </div>
                  <div className="row">
                    <MdiIcons path={mdiCheckBold} /> <div className="text">Discovery list & management system</div>
                  </div>
                  <div className="row">
                    <MdiIcons path={mdiCheckBold} /> <div className="text it">Great for individuals or small teams</div>
                  </div>
                </div>
                <div className="row">
                  <div className="strong">Free</div>
                  <div className="tiny">Available to<br />everyone</div>
                  <div style={{ flex: 1 }} />
                  <CardFakeButton>Current Plan</CardFakeButton>
                </div>
              </Card>
              <Card>
                <div className="title">Professional</div>
                <div className="col">
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Unlimited messages to talents</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Unlimited talent browsing</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Discovery list & management system</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Advanced talent filtering</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Discovery flow</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Bulk add to list</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text it">Perfect for growing channels</div></div>
                </div>
                <div className="row">
                  <div className="strong">${data?.discountedPrice}</div>
                  <div className="tiny">30 days access<br />No subscription</div>
                  <div style={{ flex: 1 }} />
                  <ButtonPurchase
                    variant="PRIMARY-BLACK"
                    onClick={Submit}
                    isLoading={isSubmitting || isLoading}
                    disabled={isSubmitting || isSubPurchased}
                  >Purchase Now</ButtonPurchase>
                </div>
              </Card>
              <CardBlue>
                <div className="title">{productPackageQuery.data?.name ?? '1-Month Package'}</div>
                <div className="col">
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Discover & Hire: $199</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Unlimited messages to talents</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Unlimited talent browsing</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Discovery list & management system</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Advanced talent filtering</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Discovery flow</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Bulk add to list</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">1 Job Listing: $149</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Receive direct proposals</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text it">Perfect for bigger channels and recruiters</div></div>
                </div>
                <div className="row">
                  <div className="strong mt-8">
                    <div className="h-title">Total Value: <span>${productPackageQuery.data?.price || ''}</span></div>
                    ${productPackageQuery.data?.discountedPrice || ''}
                  </div>
                  <div className="tiny">30 days access<br />No subscription</div>
                  <div style={{ flex: 1 }} />
                  <PurchaseSubscription
                    variant="NAKE"
                    onClick={Submit1MonthPackage}
                    isLoading={isSubmitting1MP || productPackageQuery.isLoading}
                    disabled={isSubmitting1MP || productPackageQuery.isLoading || isSubPurchased}
                  >Purchase Now</PurchaseSubscription>
                </div>
              </CardBlue>
            </>
          )}
          {tab === 1 && (
            <>
              <CardBlue>
                <div className="title">{subscriptionItem?.name ?? '3-Month Recruiter Package'}</div>
                <div className="col">
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Discover & Hire: 3*$199 = $597</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">3 Job Listings: 3*$149 = $447</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Receive direct proposals</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Unlimited messages to talents</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Unlimited talent browsing</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Discovery list & management system</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Advanced talent filtering</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Discovery flow</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text">Bulk add to list</div></div>
                  <div className="row"><MdiIcons path={mdiCheckBold} /> <div className="text it">Perfect for recruiters and ongoing hiring</div></div>
                </div>
                <div className="row">
                  <div className="strong mt-8">
                    <div className="h-title">Total Value: <span>${subscriptionItem?.price}</span></div>
                    ${subscriptionItem?.discountedPrice}
                  </div>
                  <div className="tiny">/quarter</div>
                  <div style={{ flex: 1 }} />
                  <PurchaseSubscription
                    variant="NAKE"
                    onClick={() => mutate(subscriptionItem?.id as string)}
                    isLoading={isLoadingSub || query.isLoading}
                    disabled={isLoadingSub || !subscriptionItem || isSubPurchased}
                  >Subscribe Now</PurchaseSubscription>
                </div>
              </CardBlue>
            </>
          )}
        </NewFContainer>
      </Container>
    )
    // eslint-disable-next-line
  }, [
    title, isCloseAble, close, isModal, Submit,
    isSubmitting, data, isLoading, subtitle, isLoadingSub,
    isSubPurchased, mutate, query.isLoading, subscriptionItem,
    tab, isSubmitting1MP, productPackageQuery.data, productPackageQuery.isLoading, Submit1MonthPackage
  ]);

  const content = useCallback(() => {
    return (
      <ContentContainer isModal={isModal} className="purchase-modal-content">
        <ModalTitle>
          <MdiIcons path={mdiCrown} />
          {title ?? 'Unlock Discover & Hire'}
          {isCloseAble ? <MdOutlineClose onClick={() => close()} /> : <svg />}
        </ModalTitle>
        <Subtitle style={{ display: 'none' }}>
          {subtitle
            ? subtitle
            : `You're on the path to hiring the best talent! Discover & Hire lets you pinpoint and connect with ideal candidates. Use it for 30 days for efficient filtering and direct engagement with potential team members.`}

        </Subtitle>

        {contentNew()}

        <ButtonContainer>
          <p> Upon payment, you agree to the <a href="/terms-and-conditions" target="_blank">Terms</a>  and <a target="_blank" href="/privacy-policy">Privacy Policy</a>.</p>
        </ButtonContainer>
      </ContentContainer>
    )
  }, [title, isCloseAble, close, isModal, subtitle, contentNew]);

  return (
    <>
      {isModal && (
        <ModalContainer>
          <ModalWrapper>
            <ModalGrayBlank onClick={() => isCloseAble && close()} />
            <StyledModalContent>
              <ModalGrayDash />
              {modalType === 'old' ? content() : contentNew()}
            </StyledModalContent>
          </ModalWrapper>
        </ModalContainer>
      )}
      {!isModal && (modalType === 'old' ? content() : contentNew())}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  z-index: 2;
  padding-top: 16px;
`
const StyledModalContent = styled(ModalContent)`
  max-height: 90%;
  overflow: auto;
  @media ${deviceMin.tabletS} {
    width: 1140px;
    max-width: 80%;
    max-height: 80%;
    padding: 24px;
  }
`;
const EaseInAnimation = keyframes`
  0% {
    top: 100%;
  }
  100% {
    top: 32px;
  }
`
const ContentContainer = styled.div<{ isModal: boolean; }>`
  width: 100%;
  max-width: 1140px;
  ${({ isModal }) => !isModal && css`
    position: absolute;
    left: 50%;
    top: 100%;
    animation: ${EaseInAnimation} 0.35s ease-in-out 0.2s forwards;
    transform: translate(-50%);
    background: var(--color-bg1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 1px 3px 11px 2px rgba(8, 8, 43, 0.16);
    border: 1px solid var(--color-gray6);
    max-height: 90vh;
    overflow: auto;
    @media ${device.mobileL} {
      position: fixed;
      z-index: 2;
      left: 0;
      right: 0;
      bottom: 0;
      transform: unset;
      top: unset;
      padding-bottom: 2.6rem;
      animation: unset;
      padding: 16px;
    }
  `}
`
const ModalTitle = styled(H2)`
  font-weight: normal;
  color: var(--color-gray2);
  font-size: 18px;
  width: 100%;
  text-align: center;
  margin-bottom: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  svg:first-child {
    margin-right: 4px;
    width: 24px;
    height: 24px;
    margin-top: -2px;
    > path {
      fill: var(--color-accent1) !important;
    }
  }
  svg:last-child {
    font-weight: 600;
    color: var(--color-gray1);
    position: absolute;
    right: 0;
    min-width: 22px;
    max-width: 22px;
    cursor: pointer;
  }
  //desktop
  @media ${deviceMin.tabletS} {
    ${H4Style};
    line-height: 32px;
    font-weight: 600;
    font-size: 22px;
    color: var(--color-gray2);
    margin-bottom: 5px;
    svg:first-child {
      margin-right: 8px;
      width: 32px;
      height: 32px;
      margin-top: -6px;
    }
  }
`;
const Subtitle = styled.p`
  color: var(--color-gray1);
  text-align: left;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* margin-bottom: 18px; */
  > a {
    cursor: pointer;
    color: var(--color-accent5);
    padding-left: 4px;
    text-decoration: underline;
  }
  @media ${deviceMin.tabletS} {
    text-align: center;
    padding: 0 16px;
    /* margin-bottom: 24px; */
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > p {
    color: var(--color-gray3);
    font-size: 11px;
    line-height: 19px;
    text-align: center;
    > a {
      text-decoration-line: underline;
    }
  }
  @media ${device.mobileL} {
  }
`;
const NewFContainer = styled.div<{ isModal: boolean; }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  width: 100%;
  padding: 20px 0;
  flex-wrap: nowrap;
  background-color: transparent;
  ${({ isModal }) => !isModal && css`
  `}

  @media ${device.mobileL} {
    flex-direction: column;
    gap: 16px;
  }
`
const Card = styled.div`
  width: 364px;
  border-radius: 16px;
  border: 1px solid var(--color-gray5);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: var(--color-bg1);
  color: var(--color-gray2);
  .title, .text, .strong, .tiny {
    color: inherit;
    &.it {
      font-style: italic;
    }
  }
  .row {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .col {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .tiny {
    color: var(--color-gray3);
    font-family: "Open Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    white-space: nowrap;
    &.bigger {
      font-weight: 600;
      font-size: 13px;
    }
  }
  .title {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  .text {
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  .strong {
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    &.mt-8 { margin-top: 8px; }
    .h-title {
      position: absolute;
      left: 0;
      bottom: 90%;
      color: var(--color-gray5);
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
      > span {
        text-decoration: line-through;
      }
    }
  }
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: #4E2D92;
    }
  }
  @media ${device.mobileL} {
    width: 100%;
    .strong {
      font-size: 32px;
    }
  }
`
const CardBlue = styled(Card)`
  border-color: transparent;
  color: var(--color-white);
  background-color: var(--color-secondary);
  box-shadow: -4px 4px 7.1px 0px rgba(30, 30, 30, 0.20);
  .tiny {
    /* color: var(--color-gray6-gray5); */
    color: var(--color-force-gray6);
  }
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: var(--color-white);
    }
  }
  .strong {
    .h-title {
      color: var(--color-force-gray6);
    }
  }
`
const CardFakeButton = styled.div`
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--color-gray5);
  color: var(--color-white);
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  user-select: none;
  pointer-events: none;
`
const ButtonPurchase = styled(Button)`
  min-width: 135px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 22px; /* 157.143% */
  text-decoration: unset;
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 42px;
  > svg > rect {
    fill: var(--color-secondary);
  }
  `
const PurchaseSubscription = styled(ButtonPurchase)`
  color: var(--color-secondary);
  background: var(--color-white);
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
`
const TabRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const TabItem = styled.div<{ isActive?: boolean; }>`
  width: max-content;
  width: 96px;
  min-width: 96px;
  height: 36px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--color-gray5);
  color: var(--color-gray4);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px; /* 221.429% */
  user-select: none;
  cursor: pointer;
  background-color: var(--color-bg1);
  ${({ isActive }) => isActive && css`
    border-bottom: 4px solid var(--color-gray1);
    color: var(--color-gray1);
  `}
`