import { HTMLAttributes } from 'react';
import Type4 from './Type4';
import Type1 from './Type1';
import Type3 from './Type3';

interface Props extends HTMLAttributes<HTMLAnchorElement> {
  isActive?: boolean;
  viewer?: 'OWNER' | 'OTHERS';
  avatar: string | File | null;
  abvSubscribers: string;
  channelName?: string;
  viewDirection?: 'VERTICAL' | 'HORIZENTAL';
  variant: 'TYPE1' | 'TYPE3' | 'TYPE4';
  location?: 'WIZARD_PAGE' | 'USER_PROFILE_PAGE' | 'OTHER_PROFILE_PAGE';
  isClickable?: boolean;
  channelId?: string;
  isVerified?: boolean;
  isCompany: boolean;
  forceShowLink?: boolean;
  externalLink?: string;
}
const ChannelThumbnail: React.FC<Props> = ({
  isClickable = false,
  channelId,
  variant,
  channelName,
  abvSubscribers,
  avatar,
  viewer,
  isActive,
  location,
  viewDirection = 'HORIZENTAL',
  isVerified = false,
  isCompany = false,
  forceShowLink = false,
  externalLink = '',
  ...props
}) => {
  switch (variant) {
    case 'TYPE1':
      return (
        <Type1
          avatar={typeof avatar === 'string' ? avatar : ''}
          channelName={channelName || ''}
          abvSubscribers={abvSubscribers}
          viewDirection={viewDirection}
          isClickable={isClickable}
          channelId={channelId}
          isVerified={isVerified}
          isCompany={isCompany}
          forceShowLink={forceShowLink}
          externalLink={externalLink}
          {...props}
        />
      );
    case 'TYPE3':
      return (
        <Type3
          abvSubscribers={abvSubscribers}
          location={location || 'OTHER_PROFILE_PAGE'}
          avatar={typeof avatar === 'string' ? avatar : ''}
          isClickable={isClickable}
          channelId={channelId}
          {...props}
        />
      );
      case "TYPE4":
        return (
          <Type4
            isActive={isActive}
            isCompany={isCompany}
            viewer={viewer}
            abvSubscribers={abvSubscribers}
            channelName={channelName || ""}
            avatar={avatar}
            isClickable={isClickable}
            channelId={channelId}
            {...props}
          />
        );
    default:
      return <div />;
  }
};
export default ChannelThumbnail;
