import styled from "styled-components/macro";
import React, { HTMLAttributes, ReactNode } from "react";
import Icon from "../../Elements/YtIcons";
import { YtjobsIcons } from "../../Interfaces";
import { Link } from "react-router-dom";
import { device } from "../../consts/device";

interface Props extends HTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  target: string;
  onClick?: () => void;
}
const WithTarget: React.FC<Props> = ({ children, target , onClick = () => undefined, ...props}) => {
  return (
    <Link style={{ width: "100%" }} to={target} onClick={onClick} {...props}>
      <Container>
        {children}
        <ArrowRight variant={YtjobsIcons.arrowRight} />
      </Container>
    </Link>
  );
};
const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArrowRight = styled(Icon)`
  align-self: center !important;
  color: white !important;
  @media ${device.mobileM} {
    width: 24px !important;
    height: 24px !important;
  }
`;
export default WithTarget;
