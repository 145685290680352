import { FC } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { device } from "../../consts/device";
import { NotificationItem, useInAppNotification } from "../../hooks/useInAppNotification";
import { getPushServiceRegisteration } from "../../Utils/firebase";

// for updating the service-worker automatically
getPushServiceRegisteration().then(reg => {
  if (reg) {
    reg.update();
  }
})


export const InAppNotification: FC = () => {

  const { notificationList, setNotificationList } = useInAppNotification();

  return ReactDOM.createPortal(
    <Container>
      {notificationList.slice(0, 3).map(n => (
        <NotificationItem key={n.reciveTime} notification={n} updater={setNotificationList}>
          <NotifItem />
        </NotificationItem>
      ))}
    </Container>,
    document.getElementById('notifications') || document.body
  );
}

const Container = styled.div`
  width: 305px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-gray4);
  }
  padding: 0 8px;
  @media ${device.mobileL} {
    width: 100vw;
    gap: 16px;
    padding: 0 16px;
  }
`
const NotifItem = styled.div`
  width: 100%;
  min-height: 100px;
  background-color: var(--color-gray6);
  border-radius: 16px;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.12);
  visibility: hidden;
`