import React from "react";
import styled from "styled-components";
import { deviceMin } from "../../consts/device";
import Skeleton from "../../Elements/Skeleton";
import { H5Style, Body16Style, Body12Style } from "../../Elements/Typo";
// import TalentBadge from "../../Elements/Talent/Badges";
import { ExpertiseID } from "../../Interfaces";
import { convertExpertidToJobTitle } from "../../Utils/job";
import UserAvatar from "../UserAvatar";
// import VerifiedByVideoIcon from "../../Elements/Talent/Badges/Verified/VerifiedByVideoIcon";
import { IBadges } from "../../Interfaces/Talent";
import { MultiLevelIcons } from "../../Elements/Talent/Badges/Verified/MultiLevelIcons";

interface CardProps extends React.HTMLAttributes<HTMLAnchorElement> {
  className?: string;
  hasAnimation?: boolean;
  talentName: string;
  isLoading?: boolean;
  avatar: string | File;
  isVerified?: boolean;
  hasVerifiedVideo?: boolean;
  functionalArea: ExpertiseID;
  isClickable?: boolean;
  talentId?: string;
  hasEnabledHireMe?: boolean;
  bestBadge?: keyof IBadges | null;
}

/**
 * Primary UI component for user interaction
 */
const TalentThumbnail = ({
  isClickable = false,
  avatar,
  // isVerified = false,
  // hasVerifiedVideo = false,
  functionalArea,
  talentName,
  isLoading = false,
  talentId,
  hasEnabledHireMe,
  bestBadge,
  ...props
}: CardProps) => {
  return (
    <Container
      {...props}
      href={isClickable && talentId ? `/talent/profile/${talentId}` : undefined}
      target="_blank"
      rel="noreferrer"
    >
      <StyledUserAvatar logoUrl={avatar} alt={talentName} hireMe={hasEnabledHireMe}/>

      <Texts>
        <Skeleton isLoading={isLoading}>
          <ChannelName>
            {talentName}
            {!!bestBadge && <MultiLevelIcons bestBadge={bestBadge} />}
          </ChannelName>
        </Skeleton>
        <Skeleton isLoading={isLoading}>
          <Row>
            <Caption>
              {convertExpertidToJobTitle(functionalArea as ExpertiseID)}
            </Caption>
            {/* {isVerified && <TalentBadge variant="VERIFIED" />} */}
            {/* {hasEnabledHireMe && <TalentBadge variant="HIRE_ME" />} */}
            {/* {hasVerifiedVideo &&  <div style={{marginTop: '-0.25rem'}}><VerifiedByVideoIcon/></div>} */}
          </Row>
        </Skeleton>
      </Texts>
    </Container>
  );
};
export default TalentThumbnail;

const Container = styled.a`
  display: flex;
  align-items: center;
`;
const StyledUserAvatar = styled(UserAvatar)`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  margin-right: 12px;
  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }
  //desktop
  @media ${deviceMin.tabletS} {
    width: 4rem;
    height: 4rem;
    img {
      width: 4rem;
      height: 4rem;
    }
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  margin-top: 2px;
  @media ${deviceMin.tabletS} {
    margin-top: 0px;
  }
`;
const Texts = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
const ChannelName = styled.p`
  ${H5Style}
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  margin-bottom: 0.25rem;
  color: var(--color-gray1);
  width: 100%;
  text-align: left;
  /* word-break: break-word; */
  > span:last-child {
    transform: translateY(2px);
  }
  @media ${deviceMin.tabletS} {
    ${Body16Style}
    line-height: 22px;
    color: var(--color-gray2);
  }
`;

const Caption = styled.h4`
  ${Body12Style}
  text-align: left;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray1);
  white-space: nowrap;
`;
