import React, { useEffect } from "react";
import styled, { css } from "styled-components/macro";
import { device, deviceMin } from '../../../consts/device';
import Menu from "../../../Components/Menu";
import { Helmet } from "react-helmet";
import { slideInDownAnimation, slideInUpAnimation } from "../../../Styles/Animation";
import { useData } from "./useData";
import { MdiIconsAction } from "../../../Elements/MdiIcons";
import { mdiArrowLeft } from "@mdi/js";
import { useMessageChannel } from "../../../Components/Talent/MessageChannel/useMessageChannel";
import { AsideAction, FSInput, FormStepContainer, FormStepSubtitle, FormStepTitle } from "./components";
import { Button } from "../../../Elements/Button";
import { Viewport } from "../../../Components/Talent/DetailNew/ProfileContent/NewComponents";
import Stepper from "../../../Components/Stepper";
import { WYSEditor, WYSSendMessage } from "../../../Elements/WYSIWYGEditor";
import useUser from "../../../hooks/useUser";
import SuccessToast from "../../../Elements/Toast/Success";
import { UserTalentQueryLabels } from "../../../api/interfaces/User/Talent";
import { useQueryClient } from "react-query";

export const ProposalFormModal: React.FC = () => {

  const { close, targetChannel } = useMessageChannel()
  const { talentInfo, refetchTalentInfo } = useUser()
  const queryClient = useQueryClient()

  const {
    nextStep, prevStep, goToNextStep, goToPrevStep, goToStep,
    subject, setSubject,
    introduction, setIntroduction,
    channelAnalysis, setChannelAnalysis,
    workExperience, setWorkExperience,
    collaboration, setCollaboration,
    message, setMessage,
    finalMessage
  } = useData()

  // console.log(message)

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <>
      <Helmet>
        <style>
          {`
            .video-page-layout {
              overflow: hidden;
            }
            .smart-banner {
              display: none !important;
            }
                        
          `}
        </style>
      </Helmet>

      <Container animationType={nextStep - prevStep}>
        <Menu onlyLogo={true} />
        <Viewport viewType="mobile">
          <Stepper currentStep={nextStep} end={6} />
        </Viewport>
        <InnerContainer>
          <Aside>
            <Back onClick={close}>
              <ArrowLeft path={mdiArrowLeft} /> Back to List
            </Back>
            <AsideAction isActive={nextStep === 1} isChecked={!!message.subject} text="Subject" onAction={() => goToStep(1)} />
            <AsideAction isActive={nextStep === 2} isChecked={!!message.introduction} text="Introduction and Background" disabled={!message.subject} onAction={() => goToStep(2)} />
            <AsideAction isActive={nextStep === 3} isChecked={!!message.channelAnalysis} text="Channel Analysis and Solutions" disabled={!message.subject} onAction={() => goToStep(3)} />
            <AsideAction isActive={nextStep === 4} isChecked={!!message.workExperience} text="Relevant Work Experience and Links" disabled={!message.subject} onAction={() => goToStep(4)} />
            <AsideAction isActive={nextStep === 5} isChecked={!!message.collaboration} text="Collaboration Terms" disabled={!message.subject} onAction={() => goToStep(5)} />
            <AsideAction isActive={nextStep === 6} isChecked={false} text="Wrap Up and Submit" disabled={!message.subject} onAction={() => goToStep(6)} />
          </Aside>

          {nextStep === 1 && (
            <FormStepContainer className="form-steps">
              <FormStepTitle>Pitch to {targetChannel?.name}</FormStepTitle>
              <FormStepSubtitle>In a few simple steps, you can craft a well-written proposal. Start with the subject!</FormStepSubtitle>
              <FSInput
                label="Subject"
                placeholder="e.g.  Introducing My Video Editing Services"
                maxLength={100}
                value={subject}
                onChange={(e) => setSubject(e.target?.value || '')}
              />
              <Viewport viewType="mobile">
                <div style={{ flex: 1 }} />
              </Viewport>
              <RowCol className="mt">
                <Viewport viewType="biggerThanMobile">
                  <div style={{ flex: 1 }} />
                </Viewport>
                <Viewport viewType="mobile">
                  <Button variant="NAKE" onClick={close}>Back to List</Button>
                </Viewport>
                <Button
                  disabled={!subject.length}
                  onClick={() => {
                    setMessage(s => ({ ...s, subject }))
                    goToNextStep()
                  }}
                >Start</Button>
              </RowCol>
            </FormStepContainer>
          )}
          {nextStep === 2 && (
            <FormStepContainer className="form-steps">
              <FormStepTitle>Introduction and Background</FormStepTitle>
              <FormStepSubtitle>Briefly introduce yourself and your relevant experiences.</FormStepSubtitle>
              <WYSEditor
                initValue={introduction}
                onChange={(v) => setIntroduction(v)}
                placeholder=""
              />
              <Viewport viewType="mobile">
                <div style={{ flex: 1 }} />
              </Viewport>
              <RowCol className="mt">
                <Button variant="NAKE" onClick={goToPrevStep}>Back</Button>
                <Viewport viewType="biggerThanMobile">
                  <div style={{ flex: 1 }} />
                </Viewport>
                <Button
                  disabled={false}
                  onClick={() => {
                    setMessage(s => ({ ...s, introduction }))
                    goToNextStep()
                  }}
                >Next</Button>
              </RowCol>
            </FormStepContainer>
          )}
          {nextStep === 3 && (
            <FormStepContainer className="form-steps">
              <FormStepTitle>Channel Analysis and Solutions</FormStepTitle>
              <FormStepSubtitle>Share your ideas for improvement and describe how you'll address them.</FormStepSubtitle>
              <WYSEditor
                initValue={channelAnalysis}
                onChange={(v) => setChannelAnalysis(v)}
                placeholder=""
              />
              <Viewport viewType="mobile">
                <div style={{ flex: 1 }} />
              </Viewport>
              <RowCol className="mt">
                <Button variant="NAKE" onClick={goToPrevStep}>Back</Button>
                <Viewport viewType="biggerThanMobile">
                  <div style={{ flex: 1 }} />
                </Viewport>
                <Button
                  disabled={false}
                  onClick={() => {
                    setMessage(s => ({ ...s, channelAnalysis }))
                    goToNextStep()
                  }}
                >Next</Button>
              </RowCol>
            </FormStepContainer>
          )}
          {nextStep === 4 && (
            <FormStepContainer className="form-steps">
              <FormStepTitle>Relevant Work Experience and Links</FormStepTitle>
              <FormStepSubtitle>Provide examples and links to your work.</FormStepSubtitle>
              <WYSEditor
                initValue={workExperience}
                onChange={(v) => setWorkExperience(v)}
                placeholder=""
              />
              <Viewport viewType="mobile">
                <div style={{ flex: 1 }} />
              </Viewport>
              <RowCol className="mt">
                <Button variant="NAKE" onClick={goToPrevStep}>Back</Button>
                <Viewport viewType="biggerThanMobile">
                  <div style={{ flex: 1 }} />
                </Viewport>
                <Button
                  disabled={false}
                  onClick={() => {
                    setMessage(s => ({ ...s, workExperience }))
                    goToNextStep()
                  }}
                >Next</Button>
              </RowCol>
            </FormStepContainer>
          )}
          {nextStep === 5 && (
            <FormStepContainer className="form-steps">
              <FormStepTitle>Collaboration Terms</FormStepTitle>
              <FormStepSubtitle>Outline your work conditions, availability, and pricing.</FormStepSubtitle>
              <WYSEditor
                initValue={collaboration}
                onChange={(v) => setCollaboration(v)}
                placeholder=""
              />
              <Viewport viewType="mobile">
                <div style={{ flex: 1 }} />
              </Viewport>
              <RowCol className="mt">
                <Button variant="NAKE" onClick={goToPrevStep}>Back</Button>
                <Viewport viewType="biggerThanMobile">
                  <div style={{ flex: 1 }} />
                </Viewport>
                <Button
                  disabled={false}
                  onClick={() => {
                    setMessage(s => ({ ...s, collaboration }))
                    goToNextStep()
                  }}
                >Next</Button>
              </RowCol>
            </FormStepContainer>
          )}
          {nextStep === 6 && (
            <FormStepContainer className="form-steps">
              <FormStepTitle>Wrap Up and Review</FormStepTitle>
              <FormStepSubtitle>This is the review of your proposal. Feel free to enhance it, add additional documents, and invite for collaboration.</FormStepSubtitle>
              <WYSSendMessage
                initValue={finalMessage}
                myAvatar={talentInfo?.avatar as string}
                placeholder=""
                subject={message.subject}
                target={{
                  id: targetChannel?.id as string,
                  name: targetChannel?.name as string,
                  type: 'company'
                }}
                onCancel={goToPrevStep}
                onSubmit={() => {
                  SuccessToast('Your message has been sent successfully')
                  refetchTalentInfo()
                  queryClient.refetchQueries([UserTalentQueryLabels.getReachoutList])
                  close()
                }}
              />
            </FormStepContainer>
          )}
        </InnerContainer>
      </Container>
    </>
  );
};

/**
 * animationType > 0 then going forward then animation should be fadein from right side
 * animationType < 0 then going backward then animation should be fadein from left side
 */
const Container = styled.div<{ animationType?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  background-color: var(--color-bg1);
  z-index: 100;
  > section {
    position: relative;
  }

  ${({ animationType = 0 }) => animationType > 0
    ? css`
      .form-steps {
        ${slideInUpAnimation};
      }
    `
    : animationType < 0
      ? css`
      .form-steps {
        ${slideInDownAnimation}
      }
      `
      : css``
  }
  
  @media ${deviceMin.desktop} {
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray4);
    }
  }
  @media ${device.mobileL} {
    padding-bottom: 32px;
    gap: 0px;
  }
`
const InnerContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  height: 100%;
  max-width: var(--max-width-page);
  margin: 0 auto;
  @media ${device.mobileL} {
    flex-direction: column;
    gap: 1px;
    height: calc(100% - 74px);
  }
`
const Aside = styled.div`
  width: 364px;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--color-gray6);
  @media ${device.mobileL} {
    display: none;
  }
`
const Back = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  cursor: pointer;
  color: var(--color-gray3);
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
  margin-bottom: 8px;
`
const ArrowLeft = styled(MdiIconsAction)`
  width: 16px;
  height: 16px;
  > path {
    fill: var(--color-gray3);
  }
`
const RowCol = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  &.mt {
    margin-top: 8px;
  }
  > * {
    height: 52px;
  }
  
  @media ${device.mobileL} {
    flex-direction: column-reverse;
    > * {
      width: 100%;
    }
  }
`