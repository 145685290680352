import React, { HTMLAttributes } from "react";
import styled from "styled-components/macro";
import { CenteredLoading } from "../CenteredLoading";
import useUser from "../../hooks/useUser";
import { Link } from "react-router-dom";
import { convertExpertidToJobTitle } from "../../Utils/job";
import { ExpertiseID } from "../../Interfaces";
import { MdChevronRight } from "react-icons/md";

interface Props extends HTMLAttributes<HTMLAnchorElement> {
  avatar: string;
  channelName: string | null;
  functionalArea: ExpertiseID | undefined;
  isClickable?: boolean;
  talentId?: string;
}
const TalentPopupThumbnail: React.FC<Props> = ({
  isClickable = false,
  talentId,
  avatar,
  channelName,
  functionalArea,
  ...props
}) => {
  const { isLoadingTalent } = useUser();

  return (
    <Container
      to={
        isClickable && talentId
          ? `/talent/profile/${talentId}?r=${Math.floor(Math.random() * 1000)}`
          : ""
      }
      {...props}
    >
      {isLoadingTalent ? (
        <CenteredLoading />
      ) : (
        <>
          <ChannelLogo src={avatar} alt="channel logo" />
          <Texts>
            <ChannelName>{channelName}</ChannelName>
            <Subscribers>
              {convertExpertidToJobTitle(functionalArea)}
            </Subscribers>
          </Texts>
          <div style={{flex: 1}} />
          <ChevRight />
        </>
      )}
    </Container>
  );
};
export default TalentPopupThumbnail;
const Container = styled(Link)`
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--color-gray5);
  background: var(--color-bg1);
  padding: 8px;
  width: 100%;
`;
const Texts = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;
const ChannelName = styled.h4`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-gray1);
  text-align: left;
  white-space: nowrap;
`;
const Subscribers = styled.h3`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray3);
  margin-top: 0.3rem;
  white-space: nowrap;
`;
const ChannelLogo = styled.img`
  object-fit: cover;
  border: 1px solid #dde2eb;
  box-sizing: border-box;
  border-radius: 50%;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  margin-right: 0.5rem;
`;
const ChevRight = styled(MdChevronRight)`
  width: 32px;
  height: 32px;
  color: var(--color-gray3);
`