import { useCallback, useContext } from "react";
import { PublicModalContext, ITargetUser } from "../../../Contexts/PublicModalContenxt";
import { PublicPopupVariant } from "../../../Interfaces";



export const useMessage2Talent = () => {
  const { setActiveModalOpen, activeModalOpen, targetUser, setTargetUser } = useContext(PublicModalContext);

  const close = useCallback(() => {
    setActiveModalOpen(PublicPopupVariant.NONE);
    setTargetUser(undefined);
  }, [setActiveModalOpen, setTargetUser]);

  const open = useCallback((userInfo: ITargetUser | undefined) => {
    setTargetUser(userInfo);
    setActiveModalOpen(PublicPopupVariant.MESSAGE_TO_TALLENT);
  }, [setActiveModalOpen, setTargetUser]);

  const toggle = useCallback((userInfo: ITargetUser | undefined) => {
    if (activeModalOpen === PublicPopupVariant.MESSAGE_TO_TALLENT) {
      close();
    } else {
      open(userInfo);
    }
  }, [activeModalOpen, close, open]);

  return {
    open,
    close,
    toggle,
    targetUser
  }

}