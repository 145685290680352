import { MouseEventHandler } from "react";
import styled from "styled-components/macro";
import { isDarkMode } from "../../Utils";
import { IoMdBriefcase } from "react-icons/io";

interface Props {
  className?: string;
  onClick?: MouseEventHandler<SVGElement>;
}

const JobOhterTypeIcon: React.FC<Props> = ({ className, onClick }) => {
  return <StyledIcon className={className} onClick={onClick} style={{
    backgroundColor: isDarkMode() ? '#fff7d232' : '#E258712a'
  }} />;
};

export const StyledIcon = styled(IoMdBriefcase)`
  border-radius: 10px;
  width: 40px;
  height: 40px;
  padding: 8px;
  object-fit: contain;
  color: #E25871;
`;

export default JobOhterTypeIcon;
