import React, { HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import {
  StyledLogo,
  CopyRight,
} from './StyledComponents';
import { device } from '../../consts/device';
import { Link } from 'react-router-dom';

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}
export const OnlyForLandings: React.FC<FooterProps> = ({ className, ...props }) => {
  return (
    <Container {...props} className={className}>
      <Wrapper>
        <div>
          <StyledLogo variant='LIGHT' />
          <div style={{ minHeight: 16 }} />
          <CopyRight>Copyright © {(new Date().getFullYear())} ytjobs.co, All Rights Reserved</CopyRight>
        </div>
        <LinkContainer>
          <Link to={`/talent/search/all_categories?page=1`} >Talent</Link>
          <Link to={`/job/search/all_categories`} >Jobs</Link>
          <div className='divide' />
          <Link to={`/login`} >Login</Link>
          <Link to={`/channel/signup`} className='imp'>Sign up</Link>
        </LinkContainer>
      </Wrapper>
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  background: #1e1e1f;
  margin-top: auto;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: var(--max-width-page);
  padding: 30px 0;
  margin: auto;
  img {
    margin: 0 !important;
  }
  h3 {
    position: unset;
    margin: 0 !important;
    font-size: 14px;
  }
  @media ${device.mobileL} {
    flex-direction: column-reverse;
    gap: 16px;
    align-items: flex-start;
    padding: 24px 16px;
    h3 {
      text-align: left;
    }
  }
`;
const LinkContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 30px;
  .divide {
    width: 1px;
    height: 26px;
    background: #414244;
  }
  a {
    color: var(--color-white);
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 26px; /* 162.5% */
    &.imp {
      color: var(--color-primary-dark);
    }
  }
`