export enum SmartBannerTypes {
  COMPLETE_PROFILE = 'COMPLETE_PROFILE',
  BDGE_LOST_WARNING = 'BDGE_LOST_WARNING',
  ASK_FOR_REVIEW = 'ASK_FOR_REVIEW',
  ASK_FOR_REVIEW_PANIC = 'ASK_FOR_REVIEW_PANIC',
  NONE = 'none',
  VERIFY_CHANNEL = 'VERIFY_CHANNEL',
  VERIFY_CHANNEL_PANIC = 'VERIFY_CHANNEL_PANIC',
  CHANGE_EMAIL = 'CHANGE_EMAIL',
}
