import ReactDOM from 'react-dom';
import App from './App';
import { StyleSheetManager } from 'styled-components';
import posthog from 'posthog-js';
import { isItOnBeta, isItOnLive } from './api/utils';
import * as Sentry from '@sentry/browser';

if (isItOnLive()) {
  posthog.init('phc_yrH4TAxsamiZemuhClIfxea2cHgulg0OJqtuBcF675B', { api_host: 'https://app.posthog.com' })
  Sentry.init({dsn: "https://637f2dab9beb4d46a801a1433e7d216b@sentry-dev.ytjobs.co/7"});
}
if (isItOnBeta()) {
  posthog.init('phc_7JBW8usHaFvCGB3pfLkUtrwxGOrZHe9P4by2VIddu1M', { api_host: 'https://app.posthog.com' })
}



ReactDOM.render((
  <StyleSheetManager disableCSSOMInjection={true}>
    <App />
  </StyleSheetManager>
), document.querySelector('#root'));
