import { ytJobsApi } from "../../api/clients";
import { PaginateQueryResponse } from "../../api/interfaces";
import { GetOutreachableChannels } from "../../api/interfaces/Channel";
import { TalentProduct, UserSubscriptions } from "./interfaces";


export const getOutreachList = async ({ limit, page, channelSize, niches }: {
  limit: number;
  page: number;
  channelSize?: string;
  niches?: number[];
}) => {
  const { data } = await ytJobsApi.get<PaginateQueryResponse<GetOutreachableChannels>>(`/companies`, {
    params: {
      limit,
      // is_featured: 0,
      page,
      filter: {
        outreachable: true,
        channelSize,
        niches
      },
    },
  });

  return data;
};
export const getTalentProductList = async () => {
  const { data } = await ytJobsApi.get<TalentProduct[]>(`/profile/subscriptions`);
  return data;
};
export const buyProduct = async (pId: string) => {
  const { data } = await ytJobsApi.post<{ paymentUrl: string }>(`/profile/subscriptions`, { id: pId });
  return data;
};
export const getTalentSubscriptions = async () => {
  const { data } = await ytJobsApi.get<UserSubscriptions>(`/profile/subscriptions/me`);
  return data;
};

export const getUserProductPackges = async () => {
  const { data } = await ytJobsApi.get<TalentProduct>(`/company/products/package`);
  return data;
};