import { isItOnBeta, isItOnLive } from "../api/utils";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, MessagePayload, Messaging } from "firebase/messaging";
import { addUserFcmToken } from "../api/fetchers/User/Talent";


const FCM_MAIN_CONFIG = {
  apiKey: "AIzaSyALnVc_pLMrTSwqkCI3ipSX5KvVbsOuUC0",
  authDomain: "ytjobs-406007.firebaseapp.com",
  projectId: "ytjobs-406007",
  storageBucket: "ytjobs-406007.appspot.com",
  messagingSenderId: "1046646567574",
  appId: "1:1046646567574:web:b4bf4da5be58987df588b7",
  measurementId: "G-FWQ2R0V1PG"
};

const FCM_BETA_CONFIG = {
  apiKey: "AIzaSyCn1jEjsO_dvAyH4YCVEHFoFnSykGyN20M",
  authDomain: "ytjobs-beta.firebaseapp.com",
  projectId: "ytjobs-beta",
  storageBucket: "ytjobs-beta.appspot.com",
  messagingSenderId: "168873762475",
  appId: "1:168873762475:web:772fdffae64b94906f21ee"
};

const firebaseConfig = isItOnLive() ? FCM_MAIN_CONFIG : isItOnBeta() ? FCM_BETA_CONFIG : {};

const vapidKey = isItOnLive() ? 'BGteDwmwZycEqrgrRW_1p5e-_5HLCufpywyR9hxt1EZdxoXVwWF9W31Et9I85btXtCpaftt9wwtDef_rjwkiCww' : isItOnBeta() ? 'BGwj8olyw7GqIbUC2JzUPf1YqZ0mLJitx9RVeX8eePYVjbEpCOVDZNH5c7OlLyAIeeDM0x3HN0V6oStyY6ZptY4' : '';

export const fcmApp = initializeApp(firebaseConfig);
let messaging: Messaging;
try {
  messaging = getMessaging(fcmApp);
} catch (error) {
  console.error('Failed to initialize Firebase Messaging', error);
}

export const inAppMessageSubscription = (callback: (pl: MessagePayload) => void) => {
  return onMessage(messaging, callback);
};

export const nativeNotificationUtils = {
  isSupported: () => {
    return !!window.Notification && !!navigator?.serviceWorker;
  },
  getPermissionStatus: () => {
    return window.Notification?.permission;
  },
  askToGrant: () => {
    return window.Notification?.requestPermission();
  }
}


export const requestForToken = () => {
  return (
    new Promise(async (res, rej) => {
      getToken(messaging, { vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            res(currentToken);
          } else {
            rej('Somthing went wrong!');
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          rej('Somthing went wrong!');
        });
    })
  )
};

const key = 'TKNFCN'
export const fcmTokenUtils = {
  get: () => localStorage.getItem(key),
  set: (token: string) => {
    localStorage.setItem(key, token)
  }
}

export const isPushServiceRegistered = async () => {
  if (navigator.serviceWorker) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    return !!registrations.filter(r => !!r.active && r.scope?.includes('/firebase-cloud-messaging-push-scope')).length;
  } else {
    return false;
  }
}
export const getPushServiceRegisteration= async () => {
  if (navigator.serviceWorker) {
    return (await navigator.serviceWorker.getRegistrations()).find(r => !!r.active && r.scope?.includes('/firebase-cloud-messaging-push-scope'));
  } else {
    return undefined;
  }
}
export const registerAndWaitForSW = async () => {
  try {
    return !!await navigator.serviceWorker?.register('/firebase-messaging-sw.js', { scope: "/firebase-cloud-messaging-push-scope" });
  } catch (error) {
    console.log('SW Registration failed! ', error)
    return false;
  }
}

export const initPushService = async (toaster?: (m: string) => void) => {
  const throwError = (err?: string) => {
    toaster?.(err || '')
    throw new Error(err)
  }

  if (nativeNotificationUtils.getPermissionStatus() === 'granted' && await isPushServiceRegistered()) {
    try {
      const token = await requestForToken();
      fcmTokenUtils.set(token as string)
      await addUserFcmToken(token as string);
      return true;
    } catch (error) {
      throwError(`Couldn't get registration token`)
    }
  } else {
    // check if user has denied before then show error toast.
    if (nativeNotificationUtils.getPermissionStatus() === 'denied') {
      throwError('Denied By User. You need to reset notification permission in browser setting!');
    }

    // start asking user to grant permission
    const userPermission = await nativeNotificationUtils.askToGrant();

    if (userPermission === 'granted') {
      if (await isPushServiceRegistered()) {
        try {
          const token = await requestForToken();
          fcmTokenUtils.set(token as string)
          await addUserFcmToken(token as string);
          return true;
        } catch (error) {
          throwError(`Couldn't get registration token`)
        }
      }
      else {
        throwError(`Service-Worker is not ready yet. Please retry!`)
      }
    }
    // will run only when user has rejected
    else {
      throwError(`Refuced to grant the permission.`)
    }
  }
}

