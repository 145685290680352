import styled, { css } from "styled-components";
import { MdiIcons } from "../../../Elements/MdiIcons";
import { mdiCheck } from "@mdi/js";
import { FC, InputHTMLAttributes } from "react";
import { device } from "../../../consts/device";


/**
 * Back like
 */
interface TickProps {
  text: string;
  isChecked: boolean;
  isActive: boolean;
  onAction?: () => void;
  disabled?: boolean;
}
export const AsideAction: FC<TickProps> = ({
  text,
  isChecked,
  isActive,
  onAction,
  disabled = false
}) => {
  return (
    <TickContainer checked={isChecked} isActive={isActive} onClick={() => onAction?.()} className="wt-tick" disabled={disabled}>
      <div>
        {!!isChecked && (
          <MdiIcons path={mdiCheck} />
        )}
      </div>
      <p>{text}</p>
    </TickContainer>
  )
}
const TickContainer = styled.div<{ checked?: boolean; isActive: boolean; disabled: boolean}>`
  width: 100%;
  height: auto;
  display: flex;
  gap: 8px;
  user-select: none;
  max-width: 100%;
  padding: 12px 16px;
  border: 1px solid transparent;
  background-color: var(--color-gray55);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  > div:not(.devider) {
    display: flex;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 64px;
    > svg {
      width: 14px;
      height: 14px;
      > path {
        fill: var(--color-white);
      }
    }
    ${({ checked }) => !!checked && css`
      background: var(--color-success);
    `}
    ${({ checked }) => !checked && css`
      border: 1px solid var(--Neutral-Gray-30, #C6C6C6);
      background: var(--color-gray5);
    `}
  }
  > p {
    color: var(--color-gray1);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  ${({ isActive }) => isActive && css`
    border-color: var(--color-primary);
    background-color: var(--color-primary-light2);
  `}
  ${({ disabled }) => disabled && css`
    opacity: 0.6;
    pointer-events: none !important;
  `}
`

type FSInputProps = {
  label?: string;
} & InputHTMLAttributes<HTMLInputElement>
export const FSInput: FC<FSInputProps> = ({label, ...props }) => {
  return (
    <FSInputContainer>
      <label>{label}</label>
      <input {...props} />
      <p>{(props.value as string)?.length ?? 0}/{props.maxLength ?? 100}</p>
    </FSInputContainer>
  )
}
const FSInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  > p {
    color: var(--color-gray3);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 158.333% */
    margin-top: -6px;
  }
  > label {
    color: var(--color-gray2);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px; /* 143.75% */
  }
  input {
    &::placeholder {
      color: var(--color-gray4);
      font-size: 14px;
    }
    &:focus {
      border-color: var(--color-gray3);
    }
    color: var(--color-gray2);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px; /* 221.429% */
    padding: 8px 16px;
    border: 1px solid var(--color-gray4);
    border-radius: 10px;
  }
`






// Shared Components
export const FormStepContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 68px;
  
  @media ${device.mobileL} {
    max-width: unset;
    width: 100vw;
    padding: 0 16px 48px;
    min-height: 100%;
    margin-top: 24px;
  }
`
export const FormStepTitle = styled.h2`
  color: var(--color-gray1);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 125% */
  letter-spacing: -0.05px;
`
export const FormStepSubtitle = styled.h6`
  color: var(--color-gray1);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
`