//////////Channel/Company//////////////////
import {
  AddStripeProductsToJobs_Option,
  IOneClickFrom,
  JobFormQuestions,
  JobStatus,
} from "../../../Interfaces/Job";
import { ytJobsApi } from "../../clients";
import {
  // ApplicationData,
  ApplicationPriority,
  CurrentChannelResponse,
  ExpressChannelLoginResponse, GetJobApplicantsByOwnerResponse,
  GetJobDetailByOwnerResponse,
  GetJobListByOwnerResponse,
  IChannelSettings,
} from "../../interfaces/User/Channel";
import {
  CreateJobResponse,
  EditJobResponse,
  AddStripeProductsToJobs_Response,
} from "../../interfaces/Job";
import { RefferalHandler, URLReferrerHandler, UtmCampainHandler } from "../../../Utils/refferalHandler";

export const createChannel = async (email: string, ytLink: string) => {
  const body: Record<string, string> = {};
  const refferer = RefferalHandler.GetRefferer();
  if (refferer) {
    body['referringSource'] = refferer
  }
  const utmCampaign = UtmCampainHandler.GetUtm()
  if (utmCampaign) {
    body['utmCampaign'] = utmCampaign
  }
  const firstReferer = URLReferrerHandler.GetURLRef()
  if (firstReferer) {
    body['firstReferer'] = firstReferer
  }
  const { data } = await ytJobsApi.post("/auth/company/signup", {
    email,
    ytLink,
    ...body,
  });
  return data;
};
export const createCompany = async (email: string, website: string) => {
  const body: Record<string, string> = {};
  const refferer = RefferalHandler.GetRefferer();
  if (refferer) {
    body['referringSource'] = refferer
  }
  const utmCampaign = UtmCampainHandler.GetUtm()
  if (utmCampaign) {
    body['utmCampaign'] = utmCampaign
  }
  const firstReferer = URLReferrerHandler.GetURLRef()
  if (firstReferer) {
    body['firstReferer'] = firstReferer
  }
  const { data } = await ytJobsApi.post("/auth/company/general-signup", {
    email,
    website,
    ...body,
  });
  return data;
};
export const checkChannel = async (link: string) => {
  const { data } = await ytJobsApi.get(`/youtube/channel?url=${link}`);
  return data;
};

export const updateChannel = async ({
  email,
  description,
}: {
  email?: string;
  description?: string;
}) => {
  const { data } = await ytJobsApi.post(`/company`, {
    notificationEmail: email || null,
    description: description || null,
    _method: "patch",
  });
  return data;
};
export const updateCompany = async ({
  name,
  description,
  avatar
}: {
  name: string;
  description: string;
  avatar: File
}) => {
  const fd = new FormData();
  fd.append('name', name);
  if (description)
    fd.append('description', description);
  fd.append('avatar', avatar);
  fd.append('_method', 'patch');
  const { data } = await ytJobsApi.post(`/company`, fd);
  return data;
};

export const getCurrentChannel = async (): Promise<CurrentChannelResponse> => {
  const { data } = await ytJobsApi.get<CurrentChannelResponse>(`/company`);
  return data;
};
export const expressChannelLogin = async (
  token: string
): Promise<ExpressChannelLoginResponse> => {
  const { data } = await ytJobsApi.post(`/auth/company/express`, {
    token,
  });
  return data;
};

// discover and hire //

export const chooseUserForHire = async (id: string) => {
  const { data } = await ytJobsApi.post<{ success: boolean }>(`/company/dsicovery-list/${id}`);
  return data;
};


//related job////
export const updateJobStatus = async (status: JobStatus, jobId: string) => {
  const { data } = await ytJobsApi.post(`/company/jobs/${jobId}/status`, {
    status,
    _method: "patch",
  });
  return data;
};
export const deleteJob = async (jobId: string) => {
  const { data } = await ytJobsApi.delete(`/company/jobs/${jobId}`);
  return data;
};
export const getJobDetailByOwner = async (id: string | undefined) => {
  if (!id) throw new Error("Id not found");
  const { data } = await ytJobsApi.get<GetJobDetailByOwnerResponse>(
    `/company/jobs/${id}`
  );
  return data;
};
export const getJobApplicantsByOwner = async ({
  id,
  limit,
  filter,
  sort,
  page,
}: {
  limit?: number;
  filter?: { [key: string]: string | string[] | undefined }[];
  sort?: { [key: string]: string }[];
  page: number;
  id: string | undefined
}) => {
  if (!id) throw new Error("Id not found");
  const queryParamsFilter = filter?.reduce((acc, item) => {
    const [key] = Object.getOwnPropertyNames(item);
    if (item[key]) {
      acc[key] = item[key];
      return acc;
    }
    return acc;
  }, {});
  const queryParamsSort = sort?.reduce((acc, item) => {
    const [key] = Object.getOwnPropertyNames(item);
    if (item[key]) {
      acc[key] = item[key];
      return acc;
    }
    return acc;
  }, {});

  const { data } = await ytJobsApi.get<GetJobApplicantsByOwnerResponse>((
    id !== '-1' ? `/company/jobs/${id}/applicants` : '/company/dsicovery-list/list-talent'
  ), {
    params: {
      limit,
      filter: queryParamsFilter ? JSON.stringify(queryParamsFilter) : undefined,
      sort: queryParamsSort ? JSON.stringify(queryParamsSort) : undefined,
      page
    }
  });
  return data;
};
export const getJobApplicantDetail = async (channelId: string, applicantId: string) => {
  const url = channelId === '-1' ? `/company/dsicovery-list/${applicantId}` : `/company/jobs/${channelId}/applicants/${applicantId}`
  const { data } = await ytJobsApi.get(url);
  return channelId === '-1' ? data : data?.data;
};
export const getJobsByOwner = async () => {
  const { data } = await ytJobsApi.get<GetJobListByOwnerResponse>(
    `/company/jobs`
  );
  return data;
};

export const updateApplicant = async ({
  applicationId,
  status,
  shouldNotify,
  jobId
}: {
  applicationId: string;
  status: ApplicationPriority;
  shouldNotify?: boolean;
  jobId: string;
}) => {
  const payload: {
    _method?: string;
    status: ApplicationPriority;
    shouldNotify?: boolean;
  } = {
    status,
  };
  if (jobId !== '-1') {
    payload['_method'] = "patch";
  }
  if (status === ApplicationPriority.hired) {
    payload['shouldNotify'] = !!shouldNotify;
  }
  const url = jobId !== '-1' ? `/company/applicants/${applicationId}` : `/company/dsicovery-list/${applicationId}/change-status`
  const { data } = await ytJobsApi.post(url, payload);
  return data;
};
export const getNotificationStatus = async ({ applicationId }: { applicationId: string; }) => {
  const { data } = await ytJobsApi.get<{ notified: boolean }>(`/company/applicants/${applicationId}/notification-status`);
  return data;
};
//////////////////////////
export const createJob = async (
  request: JobFormQuestions
): Promise<CreateJobResponse> => {
  const body = { ...request };
  if (body.requiredLanguages?.length) {
    // @ts-ignore
    body.requiredLanguages = body.requiredLanguages.map(ln => ln.id)
  }
  const { data } = await ytJobsApi.post<CreateJobResponse>("/jobs", body);
  return data;
};
export const onClickHiringSubmit = async (
  request: IOneClickFrom
): Promise<{ success: boolean; message: string; }> => {
  const { data } = await ytJobsApi.post<{ success: boolean; message: string; }>("/one-click-hiring", request);
  return data;
};

export const editJob = async ({
  answers,
  jobId,
}: {
  answers: JobFormQuestions;
  jobId: string;
}): Promise<EditJobResponse> => {
  const body = {
    ...answers,
    _method: "patch",
  };
  if (body.requiredLanguages?.length) {
    // @ts-ignore
    body.requiredLanguages = body.requiredLanguages.map(ln => ln.id)
  }
  const { data } = await ytJobsApi.post<EditJobResponse>(
    `/company/jobs/${jobId}`, body
  );
  return data;
};
export const activeJob = async ({
  jobId,
}: {
  jobId: string;
}): Promise<{ success: boolean, message: string }> => {
  const body = {
    status: 'active',
    _method: "patch",
  };
  const { data } = await ytJobsApi.post(
    `/company/jobs/${jobId}/status`, body
  );
  return data;
};

//////////////////////////////////
//////////stripe related//////////
//////////////////////////////////
export const getJobProductsinStripe = async (jobId: string) => {
  const { data } = await ytJobsApi.get(`/jobs/${jobId}/stripe-products`);
  return data;
};
export const addStripeProductsTojob = async ({
  jobId,
  products,
}: {
  jobId: string;
  products: AddStripeProductsToJobs_Option[] | null;
}): Promise<AddStripeProductsToJobs_Response> => {
  if (!products) throw new Error("products not found");
  const { data } = await ytJobsApi.post<AddStripeProductsToJobs_Response>(
    `/company/jobs/${jobId}/add-stripe-products`,
    { products }
  );
  return data;
};
export const getVerificationVideos = async () => {
  const { data } = await ytJobsApi.get(`/company/youtube-videos`);
  return data;
};
///////////////////////
export const admitOrDenyAllTalent = async ({
  status,
  userId,
}: {
  status: "verified" | "denied" | "undecided";
  userId: string | undefined;
}) => {
  const { data } = await ytJobsApi.post(
    `/company/youtube-videos/update-all-by-user`,
    {
      status: status,
      userId,
    }
  );
  return data;
};
export const companyAnsers = async ({
  status,
  userId,
}: {
  status: "verified" | "denied";
  userId: string | undefined;
}) => {
  const { data } = await ytJobsApi.post(
    `/company/answers/${userId}`,
    {
      answer: status === 'verified' ? 1 : 0
    }
  );
  return data;
};
export const admitOrDenyOneTalentVideo = async ({
  status,
  videoId,
}: {
  status: "verified" | "denied" | "undecided";
  videoId: string | undefined;
}) => {
  const { data } = await ytJobsApi.post(`/company/youtube-videos/${videoId}`, {
    status: status,
  });
  return data;
};

export const doReview = async ({ rate, talentId, body }: {
  rate: string;
  body: string;
  talentId: string;
}) => {
  const payload = !!body ? { rate, body } : { rate };
  const { data } = await ytJobsApi.post(`/company/made-reviews/${talentId}`, payload);
  return data;
};
export const doComment = async ({ body, talentId, rate }: {
  body?: string;
  rate?: string;
  talentId: string;
}) => {
  const payload = { _method: 'patch' } as {
    body?: string;
    rate?: string;
    _method: 'patch'
  };
  if (body) {
    payload['body'] = body;
  }
  if (rate) {
    payload['rate'] = rate;
  }
  const { data } = await ytJobsApi.post(`/company/made-reviews/${talentId}`, payload);
  return data;
};

export const getSearchProduct = async (): Promise<{
  price: number;
  discountedPrice: number;
}> => {
  const { data } = await ytJobsApi.get(`/company/products/discover-and-hire`);
  return data;
}
export const getLinkSearchProduct = async (): Promise<{
  paymentUrl: string;
}> => {
  const { data } = await ytJobsApi.post(`/company/products/discover-and-hire`);
  return data;
}
export const getLinkMonthProduct = async (): Promise<{
  paymentUrl: string;
}> => {
  const { data } = await ytJobsApi.post(`/company/products/package`);
  return data;
}



// Channel Settings
export const setChannelSettings = async (body: Partial<IChannelSettings>) => {
  const { data } = await ytJobsApi.post<IChannelSettings>(`/profile/settings/company`, body);
  return data;
};