import { FC, HTMLAttributes } from "react";
import styled from "styled-components";


interface IProps extends HTMLAttributes<HTMLOrSVGElement> {
  path: string;
}

export const MdiIcons: FC<IProps> = ({path, ...props}) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d={path} fill="var(--color-gray2)"/>
  </svg>
)

export const MdiIconsAction = styled(MdiIcons)`
  cursor: pointer;
`