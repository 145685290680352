import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useConfigData } from "./useConfigData";


export const ChannelHJSurvey: React.FC = () => {

  const {pathname, search} = useLocation();
  const {data} = useConfigData();
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.showNPS) {
      setTimeout(() => {
        if (!search.includes('showNPS')) {
          // navigate(`${pathname}${search}${search ? `&showNPS=1` : '?showNPS=1'}`)
        }
      }, 500)
    }
  }, [pathname, search, data, navigate])

  useEffect(() => {
    if (!(pathname.startsWith('/channel/job/') && pathname.endsWith('/applicants'))) {
      sessionStorage.removeItem('DSFJHJSDF');
    }
  }, [pathname])

  return null;
}