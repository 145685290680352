import styled from 'styled-components/macro';
import { useEffect } from 'react';

import {
  ModalContainer,
  ModalWrapper,
  ModalGrayBlank,
  ModalContent,
  ModalGrayDash,
} from '../../../../../../Styles/ModalStyles';
import { deviceMin } from '../../../../../../consts/device';

import { YoutubeVideosCollection } from '../../../../../../Interfaces/Talent';
import useUser from '../../../../../../hooks/useUser';
import SwapThumbnailPopup from '.';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  originVideoId: string;
}
const ShowReplaceModal: React.FC<Props> = ({ setOpen, originVideoId }) => {
  const { talentInfo } = useUser();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <ModalContainer>
      <ModalWrapper>
        <ModalGrayBlank
          onClick={() => {
            setOpen(false);
          }}
        />
        <StyledModalContent>
          <ContentWrapper>
            <ModalGrayDash />
            <ModalTitle>
              Select the video you want to replace with the selected one
            </ModalTitle>
            <StyledVitrinePoster
              collection={
                talentInfo?.youtubeVideos || ({} as YoutubeVideosCollection)
              }
              originVideoId={originVideoId}
              setAddOrEditVideoPopup={setOpen}
            />
          </ContentWrapper>
        </StyledModalContent>
      </ModalWrapper>
    </ModalContainer>
  );
};

export default ShowReplaceModal;
const StyledModalContent = styled(ModalContent)`
  width: max-content;
  max-width: 80%;
  padding: 2rem 2rem 2rem;
  overflow: auto;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: fit-content;
  width: 100%;
`;

const ModalTitle = styled.h4`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 1rem;
  color: var(--color-gray1);
  @media ${deviceMin.tabletS} {
    font-size: 22px;
    line-height: 33px;
  }
`;
export const StyledVitrinePoster = styled(SwapThumbnailPopup)`
  margin-top: 1rem;
  cursor: pointer;
  z-index: 1;
`;
