import styled from 'styled-components/macro';
import React, { useEffect } from 'react';
import {
  ModalContainer,
  ModalWrapper,
  ModalGrayBlank,
  ModalContent,
  ModalGrayDash,
} from '../../Styles/ModalStyles';
import { device, deviceMin } from '../../consts/device';
import { IoCloseSharp } from 'react-icons/io5';
import { YoutubeButton } from '../Utils/NewUserEntry/SignupLogin.components';
import { GoogleAuthUtil } from '../../api/fetchers/Channel';
import { useMutation } from 'react-query';

interface Props {
  close: () => void;
}
export const AddChannelAccountPopop: React.FC<Props> = ({ close }) => {

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const { isLoading: getLinkLoading, mutate: getLink } = useMutation(() =>
    GoogleAuthUtil.companyLogin(),
    {
      onSuccess: (res) => {
        if (res && res.authUrl) {
          window.location.href = res.authUrl;
        }
      }
    }
  );

  return (
    <ModalContainer style={{ zIndex: 9999999 }}>
      <ModalWrapper>
        <ModalGrayBlank
          onClick={close}
        />

        <StyledModalContent>
          <ContentWrapper>
            <ModalGrayDash />
            <Row>
              <Title>Add account with YouTube</Title>
              <CloseIcon onClick={close} />
            </Row>

            <TextBody>To connect a new channel account please continue with YouTube:</TextBody>

            <Row2>
              <YoutubeButton
                onClick={() => getLink()}
                isLoading={getLinkLoading}
                disabled={getLinkLoading}
              >
                Continue With
              </YoutubeButton>
            </Row2>

          </ContentWrapper>
        </StyledModalContent>
      </ModalWrapper>
    </ModalContainer>
  );
};

const StyledModalContent = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 0px 16px 48px 16px;
  @media ${deviceMin.tabletL} {
    width: 510px;
    max-width: 80%;
    padding: 24px;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: fit-content;
  width: 100%;
`;
const Row = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const Title = styled.h1`
    font-weight: 600;
    font-size: 22px;
    flex: 1;
    text-align: left;
    color: var(--color-gray1);
    @media ${device.mobileL} {
      font-size: 16px;
    }
`;
const CloseIcon = styled(IoCloseSharp)`
    font-size: 24px;
    cursor: pointer;
    color: var(--color-gray1);
`;
const TextBody = styled.p`
    font-size: 16px 0 8px 0;
    margin: 16px 0;
    color: var(--color-gray2);
`;
const Row2 = styled(Row)`
    padding: 8px 0;
    > button {
      width: max-content;
    }
    @media ${device.mobileL} {
      > button {
        width: 100%;
      }
    }
`;
