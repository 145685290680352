import "./Styles/style.scss";

import { QueryClient, QueryClientProvider } from "react-query";
import ToasterContainer from "./Components/ToasterContainer";
import { PublicModalContextProvider } from "./Contexts/PublicModalContenxt";
import Routes from "./Routes";
import { ReactQueryDevtools } from "react-query/devtools";
import ErrorBoundary from "./ErrorBoundary";
import { BrowserRouter } from "react-router-dom";
// import { CookiePolicy } from './Components/cookie-policy';
import { ChannelHJSurvey } from "./Components/HJNPSSurvey";
import { ReferringSourceHandler } from "./Components/RefferrerSourceHandler";
import { ThemeProvider } from "./Components/ThemeProvider";
import { Scroll2Top } from "./Components/Scroll2Top";
import { InAppNotification } from "./Components/InAppNotification";
import { SupportChannel } from "./Components/Channel/Support";
import { YTErrorCatch } from "./Components/YTErrorCatch";

export type TCacheItem = {
  ckey: string | string[];
  cval: unknown;
}


export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 60000 * 5, //5min
      retry: false,
    },
  },
});

// @ts-ignore
const preCachedData: TCacheItem[] | undefined = window.___yt_cf_pcache

if (preCachedData && Array.isArray(preCachedData)) {
  preCachedData.forEach(({ckey, cval}) => {
    queryClient.setQueryData(ckey, cval)
  })
}


export default function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <PublicModalContextProvider>
          <ToasterContainer />
          <ThemeProvider>
            <BrowserRouter>
              <ReferringSourceHandler />
              <Scroll2Top />
              <Routes />
              <ChannelHJSurvey />
              <SupportChannel />
              <YTErrorCatch />
            </BrowserRouter>
          </ThemeProvider>
          {/* <CookiePolicy /> */}
          <InAppNotification />
        </PublicModalContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
