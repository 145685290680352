import { SmartBannerTypes } from '../interface';
import { ProfileStatus, TalentInfo } from '../../../Interfaces/Talent';
import { ChannelProfile } from '../../../Interfaces/Channel';
import { useChannelData } from './useChannelData';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { isACompany, isNeededChangeEmail } from '../../../Utils/channel';
import { getVal } from '../../../api/fetchers/Talent';

interface Props {
  talentInfo: TalentInfo | undefined;
  channelInfo: ChannelProfile | undefined;
}

const useSmartBanner = ({ talentInfo, channelInfo }: Props) => {

  const pathName = window.location.pathname;
  const { id: channelId } = useParams(); // if in the /channel/:id
  const { userNumbers, teammateExist, hasChannelActiveJob } = useChannelData(Boolean(channelInfo?.id));
  const verificationBannerNeeded = useMemo(() => talentInfo?.id && !talentInfo?.hasReviews, [talentInfo]);

  const isPassedOneWeek = useMemo(() => {
    const oneWeekInMS = 7 * 24 * 3600 * 1000;
    if (talentInfo?.createdAt) {
      const now = +new Date();
      const createdAt = +new Date(talentInfo.createdAt)
      return (now - createdAt) >= oneWeekInMS
    }
    return false;
  }, [talentInfo])

  const isSiverTalentDenied = useMemo(() => {
    if (!talentInfo || talentInfo?.bestBadge) return false;
    // has talent any denied client
    const hasAnyDeniedClient = !!talentInfo.youtubeVideos.channels.filter(c => (c.status === 'denied')).length;
    if (hasAnyDeniedClient) {
      // then has talent any verified client for upper silever badge
      return !!talentInfo.youtubeVideos.channels
        .filter(c => (c.status === 'verified' && getVal(c.abvSubscribers) >= 100_000)).length
    }
    return false;
  }, [talentInfo]);


  const bannerState = () => {
    // Talent Banners
    if (
      talentInfo?.id &&
      talentInfo?.profileStatus === ProfileStatus.INCOMPLETE &&
      pathName !== '/talent/profile-wizard' &&
      pathName !== '/talent/signup' &&
      !pathName.startsWith('/videos/') &&
      pathName !== `/talent/${talentInfo?.id}`
    )
      return SmartBannerTypes.COMPLETE_PROFILE;
    // 
    if (
      talentInfo?.id &&
      isSiverTalentDenied &&
      pathName !== '/talent/profile-wizard' &&
      pathName !== '/talent/signup' &&
      !pathName.startsWith('/talent/edit/vitrine') &&
      !pathName.startsWith('/talent/vitrine/') &&
      !pathName.startsWith('/videos/')
    )
      return SmartBannerTypes.BDGE_LOST_WARNING;
    if (
      verificationBannerNeeded &&
      isPassedOneWeek &&
      !pathName.startsWith('/talent/edit/vitrine') &&
      !pathName.startsWith('/talent/vitrine/') &&
      !pathName.startsWith('/talent/profile-wizard') &&
      !pathName.startsWith('/videos/') &&
      !pathName.startsWith('/talent/signup')
    )
      return SmartBannerTypes.ASK_FOR_REVIEW_PANIC;
    if (
      verificationBannerNeeded &&
      !pathName.startsWith('/talent/edit/vitrine') &&
      !pathName.startsWith('/talent/vitrine/') &&
      !pathName.startsWith('/talent/profile-wizard') &&
      !pathName.startsWith('/videos/') &&
      !pathName.startsWith('/talent/signup')
    )
      return SmartBannerTypes.ASK_FOR_REVIEW;

    // Channel Banners
    if (
      channelInfo &&
      channelInfo.id &&
      !isACompany(channelInfo) &&
      !channelInfo.isVerified &&
      (hasChannelActiveJob) &&
      !pathName.includes('verified-channels-description') &&
      !pathName.includes('/review') &&
      !pathName.startsWith('/job/create') &&
      !pathName.startsWith('/videos/') &&
      !pathName.includes('/checkout') &&
      !pathName.includes('/payment') &&
      !pathName.includes('/pre-publish') &&
      !pathName.includes('/only-verified-channel')
    )
      return SmartBannerTypes.VERIFY_CHANNEL_PANIC
    if (
      channelInfo &&
      channelInfo.id &&
      !isACompany(channelInfo) &&
      !channelInfo.isVerified &&
      (hasChannelActiveJob === false) &&
      !pathName.includes('verified-channels-description') &&
      !pathName.includes('/review') &&
      !pathName.startsWith('/job/create') &&
      !pathName.startsWith('/videos/') &&
      !pathName.includes('/checkout') &&
      !pathName.includes('/payment') &&
      !pathName.includes('/pre-publish') &&
      !pathName.includes('/only-verified-channel')
    )
      return SmartBannerTypes.VERIFY_CHANNEL
    if (
      channelInfo &&
      channelInfo.id &&
      isNeededChangeEmail(channelInfo) &&
      !pathName.includes('verified-channels-description') &&
      !pathName.startsWith('/channel/settings') &&
      !pathName.startsWith('/job/create') &&
      !pathName.startsWith('/videos/') &&
      !pathName.includes('/checkout') &&
      !pathName.includes('/payment') &&
      !pathName.includes('/pre-publish') &&
      !pathName.includes('/review')
    )
      return SmartBannerTypes.CHANGE_EMAIL

    return SmartBannerTypes.NONE;
  }
  return {
    smartBannerVariant: bannerState(),
    hasTeammate: teammateExist && Boolean(channelInfo?.isVerified) && (channelId === channelInfo?.id),
    userNumbers
  }
};
export default useSmartBanner;
