import { ytJobsApi } from '../clients';
import {
  PaginateQueryResponse,
  SendEmailsInvitationResponse,
  SendErrorLogReq,
  SendErrorSucsessResponse,
  SendLoginLinkResponse,
} from '../interfaces';
import { ITestimonial } from '../interfaces/Channel';
import { UserMatchingItem } from '../interfaces/Matching';
import { IReferralInfo } from '../interfaces/User/Channel';
import { ExpressTalentLoginResponse } from '../interfaces/User/Talent';

export const expressUserLogin = async (
  token: string
): Promise<ExpressTalentLoginResponse> => {
  const { data } = await ytJobsApi.post<ExpressTalentLoginResponse>(
    `/auth/user/express`,
    {
      token,
    }
  );
  return data;
};

export const sendLoginLink = async (
  email: string
): Promise<SendLoginLinkResponse> => {
  const { data } = await ytJobsApi.post<SendLoginLinkResponse>(
    `/auth/login-link`,
    {
      email,
    }
  );
  return data;
};
export const sendEmailsInvitation = async (
  emails: string[]
): Promise<SendEmailsInvitationResponse> => {
  const { data } = await ytJobsApi.post<SendEmailsInvitationResponse>(
    `/referral/invite`,
    {
      emails,
    }
  );
  return data;
};
//////////
export const getReferralInfo = async (): Promise<IReferralInfo> => {
  const { data } = await ytJobsApi.get<IReferralInfo>(
    `/referral/info`
  );
  return data;
};
////////
export const sendErrorLog = async (
  params: SendErrorLogReq
): Promise<SendErrorSucsessResponse> => {
  ////////////
  const { data } = await ytJobsApi.post<SendErrorSucsessResponse>(
    `/utility/error-log`,
    {
      ...params,
    }
  );
  return data;
};

// Mathing Api

export const MachingAPI = {
  getList: async () => {
    const { data } = await ytJobsApi.get<UserMatchingItem[]>(`/profile/matchings`);
    return data;
  },
  addTestimony: async (talentId: string, companyId: string, testimony: string) => {
    const { data } = await ytJobsApi.post(`/profile/matchings/${talentId}/${companyId}/add-testimony`, {testimony});
    return data;
  },
  denyByChannel: async (talentId: string, companyId: string) => {
    const { data } = await ytJobsApi.post(`/profile/matchings/${talentId}/${companyId}/deny`);
    return data;
  },
  cancel: async (talentId: string, companyId: string) => {
    const { data } = await ytJobsApi.post(`/profile/matchings/${talentId}/${companyId}/cancel`);
    return data;
  }
}


// /feedback
export const SendFeedback = async (content: string) => {
  const { data } = await ytJobsApi.post(`/feedback`,{
    content,
    url: window.location.href
  });
  return data;
}
// /Company Add Video
export const companyAddVideo = async (url: string) => {
  const { data } = await ytJobsApi.post<{success: boolean; message: string;}>(`/company/youtube-videos`,{
    url,
  });
  return data;
}


// testimony list
export const getTestimonials = async ({ limit }: {limit: number;}) => {
  const { data } = await ytJobsApi.get<PaginateQueryResponse<ITestimonial>>(`/testimonials`, {
    params: { limit }
  });
  return data;
};

// User Testimonial
export const getUserTestimony = async () => {
  const { data } = await ytJobsApi.get<ITestimonial | null>(`/profile/testimonial`);
  return data;
};
export const addUserTestimony = async (testimony: string) => {
  const { data } = await ytJobsApi.post<ITestimonial | null>(`/profile/testimonial`, {testimony});
  return data;
};


// 
export const updateLastSources = async (
  latestCampaign: string | undefined,
  latestReferringSource: string | undefined,
  latestReferer: string | undefined,
) => {
  const { data } = await ytJobsApi.post<{success: boolean}>(`/profile/misc/update-latest-sources`, {
    latestCampaign,
    latestReferringSource,
    latestReferer
  });
  return data;
}