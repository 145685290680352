import { useQuery } from "react-query";
import { TalentQueryLabels } from "../../../api/interfaces/Talent";
import { getTalentActivityBoard, getTalentTimelineVideoList } from "../../../api/fetchers/Talent";
import { useEffect, useMemo, useState } from "react";
import { YtVideo } from "../../../Interfaces/Talent";



export const useActivityBoardData = (talentId?: string) => {

  // Activity Board
  const {
    isLoading,
    isError,
    data
  } = useQuery(
    [TalentQueryLabels.getTalentActivityBoard, talentId],
    () => getTalentActivityBoard({ id: talentId }),
    {
      enabled: !!talentId
    }
  );
  const videosNumber = useMemo(() => {
    if (data) {
      return Object.values(data).reduce((acc, item) => {
        acc = acc + item;
        return acc;
      }, 0);
    } else {
      return 0;
    }
  }, [data]);
  const firstVideoDate = useMemo(() => {
    const keys = Object.getOwnPropertyNames(data || {}).sort((a, b) => a.localeCompare(b));
    if (keys.length) {
      const _d = new Date(keys[0]);
      return `${_d.toLocaleString('default', { month: 'short' })} ${_d.getFullYear()}`;
    } else {
      return 'Unknown';
    }
  }, [data]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const Months = useMemo(() => (['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']), []);
  const [showMore, setShowMore] = useState(false);
  const allYearsOfRecentHistory = useMemo(() => {
    if (data) {
      // years of all vids added by talent.
      const allY = Array.from(
        new Set(
          Object.getOwnPropertyNames(data)
            .map(yearMonth => yearMonth.split('-')[0])
        )
      ).map(y => +y).sort((a, b) => b - a);

      const thisYear = new Date().getFullYear();
      if (allY.length && (allY[0] < (thisYear - 2))) {
        setShowMore(true);
      }
      if (allY.length) {
        setSelectedYear(allY[0]);
      }

      return allY;
    }
  }, [data]);
  const Years = useMemo(() => {
    const thisYear = new Date().getFullYear();
    const recent = [thisYear, thisYear - 1, thisYear - 2];
    if (showMore && allYearsOfRecentHistory) {
      return Array.from(new Set([...recent, ...allYearsOfRecentHistory]));
    } else {
      return recent;
    }
  }, [showMore, allYearsOfRecentHistory]);


  // TimeLine Video List
  const [allVidByMonth, setAllVidByMonth] = useState<Record<number, Record<string, YtVideo[]>>>({});
  const [vidByMonth, setVidByMonth] = useState<Record<string, YtVideo[]>>();
  const {
    isLoading: loadingVideoList,
    data: rawData
  } = useQuery(
    [TalentQueryLabels.getTalentTimelineVideos, selectedYear, talentId],
    () => getTalentTimelineVideoList({ id: talentId, year: selectedYear }),
    {
      enabled: !!selectedYear,
    }
  );

  useEffect(() => {
    if (rawData) {
      const { data } = rawData;
      const vidGroup: Record<string, YtVideo[]> = {};
      for (let index = 1; index <= 12; index++) {
        vidGroup[`${selectedYear}/${index.toString().padStart(2, '0')}`] = [];
      }
      for (const v of data) {
        const pub = v.publishedAt.substring(0, 7); // "2023/02/14" => '2023/02'
        if (vidGroup[pub]) {
          vidGroup[pub] = [...vidGroup[pub], v];
        }
      }
      setAllVidByMonth(c => ({ ...c, [selectedYear]: vidGroup }));
    }
  }, [rawData, selectedYear, setAllVidByMonth]);
  useEffect(() => {
    setVidByMonth(allVidByMonth[selectedYear]);
  }, [selectedYear, allVidByMonth, setVidByMonth]);

  // console.log({
  //   allYearsOfRecentHistory,
  // })
  const isNeedSeeMore = useMemo(() => {
    if (!allYearsOfRecentHistory) {
      return false;
    }
    const thisYear = new Date().getFullYear();
    const recent = [thisYear, thisYear - 1, thisYear - 2];
    return Array.from(new Set([...recent, ...allYearsOfRecentHistory])).length > 3;

  }, [allYearsOfRecentHistory]);

  return {
    isLoading,
    isError,
    data,
    videosNumber,
    firstVideoDate,
    Months,
    Years,
    showMore,
    setShowMore,
    hasSeeMore: isNeedSeeMore,
    selectedYear,
    setSelectedYear,
    loadingVideoList,
    vidByMonth
  }
}