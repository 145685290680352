import styled from 'styled-components/macro';
import { CenteredLoading } from '../../../../../CenteredLoading';
import useUser from '../../../../../../hooks/useUser';
import { YtVideo } from '../../../../../../Interfaces/Talent';
import { TbReplace } from 'react-icons/tb';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { AxiosErrorHandler } from '../../../../../../api/utils';
import SuccessToast from '../../../../../../Elements/Toast/Success';
import { hoverSmallAnimation } from '../../../../../../Styles/Animation';
import { Container, Thumbnail } from '../StyledComponents';
import { swapPosterImage } from '../../../../../../api/fetchers/User/Talent';
// import { QueryCache } from '@tanstack/react-query';

interface Props {
  video: YtVideo;
  originVideoId: string;
  className?: string;
  setAddOrEditVideoPopup: React.Dispatch<React.SetStateAction<boolean>>;
}
const PosterThumbnailForReplacePopup = ({
  video,
  className,
  originVideoId,
  setAddOrEditVideoPopup,
}: Props) => {
  const { talentInfo, isLoadingTalent, refetchTalentInfo } =
    useUser();
  const mutation = useMutation(
    () =>
      swapPosterImage({
        originVideoId: originVideoId,
        targetVideoId: video.id,
        talentId: talentInfo?.id || '',
      }),
    {
      onSuccess: () => {
        SuccessToast('Change applied successfully!');
        refetchTalentInfo();
        setAddOrEditVideoPopup(false);
      },
      onError: (error) => {
        AxiosErrorHandler(error as AxiosError);
      },
    }
  );

  return (
    <StyledContainer className={className}>
      {isLoadingTalent || mutation.isLoading ? (
        <CenteredLoading />
      ) : (
        video?.thumbnail && (
          <ThumbnailContainer
            onClick={() => {
              if (!mutation.isLoading) mutation.mutate();
            }}
          >
            <Thumbnail src={video?.thumbnail} alt={video?.title} />
            <Overlay>
              <ReplaceIcon />
            </Overlay>
          </ThumbnailContainer>
        )
      )}
    </StyledContainer>
  );
};

export default PosterThumbnailForReplacePopup;

const StyledContainer = styled(Container)`
  &: hover {
    ${hoverSmallAnimation}
  }
`;
const ThumbnailContainer = styled.div``;
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #0000009c;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ReplaceIcon = styled(TbReplace)`
  width: 2rem;
  height: 2rem;
  color: white;
  transition: all 0.5s ease;
  ${StyledContainer}:hover & {
    transform: rotate(90deg);
  }
`;
