import React, { HTMLAttributes } from 'react';
import DesktopFooter from './DesktopFooter';
import MobileFooter from './MobileFooter';
import useDevice from '../../hooks/useDevice';
import { MinimalFooter } from './MinimalFooter';
import { OnlyForLandings } from './OnlyForLanding';
interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  minimal?: boolean;
  onlyForLandings?: boolean;
}
const SmartFooter: React.FC<Props> = ({ className, minimal = false, onlyForLandings = false, ...props }) => {
  const { isLaptop } = useDevice();
  if (minimal) {
    return <MinimalFooter {...props} className={className}/>
  }
  if (onlyForLandings) {
    return <OnlyForLandings {...props} className={className}/>
  }
  if (isLaptop) return <DesktopFooter {...props} className={className} />;
  return <MobileFooter {...props} className={className} />;
};
export default SmartFooter;
