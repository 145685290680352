
const REFFERAL_KEY = 'nkjSfd8dSvfS9';
const UTM_CAMPAIN_KEY = 'campjSfd8dSvfS9utm';
const URL_REFERRER = 'u0r9lr8e1f7er0r4er'

interface IItem {
  refferer: string;
  date: number;
}
interface IItemUtml {
  utm: string;
  date: number;
}
interface IItemURLReff {
  url_referrer: string;
  date: number;
}

// 1 month represented by ms
const VALID_FOR = 30 * 24 * 60 * 60 * 1000;

const getItem = () => {
  const itemString = localStorage.getItem(REFFERAL_KEY) ||
    JSON.stringify({
      refferer: '',
      date: 1,
    } as IItem);
  return JSON.parse(itemString) as IItem;
};
const setItem = (refferer: string) => {
  localStorage.setItem(REFFERAL_KEY, JSON.stringify(
    {
      refferer,
      date: Date.now(),
    }
  ))
};

export class RefferalHandler {
  static SetRefferer(refferer: string) {
    setItem(refferer);
  }
  static GetRefferer(): string | undefined {
    const item = getItem();
    if ((Date.now() - item.date) < VALID_FOR) {
      // setTimeout(() => {
      //   localStorage.removeItem(REFFERAL_KEY);
      // }, 500)
      return item.refferer;
    }
    return undefined;
  }
}
export class UtmCampainHandler {
  static SetUtm(utmCampain: string) {
    localStorage.setItem(UTM_CAMPAIN_KEY, JSON.stringify(
      {
        utm: utmCampain,
        date: Date.now(),
      } as IItemUtml
    ))
  }
  static GetUtm(): string | undefined {
    const itemString = localStorage.getItem(UTM_CAMPAIN_KEY) ||
      JSON.stringify({
        utm: '',
        date: 1,
      } as IItemUtml);
    const item = JSON.parse(itemString) as IItemUtml;
    if ((Date.now() - item.date) < VALID_FOR) {
      return item.utm;
    }
    return undefined;
  }
}
export class URLReferrerHandler {
  static SetURLRef(ref: string) {
    localStorage.setItem(URL_REFERRER, JSON.stringify(
      {
        url_referrer: ref,
        date: Date.now(),
      } as IItemURLReff
    ))
  }
  static GetURLRef(): string | undefined {
    const itemString = localStorage.getItem(URL_REFERRER) ||
      JSON.stringify({
        url_referrer: '',
        date: 1,
      } as IItemURLReff);
    const item = JSON.parse(itemString) as IItemURLReff;
    if ((Date.now() - item.date) < VALID_FOR) {
      return item.url_referrer;
    }
    return undefined;
  }
}