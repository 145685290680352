import styled from 'styled-components/macro';
import React, { useEffect, useMemo } from 'react';
import {
  ModalContainer,
  ModalWrapper,
  ModalGrayBlank,
  ModalContent,
  ModalGrayDash,
} from '../../Styles/ModalStyles';
import { device, deviceMin } from '../../consts/device';
import { JobListForOwner } from '../../Interfaces/Job';
import useUser from '../../hooks/useUser';
import { Button } from '../../Elements/Button';
import { useNavigate } from 'react-router-dom';
import { GoogleAnalytic } from '../../Utils/ga';

interface Props {
  close: () => void;
  cartItem: JobListForOwner;
}
export const PublishByCartModal: React.FC<Props> = ({ close, cartItem }) => {

  const { channelInfo } = useUser()
  const isSmall = useMemo(() => !!channelInfo && (parseInt(`${channelInfo.subscribers || 0}`)) < 1000, [channelInfo])

  const navigate = useNavigate()

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <ModalContainer style={{ zIndex: 9999999 }}>
      <ModalWrapper>
        <ModalGrayBlank
          onClick={close}
        />
        <StyledModalContent>
          <ContentWrapper>
            <ModalGrayDash />

            {isSmall && (
              <>
                <Row>
                  <Title>Limited-Time Offer: Publish Your Job and Save [$100]!</Title>
                </Row>

                <TextBody>
                  You have a drafted job ready to reach top-tier candidates. Publish your job 
                  post now and enjoy a <b>[$100] discount</b>. Act quickly—this offer won't last 
                  long! Enter the code in the promotion code field during checkout.
                </TextBody>

                <TextBody>
                  <b>Your code: FIRSTOFF</b>
                </TextBody>
              </>
            )}


            {!isSmall && (
              <>
                <Row>
                  <Title>Limited-Time Offer: Publish Your Job and Save [$50]!</Title>
                </Row>

                <TextBody>
                  You have a drafted job ready to reach top-tier candidates. Publish your job
                  post now and enjoy a <b>[$50] discount</b>. Act quickly—this offer won't last
                  long! Enter the code in the promotion code field during checkout.
                </TextBody>

                <TextBody>
                  <b>Your code: FIRSTHIRING</b>
                </TextBody>
              </>
            )}

            <Row className='mobcol'>
              <Button variant='NAKE' onClick={close}>Cancel</Button>
              <div style={{ flex: 1 }} />
              <Button variant='PRIMARY' onClick={() => {
                GoogleAnalytic.CartIconModalPublishClick()
                navigate(`/jobs/${cartItem.id}/checkout`)
              }}>Publish the Job</Button>
            </Row>



          </ContentWrapper>
        </StyledModalContent>
      </ModalWrapper>
    </ModalContainer>
  );
};

const StyledModalContent = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 0px 16px 68px 16px;
  @media ${deviceMin.tabletL} {
    width: 606px;
    max-width: 80%;
    padding: 24px;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: fit-content;
  width: 100%;
`;
const Row = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      height: 52px;
    }
    @media ${device.mobileL} {
      &.mobcol {
        flex-direction: column-reverse;
        button {
          height: 52px;
          gap: 8px;
          width: 100%;
        }
      }
    }
`;
const Title = styled.h1`
    font-weight: 700;
    font-size: 18px;
    font-family: Poppins;
    flex: 1;
    text-align: left;
    line-height: 23px;
    margin-bottom: 20px;
    color: var(--color-gray1);
    @media ${device.mobileL} {
      font-size: 16px;
      font-weight: 600;
    }
`;
const TextBody = styled.p`
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 26px;
    color: var(--color-gray1);
    > b {
      font-weight: 600;
    }
`;
