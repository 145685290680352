import React from 'react';
import { useState } from 'react';

type Context = {
  linkValue: string;
  setLinkValue: React.Dispatch<React.SetStateAction<string>>;
  descValue: string;
  setDescValue: React.Dispatch<React.SetStateAction<string>>;
  setEmptyValues: () => void;
  videoId: string;
  setVideoId: React.Dispatch<React.SetStateAction<string>>;
};
type Props = {
  children: React.ReactNode;
};

const modalValueContext = React.createContext({} as Context);

function MoadalValueContextProvider({ children }: Props) {
  const [linkValue, setLinkValue] = useState('');
  const [descValue, setDescValue] = useState('');
  const [videoId, setVideoId] = useState(''); // if value of videoid==='' means we are addNewPopup , else we are in editVideoPopup

  function setEmptyValues() {
    setLinkValue('');
    setDescValue('');
    setVideoId('');
  }
  ///////////////////
  return (
    <modalValueContext.Provider
      value={{
        linkValue,
        setLinkValue,
        descValue,
        setDescValue,

        setEmptyValues,
        videoId,
        setVideoId,
      }}
    >
      {children}
    </modalValueContext.Provider>
  );
}
export { MoadalValueContextProvider, modalValueContext };
