import { useMemo } from 'react';
import {
  getJobsByOwner,
  getVerificationVideos,
} from "../../../api/fetchers/User/Channel";
import { ClaimedToWork } from "../../../Interfaces/Channel";
import { useQuery } from 'react-query';
import { GetJobListByOwnerResponse, IChannelSettings, UserChannelQueryLabels } from '../../../api/interfaces/User/Channel';
import { JobStatus } from '../../../Interfaces/Job';
import { JobQueryLabels } from '../../../api/interfaces/Job';
import { getTalentSettings } from '../../../api/fetchers/User/Talent';

export const useChannelData = (isChannel: boolean) => {
  const {
    data: channelJobs,
  } = useQuery<GetJobListByOwnerResponse, Error>(
    [UserChannelQueryLabels.getJobsByOwner],
    () => getJobsByOwner(),
    {
      staleTime: 600000,
      enabled: isChannel,
    }
  );
  const query = useQuery<ClaimedToWork[]>(
    [JobQueryLabels.getVerificationTalent],
    getVerificationVideos,
    { enabled: isChannel }
  );

  const teammateList = useMemo(() => query.data || [], [query.data])

  const { data: channelSettings } =
    useQuery<IChannelSettings>(
      [UserChannelQueryLabels.getSettings],
      () => (getTalentSettings() as unknown as Promise<IChannelSettings>),
      { enabled: isChannel }
    );

  const hasChannelActiveJob = useMemo(
    () => !!channelJobs?.some(job => job.status === JobStatus.ACTIVE),
    [channelJobs]
  );
  return {
    usersWithActionNeed: teammateList.filter(item => item.videos.length && item.videos.some(v => v.status === 'undecided')),
    teammateExist: Boolean(teammateList.length),
    userNumbers: teammateList.filter(user => user.videos.length && user.videos.every(v => v.status === 'undecided')).length,
    hasChannelActiveJob: !!channelJobs ? hasChannelActiveJob : undefined,
    query,
    channelSettings
  };
};
