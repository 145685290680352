import { FC, HTMLAttributes, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { device } from "../../consts/device";


interface Props extends HTMLAttributes<HTMLParagraphElement> {
  text: string;
  heightFree?: boolean;
}
export const TypoSeeMore: FC<Props> = ({text, heightFree, ...props }) => {

  const [overflowed, setOverflowed] = useState(false);
  const [showMore, setShow] = useState(false);
  const [item, setItem] = useState<HTMLDivElement>();
  const heightRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      setItem(node);
      setOverflowed(
        node.clientWidth < node.scrollWidth
        || node.clientHeight < node.scrollHeight
      )
    }
  }, [setOverflowed, setItem]);
  useEffect(() => {
    if (!showMore && item) {
      item.scrollTo(0, 0);
    }
  }, [showMore, item]);

  return (
    <Desc ref={heightRef} showMore={showMore} heightFree={!!heightFree} {...props}>
      {text}
      {overflowed && (
        <Seemore onClick={() => setShow(s => !s)}>
          {!showMore && (<Fader />)}
          <span>see {showMore ? 'less' : 'more'}</span>
        </Seemore>
      )}
    </Desc>
  )
}

const Desc = styled.p<{ showMore?: boolean; heightFree: boolean;}>`
  font-size: 16px;
  line-height: 26px;
  color: var(--color-gray2);
  margin-bottom: 0.25rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  max-height: 52px;
  max-width: 412px;
  ${({ showMore, heightFree }) => showMore && css`
    max-height: 500vh;
    padding-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    ${heightFree ? 'display: block;' : ''}
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray4);
    }
    > span {
      position: unset;
      /* width: 100%; */
      text-align: right;
      display: unset !important;
      margin-left: 4px;
    }
  `}
  position: relative;
  @media ${device.mobileL} {
    margin-bottom: 0.25rem;
  }
`;
const Seemore = styled.span`
  position: absolute;
  font-size: 14px;
  bottom: 0;
  right: 0;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  background: transparent;
  > span {
    color: var(--color-secondary);
    background-color: var(--color-bg1);
  }
`
const Fader = styled.div`
  background: linear-gradient(90deg, #00000000 0%, var(--color-bg1) 85%);
  width: 40px;
  height: 18px;
`