import { HTMLAttributes } from 'react';
import {
  YoutubeVideosCollection,
} from '../../../../../../Interfaces/Talent';
import { getSmartThumbnails } from '../../../Utils';
import {
  Content,
  Top,
  TitleWrapper,
  VitrineTitle,
  Texts,
  Videos,
  Likes,
  Views,
  Thumbnails,
  ChannelSection,
  ChanneslTitle,
  ChannelProfiles,
} from '../../StyledComponents';

import PosterThumbnailForReplacePopup from './PosterThumbnail';
import ChannelThumbnail from '../../../../../Channel/ChannelThumbnail';
import styled from 'styled-components/macro';
import { isACompany } from '../../../../../../Utils/channel';

interface Props extends HTMLAttributes<HTMLDivElement> {
  collection: YoutubeVideosCollection;
  originVideoId: string;
  setAddOrEditVideoPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const SwapThumbnailPopup: React.FC<Props> = ({
  collection,
  originVideoId,
  setAddOrEditVideoPopup,
  ...props
}) => {
  

  ///////////////////

  return (
    <Content {...props}>
      <Top>
        <TitleWrapper>
          <VitrineTitle>Portfolio</VitrineTitle>
        </TitleWrapper>
        <Texts>
          <Videos>
            {collection?.statistics?.counts}
            {collection?.statistics?.counts === 1 ? " Video" : " Videos"}
          </Videos>
          <Likes>{collection?.statistics?.abvLikes} Likes</Likes>
          <Views>{collection?.statistics?.abvViews} Views</Views>
        </Texts>
        <Thumbnails>
          {collection?.videos &&
            getSmartThumbnails(collection?.videos)?.map((video, i) => {
              return (
                <PosterThumbnailForReplacePopup
                  key={i}
                  video={video}
                  setAddOrEditVideoPopup={setAddOrEditVideoPopup}
                  originVideoId={originVideoId}
                />
              );
            })}
        </Thumbnails>
      </Top>
      <ChannelSection>
        <ChanneslTitle>Some of my previous clients ...</ChanneslTitle>
        <ChannelProfiles>
          {collection?.channels.map((channel, i) => {
            if (i < 6)
              return (
                <StyledChannelThumbnail
                  avatar={channel?.avatar}
                  isActive={true}
                  isCompany={isACompany(channel)}
                  abvSubscribers={channel?.abvSubscribers || ""}
                  variant="TYPE4"
                  
                />
              );
          })}
        </ChannelProfiles>
      </ChannelSection>
    </Content>
  );
};
export default SwapThumbnailPopup;
const StyledChannelThumbnail = styled(ChannelThumbnail)`
  background: var(--color-bg1);
`;
