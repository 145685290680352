import { HTMLAttributes } from 'react';
import styled from 'styled-components/macro';

interface InputProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  variant?: 'INFO' | 'ALERT';
}

export const Sticker = ({
  className,
  variant = 'INFO',
  children,
  ...props
}: InputProps) => {
  return (
    <Container className={className} variant={variant} {...props}>
      {children}
    </Container>
  );
};

const Container = styled.div<{ variant: 'INFO' | 'ALERT' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 160px;
  height: 3rem;
  overflow: hidden;

  background-color: ${({ variant }) =>
    variant === 'INFO' ? '#fcf59b' : 'var(--color-sticky-bg-alert)'};
  color: var(--color-gray1);
  border-radius: 0px 10px 250px / 0 200px 55px 250px;

  box-shadow: -3px 5px 12px 0 rgba(0, 0, 0, 0.3);
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    width: 90%;
    height: 20px;

    bottom: 15px;
    right: 5px;
    transform: skew(-10deg, 2deg);
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    right: 7px;
    bottom: -1px;
    border-top: 10px solid rgb(198 178 199);
    border-right: 10px solid transparent;
    -webkit-transform: skew(-55deg, 10deg);
    transform: skew(-55deg, 10deg);
  }
`;
