import { FC, useMemo } from "react";
import styled from "styled-components";
import { Voucher } from "../../../../../api/interfaces/User/Talent";
import { TalentInfo } from "../../../../../Interfaces/Talent";
import ChannelThumbnail from "../../../../Channel/ChannelThumbnail";
import TalentThumbnail from "../../../TalentThumbnail";
import { isACompany } from "../../../../../Utils/channel";
import { MdiIcons } from "../../../../../Elements/MdiIcons";
import { mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js";
import { useMutation, useQueryClient } from "react-query";
import { setHiddenOrShownVouch } from "../../../../../api/fetchers/User/Talent";
import { TalentQueryLabels } from "../../../../../api/interfaces/Talent";
import SuccessToast from "../../../../../Elements/Toast/Success";
import { AxiosErrorHandler } from "../../../../../api/utils";
import { AxiosError } from "axios";
import YtIcon from "../../../../../Elements/YtIcons";
import { YtjobsIcons } from "../../../../../Interfaces";


interface VoucheListItemProps {
  vouche: Voucher;
  talentInfo: TalentInfo | undefined;
  isOwner: boolean;
}
export const VoucheListItem: FC<VoucheListItemProps> = ({ vouche, isOwner, talentInfo }) => {

  const currentVoucher = useMemo(() => vouche.vouchedBy, [vouche])
  const isHidden = useMemo(() => vouche ? vouche.isHidden : true, [vouche])
  const talentId = useMemo(() => talentInfo?.id || '', [talentInfo])
  const queryClient = useQueryClient();

  const mutation = useMutation(
    () =>
      setHiddenOrShownVouch({
        talentId,
        vouchId: vouche.id,
        isHidden: !isHidden,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          TalentQueryLabels.getVouchesList,
          talentId,
        ]);
        SuccessToast(
          `This vouch message is now ${isHidden ? 'visible' : 'hidden'
          } for others.`
        );
      },
      onError: (error) => {
        AxiosErrorHandler(error as AxiosError);
      },
    }
  );

  return (
    <>
      <Row>
        {vouche.vouchedByType ===
          'Company' ? (
          <StyledChannelThumbnail
            variant='TYPE1'
            channelName={currentVoucher?.name}
            isCompany={isACompany(currentVoucher)}
            //@ts-ignore
            abvSubscribers={currentVoucher?.abvSubscribers}
            avatar={currentVoucher?.avatar}
            viewer='OTHERS'
            isActive={true}
            isVerified={currentVoucher?.isVerified}
            location='OTHER_PROFILE_PAGE'
            channelId={currentVoucher?.id}
            isClickable={true}
          />
        ) : (
          <StyledTalentThumbnail
            avatar={currentVoucher?.avatar}
            functionalArea={
              //@ts-ignore
              currentVoucher?.functionalArea}
            talentName={currentVoucher?.name || ''}
            //@ts-ignore
            isVerified={currentVoucher?.isVerified || false}
            isLoading={false}
            isClickable={true}
            talentId={currentVoucher?.id}
            //@ts-ignore
            bestBadge={currentVoucher?.bestBadge}
          />
        )}
        {!isOwner && (
          <div />
        )}
        {isOwner && (
          <>
            {mutation.isLoading && (
              <IconContainer><YtIcon variant={YtjobsIcons.loading} /></IconContainer>
            )}
            {(!mutation.isLoading && isHidden) && (
              <IconContainer onClick={() => mutation.mutate()}>
                <MdiIcons path={mdiEyeOutline} />
                <p>Show</p>
              </IconContainer>
            )}
            {(!mutation.isLoading && !isHidden) && (
              <IconContainer onClick={() => mutation.mutate()} title="Hide">
                <MdiIcons path={mdiEyeOffOutline} />
              </IconContainer>
            )}
          </>
        )}
      </Row>
      <Quote>“{vouche.recommendationText}“</Quote>
    </>
  )
}

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const Quote = styled.div`
  color: var(--color-gray2);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
  padding-left: 56px;
  margin-top: -16px;
`
const StyledChannelThumbnail = styled(ChannelThumbnail)`
  width: max-content;
  img {
    width: 48px;
    height: 48px;
  }
`
const StyledTalentThumbnail = styled(TalentThumbnail)`
  width: max-content;
  > div:first-child {
    img {
      width: 48px;
      height: 48px;
    }
    margin-right: 8px;
    width: 48px;
    height: 48px;
  }
`
const IconContainer = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  > svg {}
  > p {
    color: var(--color-gray1);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    user-select: none;
  }

`