import React, { useState } from 'react';
import { PublicPopupVariant, SharedProfileData } from '../Interfaces';
import { ChannelInfo } from '../Interfaces/Channel';

export interface ITargetUser extends SharedProfileData {
  jobId?: string;
} 

type Context = {
  activeModalOpen: PublicPopupVariant;
  setActiveModalOpen: React.Dispatch<React.SetStateAction<PublicPopupVariant>>;
  targetUser: ITargetUser | undefined;
  targetChannel: ChannelInfo | undefined;
  setTargetUser: React.Dispatch<React.SetStateAction<ITargetUser | undefined>>;
  setTargetChannel: React.Dispatch<React.SetStateAction<ChannelInfo | undefined>>;

};
type Props = {
  children: React.ReactNode;
};

const PublicModalContext = React.createContext({} as Context);
function PublicModalContextProvider({ children }: Props) {
  const [activeModalOpen, setActiveModalOpen] = useState<PublicPopupVariant>(
    PublicPopupVariant.NONE
  );
  const [targetUser, setTargetUser] = useState<ITargetUser | undefined>();
  const [targetChannel, setTargetChannel] = useState<ChannelInfo | undefined>();
  ///////////////////
  return (
    <PublicModalContext.Provider
      value={{
        activeModalOpen,
        setActiveModalOpen,
        targetUser,
        setTargetUser,
        targetChannel,
        setTargetChannel
      }}
    >
      {children}
    </PublicModalContext.Provider>
  );
}
export { PublicModalContextProvider, PublicModalContext };
