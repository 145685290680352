import TextareaAutosize from 'react-autosize-textarea';

import { HTMLAttributes, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';

interface Props extends HTMLAttributes<HTMLTextAreaElement> {
  // eslint-disable-next-line
  onChange?: any;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  focus?: boolean;
}

export const TextArea: React.FC<Props> = ({
  value,
  disabled,
  onChange,
  placeholder,
  className,
  focus = false,
  ...props
}) => {
  const inputElement = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputElement?.current && focus) {
      inputElement?.current?.focus();
    }
  }, [focus]);
  return (
    <Container className={className}>
      <StyledTextarea
        {...props}
        onChange={onChange}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        ref={inputElement}
        id={"text-area"}
      />
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
const StyledTextarea = styled(TextareaAutosize)`
  display: flex;
  padding-bottom: 8px;
  border-bottom: 2px solid var(--color-gray4);
  width: 100%;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  ::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-gray4);
  }
`;
