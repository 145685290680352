import { FC, HTMLAttributes } from "react"
import styled, { css } from "styled-components"
import { Button } from "../../../Elements/Button";
import { device } from "../../../consts/device";
import YoutubeLogo from '../../../Assests/Images/yt_logo_rgb_light.png';
import YoutubeLogoDark from '../../../Assests/Images/yt_logo_rgb_dark.png';
import { Input } from "../../../Elements/Inputs/Input";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { CenteredLoading } from "../../CenteredLoading";
import { isDarkMode } from "../../../Utils";



// *************   OrLine   *************  //
export const OrLine = styled.div`
  width: 100%;
  height: 27px;
  min-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 24px 0;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    border-top: 1px solid var(--color-gray5);
    z-index: 1;
  }
  &::after {
    content: 'Or';
    width: 50px;
    background-color: var(--color-bg1);
    position: absolute;
    left: 50%;
    transform: translateX(-25px);
    z-index: 2;
    color: var(--color-gray4);
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
  @media ${device.mobileL} {
    margin: 16px 0;
  }
`


// *************   GoogleButton   *************  //
type PropsFromApple<TComponent> = TComponent extends React.FC<infer Props> ? Props : never;

export const LoginWithApple: FC<PropsFromApple<typeof Button>> = ({ children, ...props }) => {
  return (
    <StyledAppleButton {...props}>
      <StyledAppleIcon /> {!!children ? children : 'Sign up with Apple'}
    </StyledAppleButton>
  )
}
const StyledAppleButton = styled(Button)`
  height: 50px;
  width: 100%;
  z-index: 1;
  min-width: 261px;
  border-radius: 10px;
  > svg > rect {
    fill: var(--color-gray1);
  }
  ${({ disabled }) => !disabled && css`
    font-size: 16px;
    font-weight: 600;
    color: var(--color-gray1);
    background-color: var(--color-bg2);
    border: 2px solid var(--color-gray1);
    &:hover {
      background-color: var(--color-gray6) !important;
    }
  ` }
  ${({ disabled }) => disabled && css`
    background-color: var(--color-bg2) !important;
    opacity: 0.9;
    color: var(--color-gray1) !important;
    border: 2px solid var(--color-gray3);
    &:hover {
      background-color: var(--color-bg2) !important;
    }
  ` }
  @media ${device.tabletS} {
  }
`;
const AppleIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12.2363 6.98601C13.016 6.98601 13.9932 6.45894 14.5752 5.75617C15.1023 5.11929 15.4866 4.22984 15.4866 3.3404C15.4866 3.21962 15.4756 3.09883 15.4537 3C14.5862 3.03294 13.543 3.58198 12.9171 4.31769C12.423 4.87771 11.9728 5.75617 11.9728 6.65659C11.9728 6.78836 11.9947 6.92013 12.0057 6.96405C12.0606 6.97503 12.1485 6.98601 12.2363 6.98601ZM9.49113 20.2727C10.5563 20.2727 11.0284 19.559 12.3571 19.559C13.7077 19.559 14.0042 20.2508 15.1901 20.2508C16.3541 20.2508 17.1337 19.1747 17.8694 18.1205C18.693 16.9126 19.0334 15.7267 19.0554 15.6718C18.9785 15.6498 16.7494 14.7384 16.7494 12.1799C16.7494 9.9618 18.5063 8.96255 18.6052 8.88568C17.4412 7.21661 15.6733 7.17269 15.1901 7.17269C13.8834 7.17269 12.8183 7.9633 12.1485 7.9633C11.4237 7.9633 10.4684 7.21661 9.3374 7.21661C7.18517 7.21661 5 8.99549 5 12.3556C5 14.4419 5.81258 16.6491 6.81182 18.0766C7.66832 19.2845 8.41501 20.2727 9.49113 20.2727Z" fill="var(--color-gray1)" />
  </svg>
)
const StyledAppleIcon = styled(AppleIcon)`
  margin-right: 6px;
  width: 32px;
  height: 32px;
`
// *************   GoogleButton   *************  //
type PropsFrom<TComponent> = TComponent extends React.FC<infer Props> ? Props : never;

export const LoginWithGoogle: FC<PropsFrom<typeof Button>> = ({ children, ...props }) => {
  return (
    <StyledButton {...props}>
      <StyledGoogleIcon /> {!!children ? children : 'Sign up with Google'}
    </StyledButton>
  )
}
const StyledButton = styled(Button)`
  height: 50px;
  width: 100%;
  z-index: 1;
  min-width: 261px;
  border-radius: 10px;
  > svg > rect {
    fill: var(--color-primary);
  }
  ${({ disabled }) => !disabled && css`
    font-size: 16px;
    font-weight: 600;
    color: var(--color-gray25);
    background-color: var(--color-bg2);
    border: 2px solid var(--color-gray4);
    &:hover {
      background-color: var(--color-gray6) !important;
    }
  ` }
  ${({ disabled }) => disabled && css`
    background-color: var(--color-bg2) !important;
    opacity: 0.4;
    color: var(--color-gray1) !important;
    border: 2px solid var(--color-gray3);
    &:hover {
      background-color: var(--color-bg2) !important;
    }
  ` }
  @media ${device.tabletS} {
  }
`;
const GoogleIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props) => (
  <svg {...props} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.14 9.20468C18.14 8.5665 18.0827 7.95286 17.9764 7.36377H9.5V10.8451H14.3436C14.135 11.9701 13.5009 12.9233 12.5477 13.5615V15.8197H15.4564C17.1582 14.2529 18.14 11.9456 18.14 9.20468Z" fill="#4285F4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.49976 18C11.9298 18 13.967 17.1941 15.4561 15.8195L12.5475 13.5613C11.7416 14.1013 10.7107 14.4204 9.49976 14.4204C7.15567 14.4204 5.17158 12.8372 4.46385 10.71H1.45703V13.0418C2.93794 15.9831 5.98158 18 9.49976 18Z" fill="#34A853" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.46409 10.7098C4.28409 10.1698 4.18182 9.59301 4.18182 8.99983C4.18182 8.40664 4.28409 7.82983 4.46409 7.28983V4.95801H1.45727C0.847727 6.17301 0.5 7.54755 0.5 8.99983C0.5 10.4521 0.847727 11.8266 1.45727 13.0416L4.46409 10.7098Z" fill="#FBBC05" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.49976 3.57955C10.8211 3.57955 12.0075 4.03364 12.9402 4.92545L15.5216 2.34409C13.9629 0.891818 11.9257 0 9.49976 0C5.98158 0 2.93794 2.01682 1.45703 4.95818L4.46385 7.29C5.17158 5.16273 7.15567 3.57955 9.49976 3.57955Z" fill="#EA4335" />
  </svg>
)
const StyledGoogleIcon = styled(GoogleIcon)`
  margin-right: 10px;
  width: 24px;
  height: 24px;
`

// *************   YoutubeButton   *************  //
export const YoutubeButton: FC<PropsFrom<typeof Button>> = ({ children, ...props }) => {
  return <VerificationButton {...props}>
    {!!children ? children : 'Sign Up With '} <Youtube src={isDarkMode() ? YoutubeLogoDark : YoutubeLogo} alt={'youtube'} />
  </VerificationButton>
}
const VerificationButton = styled(Button)`
  height: 50px;
  z-index: 1;
  min-width: 261px;
  width: 100%;
  border-radius: 10px;
  > svg > rect {
    fill: var(--color-primary);
  }
  ${({ disabled }) => !disabled && `
    font-size: 16px;
    letter-spacing: 0px;
    color: var(--color-primary);
    background-color: var(--color-bg2);
    border: 2px solid var(--color-primary);
    &:hover {
      background-color: var(--color-bg2) !important;
    }
  ` }
  @media ${device.tabletS} {
  }
`;
const Youtube = styled.img`
  height: 20px;
  width: auto;
  margin-left: 6px;
`;



// *************   Emplyer Signup Elements   *************  //
export const VSpacer = styled.div<{ h: number; mh?: number; }>`
  width: 100%;
  ${({ h }) => css`min-height: ${h}px;`}
  @media ${device.mobileL} {
    ${({ mh }) => mh && css`min-height: ${mh}px;`}
  }
`;
export const HSpacer = styled.div<{ w: number; mw?: number; }>`
  height: 100%;
  ${({ w }) => css`min-width: ${w}px;`}
  @media ${device.mobileL} {
    ${({ mw }) => mw && css`min-height: ${mw}px;`}
  }
`;
export const Title = styled.h1`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  color: var(--color-gray1);
  @media ${device.mobileL} {
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
  }
`
export const SubTitle = styled.h1`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-gray1);
  @media ${device.mobileL} {
    font-size: 16px;
    line-height: 24px;
  }
`
export const ActionText = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: var(--color-gray2);
  > span {
    color: var(--color-primary);
    cursor: pointer;
    text-decoration: underline;
    margin-left: 4px;
  }
  @media ${device.mobileL} {
    width: 100%;
    text-align: left;
    font-size: 12px;
  }
`;
export const ActionText2 = styled(Button)`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  width: 100%;
  color: var(--color-primary);
  cursor: pointer;
  text-decoration: underline;
`;
export const SignUpContainer = styled.div<{ maxWidth?: number; }>`
  width: 100%;
  max-width: 386px;
  ${({ maxWidth }) => !!maxWidth && css`
    max-width: ${maxWidth}px;
  `}
  margin: 0 auto;
  @media ${device.mobileL} {
    max-width: unset;
    padding: 0 16px;
  }
`
export const SignUpStepEdit = styled.div`
  width: 100%;
  color: var(--color-gray3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  > span:first-child {
    font-size: 16px;
    max-width: calc(100% - 35px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > span:last-child {
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
  }
`

// *************   Input With Action   *************  //
type TInputWithAct = PropsFrom<typeof Input> & {
  lableText: string;
  isValid: boolean;
  onActionClick: () => void;
  isLoading?: boolean;
}
export const InputWithAction: FC<TInputWithAct> = ({
  name,
  lableText,
  isValid,
  isLoading = false,
  onActionClick,
  ...props
}) => {
  return (
    <InputActionContainer>
      <Label htmlFor={name}>{lableText}</Label>
      <StyledInput
        name={name}
        {...props}
      />
      <ActionIcon disabled={!isValid || isLoading} onClick={() => {
        if (isValid) onActionClick();
      }} />
      {isLoading && <Loading>
        <CenteredLoading />
      </Loading>}
    </InputActionContainer>
  )
}
const InputActionContainer = styled.div`
  position: relative;
  width: 100%;
  > div > h2 {
    font-size: 12px;
    line-height: 19px;
    margin: 0;
    margin-bottom: 24px;
    width: 100%;
    text-align: left;
  }
`;
const StyledInput = styled(Input)`
  margin-top: 8px;
  > div > input {
    padding-right: 32px;
  }
`
const Label = styled.label`
  line-height: 22px;
  font-size: 14px;
  color: var(--color-gray2);
  margin-right: auto;
`;
const ActionIcon = styled(BsArrowRightCircleFill) <{ disabled?: boolean; }>`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  bottom: 10px;
  cursor: pointer;
  user-select: none;
  color: var(--color-primary);
  ${({ disabled }) => !!disabled && css`
    cursor: default;
    color: var(--color-primary-light1);
  `}
`
const Loading = styled.div`
  position: absolute;
  inset: 0;
  top: 20px;
  background-color: var(--color-smooth-cover);
  display: flex;
  justify-content: center;
  align-items: center;
`

// *************   Input With Label   *************  //
type TInputWithLabel = PropsFrom<typeof Input> & {
  lableText: string;
}
export const InputWithLabel: FC<TInputWithLabel> = ({
  name,
  lableText,
  ...props
}) => {
  return (
    <InputActionContainer>
      <Label htmlFor={name}>{lableText}</Label>
      <StyledInput
        name={name}
        {...props}
      />
    </InputActionContainer>
  )
}


// *************   Login Tab El   *************  //
export const TabContainer = styled.div<{ activeNumber: 1 | 2; }>`
  display: flex;
  width: 100%;
  min-height: 42px;
  > div {
    display: flex;
    min-height: 42px;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: var(--color-gray6);
    border: 1px solid var(--color-gray4);
    color: var(--color-gray3);
    font-size: 14px;
    font-weight: 500;
    line-height: 31px;
    cursor: pointer;
    user-select: none;
  }
  > div:first-child {
    border-radius: 8px 0 0 8px;
  }
  > div:last-child {
    border-radius: 0 8px 8px 0;
  }
  ${({ activeNumber }) => css`
    > div:nth-child(${activeNumber}) {
      color: white;
      background-color: var(--color-primary);
      border-color: transparent;
    }
  `}
`
export const Title2 = styled.h1`
  width: 100%;
  text-align: left;
  white-space: nowrap;
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  color: var(--color-gray1);
  @media ${device.mobileL} {
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
  }
`