import { FC, useMemo, useState } from "react";
import { YtChannel, YtVideo } from "../../../Interfaces/Talent";
import { GetTalentDetailNotSuspended } from "../../../api/interfaces/Talent";
import { Accordion } from "./Components";
import styled from "styled-components";
import { IoMdCloseCircle } from "react-icons/io";
import { ShareLinksPopup } from "../../../Components/Talent/Detail/PopupContents/ShareLinks";
import { MultiLevelIconsWrapper, getTitleIconByAbvSub } from "../../../Elements/Talent/Badges/Verified/MultiLevelIcons";
import { isACompany } from "../../../Utils/channel";
import { deviceMin, device } from "../../../consts/device";
import HoverPopup from 'reactjs-popup';
import Channel from "../../../Components/Channel/ChannelThumbnail";
import useDevice from "../../../hooks/useDevice";
import useUser from "../../../hooks/useUser";
import { useParams } from "react-router-dom";
import { VideoItemForTimeline } from "./VideoItemForTimeline";



interface Props {
  ytVideoList: YtVideo[];
  mList: string[];
  yearMonth: string; // 2023-03
  talentDetail: GetTalentDetailNotSuspended | undefined;
}
export const TimelineItem: FC<Props> = ({ ytVideoList, mList, yearMonth, talentDetail }) => {

  const { talentInfo } = useUser();
  const { id } = useParams();

  const monthTag = useMemo(() => {
    // yearMonth = yyyy/mm
    const [y, _m] = yearMonth.split('/'); // m is 01...12
    const mIndex = parseInt(_m) - 1;
    return `${mList[mIndex]?.toUpperCase()} ${y}`;
  }, [yearMonth, mList]);

  const YouTubeChannels: YtChannel[] = useMemo(() => {
    const channelIds = Array.from(new Set(ytVideoList.map(v => v.channelId)));
    const channels: YtChannel[] = [];
    for (let index = 0; index < channelIds.length; index++) {
      const ytvideo = ytVideoList.find(ytv => ytv.channelId === channelIds[index]);
      if (ytvideo) {
        channels.push({
          id: ytvideo.channelId,
          name: ytvideo.channelTitle,
          avatar: ytvideo.channelThumbnail,
          abvSubscribers: ytvideo.abvChannelSubscribers
        } as YtChannel)
      }
    }
    return channels;
  }, [ytVideoList]);

  const hasVerifiedVideo = useMemo<Record<string, boolean> | undefined>(() => {
    if (ytVideoList && YouTubeChannels) {
      return YouTubeChannels.reduce((acc, c) => {
        const videoz = ytVideoList.filter(v => String(v.channelId) === String(c.id));
        if (videoz.length) {
          acc[c.id] = videoz.some(v => v.confirmationStatus === 'verified');
        }
        return acc;
      }, {} as Record<string, boolean>)
    } else {
      return undefined;
    }
  }, [ytVideoList, YouTubeChannels]);

  const hasDeniedVideo = useMemo<Record<string, boolean> | undefined>(() => {
    if (ytVideoList && YouTubeChannels) {
      return YouTubeChannels.reduce((acc, c) => {
        const videoz = ytVideoList.filter(v => v.channelThumbnail === c.avatar);
        if (videoz.length) {
          acc[c.id] = videoz.some(v => v.confirmationStatus === 'denied');
        }
        return acc;
      }, {} as Record<string, boolean>)
    } else {
      return undefined;
    }
  }, [ytVideoList, YouTubeChannels]);

  const [askChannelName, setAskChannelName] = useState('');
  const title$icon = useMemo(() => {
    if (askChannelName && talentDetail) {
      const chnl = talentDetail.youtubeVideos?.channels?.find(ch => ch.name === askChannelName);
      if (chnl) {
        return getTitleIconByAbvSub(chnl.abvSubscribers);
      }
    }
  }, [askChannelName, talentDetail]);

  const { isLaptop } = useDevice();

  return (
    <>
      <Accordion tag={monthTag}>
        <Container>

          {/* Channels */}
          <Channels>
            <ChannelsCount>
              <Count>{YouTubeChannels?.filter(c => !hasDeniedVideo?.[c.id])?.length} Client{(YouTubeChannels?.filter(c => !hasDeniedVideo?.[c.id])?.length || 0) > 1 && 's'}</Count>
            </ChannelsCount>
            <ChannelsWrapper>
              {YouTubeChannels && (
                <>
                  {
                    YouTubeChannels?.filter(c => hasVerifiedVideo && hasVerifiedVideo[c.id]).map((channel, i) => (
                      <ChannelListThumbContainer key={i}>
                        {
                          hasVerifiedVideo && hasVerifiedVideo[channel.id]
                            ? <MultiLevelIconsWrapper abvsub={channel.abvSubscribers} linkToDescription={true} size={18} />
                            : <EmptyIcon />
                        }
                        <Channel
                          avatar={channel?.avatar}
                          channelName={channel?.name}
                          isCompany={isACompany(channel)}
                          abvSubscribers={channel?.abvSubscribers || ""}
                          variant="TYPE1"
                          style={{ marginBottom: "0.5rem" }}
                          viewDirection={isLaptop ? "HORIZENTAL" : "VERTICAL"}
                        />
                      </ChannelListThumbContainer>
                    ))
                  }
                  {
                    YouTubeChannels?.filter(c => !hasVerifiedVideo?.[c.id] && !hasDeniedVideo?.[c.id]).map((channel, i) => (
                      <ChannelListThumbContainer key={i}>
                        {
                          hasVerifiedVideo && hasVerifiedVideo[channel.id]
                            ? null
                            : talentInfo?.id === id ? <LinkLike onClick={() => setAskChannelName(channel.name)}>Ask</LinkLike> : <EmptyIcon />
                        }
                        <Channel
                          avatar={channel?.avatar}
                          channelName={channel?.name}
                          isCompany={isACompany(channel)}
                          abvSubscribers={channel?.abvSubscribers || ""}
                          variant="TYPE1"
                          style={{ marginBottom: "0.5rem" }}
                          viewDirection={isLaptop ? "HORIZENTAL" : "VERTICAL"}
                        />
                      </ChannelListThumbContainer>
                    ))
                  }
                  {
                    YouTubeChannels?.filter(c => hasDeniedVideo?.[c.id]).map((channel, i) => (
                      <ChannelListThumbContainer key={i} denied={true}>
                        <DenyIcon />
                        <Channel
                          avatar={channel?.avatar}
                          channelName={channel?.name}
                          isCompany={isACompany(channel)}
                          abvSubscribers={channel?.abvSubscribers || ""}
                          variant="TYPE1"
                          style={{ marginBottom: "0.5rem" }}
                          viewDirection={isLaptop ? "HORIZENTAL" : "VERTICAL"}
                        />
                        <HoverPopup
                          trigger={() => (<DeniedOverlay />)}
                          position={['top center']}
                          arrow={true}
                          on={['hover', 'click']}
                        >
                          <Text>{channel.name} has denied working with this talent</Text>
                        </HoverPopup>
                      </ChannelListThumbContainer>
                    ))
                  }
                </>
              )
              }
              {!!askChannelName && <ShareLinksPopup
                text='Send this link to your client and invite them to confirm your cooperation.'
                link={`https://ytjobs.co/review/${talentDetail?.id}?client_name=${askChannelName.split(' ').join('_')}&utm_campaign=afv&utm_ref=talent`}
                title={`Get your ${title$icon?.title}`}
                type="VERIFY"
                getIcon={() => title$icon?.icon || <div />}
                close={() => setAskChannelName('')}
              />}
            </ChannelsWrapper>
          </Channels>

          {/* Videos */}
          <Videos>

            <ChannelsCount>
              <Count>{ytVideoList?.length} Video{(ytVideoList?.length || 0) > 1 && 's'}</Count>
            </ChannelsCount>

            <Items>
              {!!ytVideoList && ytVideoList.map((v) => (
                <VideoItemForTimeline key={v.id} video={v}/>
              ))}
            </Items>

          </Videos>


        </Container>
      </Accordion>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  background-color: transparent;
  display: flex;
  gap: 24;
  padding: 16px;
  > div:first-child {
    width: 340px;
  }
  > div:last-child {
    flex: 1;
  }
  @media ${device.tabletS} {
   flex-direction: column;
   padding: 16px 0;
  }
`
const ChannelListThumbContainer = styled.div<{ denied?: boolean; }>`
  display: flex;
  align-items: center;
  position: relative;
  > *:first-child {
    margin-right: 5px;
    margin-bottom: 0.5rem;
  }
  ${({ denied }) => denied && `
    > a {
      filter: grayscale(0.9) blur(1.2px);
      pointer-events: none;
    }
  `}
`;
const Text = styled.p`
  font-size: 14px;
  background-color: var(--color-force-gray2);
  color: white;
  border-radius: 6px;
  padding: 16px;
  margin-left: -3rem;
  position: relative;
  max-width: 370px;
  /* transform: translateX(-50px); */
  @media ${device.tabletS} {
    max-width: 310px;
    margin-left: -8rem;
  }
`;
const DeniedOverlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
`
const DenyIcon = styled(IoMdCloseCircle)`
  min-width: 18px;
  min-height: 18px;
  color: var(--color-primary);
`;
const EmptyIcon = styled.div`
  min-width: 16px;
  min-height: 16px;
`;
const Count = styled.h4`
  color: var(--color-gray1);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;
const Channels = styled.div`
`;
const Videos = styled.div`
`;
const ChannelsCount = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  @media ${device.mobileL} {
    margin-bottom: 0.5rem;
    font-size: 14px;
  }
`;
const ChannelsWrapper = styled.div`
  @media ${device.mobileL} {
    display: flex;
    overflow: auto;
  }
`;
const Items = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
export const LinkLike = styled.div`
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  color: var(--color-accent5);
  cursor: pointer;
  @media ${deviceMin.tabletS} {
    font-size: 14px;
  }
`;