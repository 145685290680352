import { FC, HTMLAttributes } from "react";
import styled from "styled-components";

export const NewTag: FC<HTMLAttributes<HTMLDivElement>> = ({...htmlProps}) => {
  return (
    <TagContainer {...htmlProps}>
      <Tag>
        Beta
      </Tag>
    </TagContainer>
  )
}

const TagContainer = styled.div`
  display: flex;
  height: 20px;
  padding: 2px 4px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid var(--color-accent5);
  background: var(--color-bg1);
  user-select: none;
`

const Tag = styled.div`
  color: var(--color-accent5);
  font-size: 12px;
  font-weight: 600;
`