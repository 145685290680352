import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalStorageKeys, UserRoles } from "../../Interfaces";
import { RefferalHandler, UtmCampainHandler, URLReferrerHandler } from "../../Utils/refferalHandler";
import useUser from "../../hooks/useUser";
import { updateLastSources } from "../../api/fetchers";

const TARGET_QUERY_PARAM = 'utm_ref';
const TARGET_QUERY_PARAM1 = 'utm_campaign';

const convertor = (utmCampaing: string) => {
  const talentCampaingList = [
    'shareportfolio',
    'share-profile',
    'share-new-profile',
    'share-business-card',
    'share-review',
    'share-badge',
    'share-reel',
    'afv',
    'afr',
    'share-timeline',
    '2023-recap'
  ]
  if (talentCampaingList.some(s => s === utmCampaing)) {
    return 'talent'
  }
  if (utmCampaing === 'sharejob') {
    return 'company'
  }
  if (utmCampaing === 'google_jobs_apply') {
    return 'Google'
  }
  if (utmCampaing === 'thinkmedia') {
    return 'affiliate'
  }
  return undefined
}

export const ReferringSourceHandler: React.FC = () => {

  const { search } = useLocation()
  const { role } = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    // set utm_ref
    if (search.includes(TARGET_QUERY_PARAM)) {
      const refSource = new URLSearchParams(search).get(TARGET_QUERY_PARAM);
      if (
        !(!!localStorage.getItem(LocalStorageKeys.ChannelToken) || !!localStorage.getItem(LocalStorageKeys.TalentToken))
        && refSource
      ) {
        RefferalHandler.SetRefferer(refSource);
      }

    }

    // set utm_campaign
    if (search.includes(TARGET_QUERY_PARAM1)) {
      const utmCampaign = new URLSearchParams(search).get(TARGET_QUERY_PARAM1);
      if (
        !(!!localStorage.getItem(LocalStorageKeys.ChannelToken) || !!localStorage.getItem(LocalStorageKeys.TalentToken))
        && utmCampaign
      ) {
        UtmCampainHandler.SetUtm(utmCampaign);
        let refSource = new URLSearchParams(search).get(TARGET_QUERY_PARAM) as string | undefined;
        if (!refSource && !RefferalHandler.GetRefferer()) {
          refSource = convertor(utmCampaign)
          if (!!refSource) RefferalHandler.SetRefferer(refSource)
        }
      }
    }

    // set first url_referrer
    if (
      document.referrer &&
      !(localStorage.getItem(LocalStorageKeys.ChannelToken) || localStorage.getItem(LocalStorageKeys.TalentToken))
    ) {
      URLReferrerHandler.SetURLRef(document.referrer)
    }


    // set last campaign or last source 
    if (
      role !== UserRoles.NOT_LOGIN
      && (
        search.includes(TARGET_QUERY_PARAM1)
        || search.includes(TARGET_QUERY_PARAM)
        || document.referrer
      )
    ) {
      let refSource = new URLSearchParams(search).get(TARGET_QUERY_PARAM) || undefined;
      const utmCampaign = new URLSearchParams(search).get(TARGET_QUERY_PARAM1) || undefined;
      if (utmCampaign && !refSource) {
        refSource = convertor(utmCampaign)
      }
      updateLastSources(utmCampaign, refSource, (document.referrer || undefined)).then(() => {
        const p = new URLSearchParams(window.location.search)
        //@ts-ignore
        for (const [key] of p) {
          if (key.startsWith('utm_')) p.delete(key)
        }
        //@ts-ignore
        for (const [key] of p) {
          if (key.startsWith('utm_')) p.delete(key)
        }

        //@ts-ignore
        const searchP = p.size > 0 ? `?${p.toString()}` : ''
        navigate(window.location.pathname + searchP)
      })
    }

    // eslint-disable-next-line
  }, [search])

  return null;
}