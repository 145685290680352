import { MessagePayload } from "firebase/messaging"
import { FC, useEffect, useMemo, useState } from "react"
import { fcmTokenUtils, inAppMessageSubscription } from "../Utils/firebase"
import styled, { css } from "styled-components";
import { useQueryClient } from "react-query";
import { UserTalentQueryLabels } from "../api/interfaces/User/Talent";

/**
 * This hook is for setting recive time that based on we can
 * determine when we want to play exiting animation.
 */

export const MAX_SHOW_TIME = 5000; //  ms

export type AppNotifStatus = 'showing' | 'leaving';

export type AppNotification = {
  payload: MessagePayload;
  reciveTime: number;
  status: AppNotifStatus;
}

export const useInAppNotification = () => {

  const queryClient = useQueryClient();
  const [notificationList, setNotificationList] = useState<AppNotification[]>([]);

  const Unsubscribe = useMemo(() => {
    if (!!fcmTokenUtils.get()) {
      return inAppMessageSubscription((payload) => {
        // console.log({ payload });
        setNotificationList(oldList => [...oldList, {
          payload,
          reciveTime: Date.now(),
          status: 'showing'
        }])
      });
    }
  }, []);

  useEffect(() => {
    if (notificationList.length === 1) {
      queryClient.refetchQueries({
        queryKey: [UserTalentQueryLabels.getCurrentTalent],
      });
    }
  }, [notificationList, queryClient])

  useEffect(() => {
    return () => {
      Unsubscribe?.();
    }
  }, [Unsubscribe]);

  return {
    notificationList,
    setNotificationList
  }
}


export const NotificationItem: FC<{
  updater: React.Dispatch<React.SetStateAction<AppNotification[]>>;
  notification: AppNotification;
}> = ({ updater, notification, children }) => {

  useEffect(() => {
    setTimeout(() => updater(ns => ns.map(n => n.reciveTime !== notification.reciveTime ? n : { ...n, status: 'leaving' })), MAX_SHOW_TIME)
  // eslint-disable-next-line
  }, [])


  return (
    <NotificationItemContainer onAnimationEnd={(e) => {
      if (e?.animationName === 'notif-slide-out') {
        updater((ns) => ns.filter(n => n.reciveTime !== notification.reciveTime))
      }
    }} status={notification.status}>
      {children}
    </NotificationItemContainer>
  )
}


const NotificationItemContainer = styled.div<{ status?: AppNotifStatus }>`
  width: 100%;
  ${({ status }) => status === 'showing' && css`
    animation: 0.3s ease-in notif-slide-in;
  `}
  ${({ status }) => status === 'leaving' && css`
    animation: 0.3s ease-out notif-slide-out;
  `}
`