import { YtVideo } from '../../../../Interfaces/Talent';

export function getSmartThumbnails(
  ytVideos: YtVideo[] | undefined
): YtVideo[] | null {
  const thumbnailLimit = 6;
  if (ytVideos === undefined) return null;
  if (ytVideos.length < thumbnailLimit) return ytVideos;
  const newSmartVideos = ytVideos.filter((video, i) => i < 6);
  return newSmartVideos;
}

