export class WeakStorage {

  private _validFor!: number;
  private _key!: string;

  /**
   * 
   * @param key key for saving in storage
   * @param validFor number of days
   */
  constructor(key: string, validFor?: number) {
    this._key = key;
    this._validFor = 24 * 60 * 60 * 1000 * (validFor || 1000);
  }
  setValue(value: string) {
    localStorage.setItem(this._key, JSON.stringify(
      {
        value,
        date: Date.now(),
      }
    ))
  }
  getValue() {
    const item = JSON.parse(localStorage.getItem(this._key) || `{"value": "", "date": 1}`) as { date: number; value: string };
    if ((Date.now() - item.date) < this._validFor) {
      return item.value;
    }
    return undefined;
  }
}