import LogoPlaceholderWhite from './logoPlaceholderWhite.svg';
import LogoPlaceholderDark from './logoPlaceholderDark.svg';
import { isDarkMode } from '../../Utils';
import { THEME_EVENT_NAME, THEME_STORAGE_KEY } from '../../api/interfaces';


let LogoPlaceholder = isDarkMode() ? LogoPlaceholderDark : LogoPlaceholderWhite;

window.addEventListener('storage', (event: StorageEvent) => {
  if ((event.key === THEME_STORAGE_KEY) && (event.newValue !== event.oldValue)) {
    LogoPlaceholder = isDarkMode() ? LogoPlaceholderDark : LogoPlaceholderWhite;
  }
})
window.addEventListener(THEME_EVENT_NAME, () => {
  LogoPlaceholder = isDarkMode() ? LogoPlaceholderDark : LogoPlaceholderWhite;
})

export { LogoPlaceholder as default}