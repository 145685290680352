import React from 'react';
import styled from 'styled-components/macro';
import { device } from '../../consts/device';
import { Body12 } from '../../Elements/Typo';

interface Props {
  className?: string;
  end: number;
  currentStep: number;
}

function getSmartWidth(currentStep: number, end: number): string {
  const percent = 100 / end;
  const currentPercentage = percent * currentStep;
  return currentPercentage.toString() + '%';
}
const Stepper: React.FC<Props> = ({ className, end, currentStep }) => {
  return (
    <Container className={className}>
      <Wrapper>
        <Lines>
          <BlankRow />
          <FillRow width={getSmartWidth(currentStep, end)} />
        </Lines>
        <Numbers>{`${currentStep}/${end}`}</Numbers>
      </Wrapper>
    </Container>
  );
};
export default Stepper;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  background-color: var(--color-gray6);
  padding: 0 1rem;
  /* z-index: 1; */
  @media ${device.mobileL} {
    height: 3.25rem;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20.5rem;
`;
const BlankRow = styled.div`
  height: 5.5px;
  width: 100%;
  background: var(--color-gray5);
  flex: 1;
  /* border-radius: 400px 0px 0px 400px; */
`;
const FillRow = styled.div<{ width: string }>`
  height: 5.5px;
  width: ${({ width }) => width};
  background: var(--color-primary);
  border-radius: 400px 0px 0px 400px;
  /* margin-top: -4px; */
  transition-duration: 1s;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: inherit;
    right: -6px;
    top: -3.5px;
  }
`;
const Lines = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;
const Numbers = styled(Body12)`
  font-weight: 600;
  line-height: 16px;
  margin-left: 10px;
  margin-bottom: 2px;
  color: var(--color-gray4);
`;
