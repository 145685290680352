import { Form } from "formik";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { device, deviceMin } from "../../../../consts/device";
import Icon from "../../../../Elements/YtIcons";
// import { fadeinAnimation } from "../../../../Styles/Animation";
import { ImageUpload } from "../../../UploadImage";
import { TypoSeeMore } from "../../../../Elements/TypoWithSeeMore";
import { Sticker } from "../../../../Elements/Sticker";

export const Container = styled.section`
  width: 100%;
  // padding: 1rem 0rem;
  margin: auto;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  @media ${deviceMin.tabletS} {
    justify-content: space-between;
  }
`;
export const StyledForm = styled(Form)`
  width: 100%;
`;
export const Row = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
  //desktop
  @media ${deviceMin.tabletS} {
    margin-bottom: 2rem;
    //margin-top: 1rem
  }
`;
export const Avatar = styled(ImageUpload)`
  margin-right: 1.5rem;
`;
export const ChannelContainer = styled.div`
  // display: flex;
  // align-items: center;
`;
export const ChannelName = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--color-gray2);
  width: fit-content;
  margin-bottom: 2px;
  //desktop
  @media ${deviceMin.tabletS} {
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 4px;
  }
`;

export const Caption = styled.h3`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  width: fit-content;
  color: var(--color-gray3);
  @media ${deviceMin.tabletS} {
    font-size: 16px;
    line-height: 26px;
    width: auto;
  }
`;
export const Img = styled.img`
  width: 5.5rem;
  height: 5.5rem;
  box-sizing: border-box;
  border-radius: 50%;
  margin-right: 0.5rem;
  object-fit: cover;
  //desktop
  @media ${deviceMin.tabletS} {
    width: 7.5rem;
    height: 7.5rem;
    margin-right: 1rem;
  }
`;
export const Body = styled.section`
  display: flex;
  gap: 24px;
  margin-bottom: 32px;
  height: auto;
  @media ${device.mobileL} {
    margin-bottom: 24px;
    gap: 16px;
    flex-direction: column;
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1.5rem;
  width: 100%;

  @media ${deviceMin.tabletS} {
    margin-top: 0;
    margin-left: auto;
    width: auto;
  }
`;
export const DataSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  > div.data-container {
    width: 100%;
    max-width: 267px;
    min-width: 267px;
    border-radius: 8px;
    border: 1px solid var(--color-gray5);
    background: var(--color-gray6);
    padding: 8px;
    @media ${device.mobileL} {
      max-width: unset;
      padding: 0;
      background-color: unset;
      border: 0;
    }
  }
`;
export const LinkToShowAll = styled(Link)`
  width: max-content;
  height: max-content;
`;
export const PosterWrapper = styled.div`
  height: max-content;
  position: relative;
  padding-bottom: 8px;
`;

export const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
export const LinkLike = styled.div`
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  color: var(--color-accent5);
  cursor: pointer;
  @media ${deviceMin.tabletS} {
    font-size: 14px;
  }
`;
export const Title = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: var(--color-gray1);
`;
export const EditIcon = styled(Icon)`
  color: var(--color-gray2);
  width: 1.25rem;
  height: auto;
  cursor: pointer;
  &:hover {
    color: var(--color-primary-dark);
  }
`;
export const Desc = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-line;
  color: var(--color-gray1);
  margin-bottom: 1rem;
  @media ${deviceMin.tabletS} {
    margin-bottom: 1rem;
  }
`;
export const TopicsContainer = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  flex-wrap: wrap;
  margin-bottom: 1px;
`;
export const TopicItem = styled.div`
  display: flex;
  padding: 2px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 0.5px solid var(--color-gray25);
  background: transparent;
  margin-right: 6px;
  margin-bottom: 6px;
  color: var(--color-gray2);
  text-align: center;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 400;
  line-height: 20px; /* 157.143% */
  cursor: default;
`;


export const ProfileContentContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: calc(100% - 24px - 267px);
`
export const Desc2 = styled(TypoSeeMore)`
  color: var(--color-gray1);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 1rem;
  max-width: 100%;
  max-height: unset;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  white-space: pre-line;
  > span {
    > div {
      background: linear-gradient(90deg, #00000000 0%, var(--color-gray6) 85%);
    }
    > span {
      background-color: var(--color-gray6);
    }
  }
  @media ${deviceMin.tabletS} {
  }
`;

export const NameStickerAlert = styled(Sticker)`
  position: absolute;
  left: 120px;
  bottom: 4px;
  cursor: pointer;
  background-color: var(--color-primary);
  width: max-content;
  height: max-content;
  > div {
    color: var(--color-white);
    padding: 8px;
  }
  z-index: 3;
  &::after {
    right: 6px;
    bottom: -3px;
  }
  @media ${device.tabletL} {
    left: 110px;
  }
  @media ${device.mobileL} {
    left: 90px;
    bottom: 0px;
  }
`
