import React, { useCallback, useEffect } from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { device, deviceMin } from "../../../consts/device";
import { Button } from "../../../Elements/Button";
import { H2, H4Style } from "../../../Elements/Typo";
import {
  ModalContainer,
  ModalWrapper,
  ModalGrayBlank,
  ModalContent,
  ModalGrayDash,
} from "../../../Styles/ModalStyles";
import { MdOutlineClose } from 'react-icons/md';
import { mdiTabSearch, mdiFormatListText, mdiForumPlusOutline, mdiBriefcaseOutline } from '@mdi/js';
import { MdiIcons } from "../../../Elements/MdiIcons";
import { mdiCrown } from '@mdi/js';

interface Props {
  close?: () => void;
  onAction?: () => void;
  isCloseAble?: boolean;
  title?: string;
  subtitle?: string;
  isModal?: boolean;
}
export const TalentPurchasePopup: React.FC<Props> = ({
  close = () => undefined,
  isCloseAble = false,
  title,
  subtitle,
  isModal = true,
  onAction = () => undefined,
}) => {

  useEffect(() => {
    if (isModal) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "unset";
      };
    }
  }, [isModal]);

  const content = useCallback(() => {
    return (
      <ContentContainer isModal={isModal}>
        <ModalTitle>
          <MdiIcons path={mdiCrown} />
          {title ?? 'Connect with Top YouTube Channels!'}
          {isCloseAble ? <MdOutlineClose onClick={() => close()} /> : <svg />}
        </ModalTitle>
        <Subtitle>
          {subtitle
            ? subtitle
            : `Send personalized proposals directly to channels that are looking for new ideas and collaborations. Your first proposal is on us—use your best shot! `}
          <br /><a href="/about-proposals" target="_blank">Learn more about the Outreach feature.</a>
        </Subtitle>
        <IconsContainer>
          <div>
            <MdiIcons path={mdiTabSearch} />
            Find Leads
          </div>
          <div>
            <MdiIcons path={mdiForumPlusOutline} />
            Send Proposals
          </div>
          <div>
            <MdiIcons path={mdiBriefcaseOutline} />
            Boost Hiring Chances
          </div>
          <div className="disable">
            <MdiIcons path={mdiFormatListText} />
            <div>Lead Pipeline <span>(Soon!)</span></div>
          </div>
        </IconsContainer>
        <ButtonContainer>
          <CTL
            onClick={onAction}
          >
            Outreach | <span>$10 for 10 Proposals</span> Free Now!
          </CTL>
          <p style={{ display: 'none' }}> Upon payment, you agree to the <a href="/terms-and-conditions" target="_blank">Terms</a>  and <a target="_blank" href="/privacy-policy">Privacy Policy</a>.</p>
        </ButtonContainer>
      </ContentContainer>
    )
  }, [title, isCloseAble, close, onAction, isModal, subtitle]);

  return (
    <>
      {isModal && (
        <ModalContainer>
          <ModalWrapper>
            <ModalGrayBlank onClick={() => isCloseAble && close()} />
            <StyledModalContent>
              <ModalGrayDash />
              {content()}
            </StyledModalContent>
          </ModalWrapper>
        </ModalContainer>
      )}
      {!isModal && content()}
    </>
  );
};

const StyledModalContent = styled(ModalContent)`
  max-height: 90%;
  overflow: auto;
  @media ${deviceMin.tabletS} {
    width: 585px;
    max-width: 80%;
    max-height: 80%;
    padding: 24px;
  }
`;
const EaseInAnimation = keyframes`
  0% {
    top: 100%;
  }
  100% {
    top: 32px;
  }
`
const ContentContainer = styled.div<{ isModal: boolean; }>`
  width: 100%;
  max-width: 585px;
  ${({ isModal }) => !isModal && css`
    position: absolute;
    left: 50%;
    top: 100%;
    animation: ${EaseInAnimation} 0.35s ease-in-out 0.2s forwards;
    transform: translate(-50%);
    background: var(--color-bg1);
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 1px 3px 11px 2px rgba(8, 8, 43, 0.16); */
    /* border: 1px solid var(--color-gray6); */
    @media ${device.mobileL} {
      position: fixed;
      z-index: 2;
      left: 0;
      right: 0;
      bottom: 0;
      transform: unset;
      top: unset;
      padding-bottom: 2.6rem;
      animation: unset;
      padding: 16px;
    }
  `}
`
const ModalTitle = styled(H2)`
  font-weight: normal;
  color: var(--color-gray2);
  font-size: 18px;
  width: 100%;
  text-align: center;
  margin-bottom: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  svg:first-child {
    margin-right: 4px;
    width: 24px;
    height: 24px;
    margin-top: -2px;
    > path {
      fill: var(--color-accent1) !important;
    }
  }
  svg:last-child {
    font-weight: 600;
    color: var(--color-gray1);
    position: absolute;
    right: 0;
    min-width: 22px;
    max-width: 22px;
    cursor: pointer;
  }
  //desktop
  @media ${deviceMin.tabletS} {
    ${H4Style};
    line-height: 32px;
    font-weight: 600;
    font-size: 22px;
    color: var(--color-gray2);
    margin-bottom: 5px;
    svg:first-child {
      margin-right: 8px;
      width: 32px;
      height: 32px;
      margin-top: -6px;
    }
  }
`;
const Subtitle = styled.p`
  color: var(--color-gray1);
  text-align: left;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 18px;
  > a {
    cursor: pointer;
    color: var(--color-accent5);
    padding-left: 4px;
    text-decoration: underline;
  }
  @media ${deviceMin.tabletS} {
    text-align: center;
    padding: 0 16px;
    margin-bottom: 24px;
  }
`
const IconsContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1px;
    font-size: 11px;
    line-height: 19px;
    color: var(--color-gray2);
  }
  .disable {
    color: var(--color-gray4);
    > div {
      display: flex;
      align-items: center;
      gap: 4px;
      > span {
        color: #21D4FD;
      }
    }
    > svg > path {
      fill: var(--color-gray4);
    }
  }
  @media ${deviceMin.tabletS} {
    padding: 0 16px;
    margin-bottom: 24px;
    > div {
      color: var(--color-gray2);
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      > svg {
        margin-bottom: 8px;
      }
    }
  }
`
const CTL = styled(Button)`
    /* width: 345px; */
    margin-bottom: 6px;
    align-items: center;
    gap: 4px;
    > span {
      opacity: 0.7;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-decoration: line-through;
      /* text-decoration-line: line-through; */
    }
    @media ${device.mobileL} {
      width: 100%;
      margin-bottom: 12px;
    }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > p {
    color: var(--color-gray3);
    font-size: 11px;
    line-height: 19px;
    text-align: center;
    > a {
      text-decoration-line: underline;
    }
  }
  @media ${device.mobileL} {
  }
`;