import styled from "styled-components";
import { device } from "../../../consts/device";
import Skeleton from "../../../Elements/Skeleton";


export const TimeLineSkelleton = () => {
  return (
    <>
      {[1, 2, 3].map((i) => (
        <div key={i}>
          <Title isLoading={true} />
          <Container>

            <div>
              <Skeleton isLoading={true} style={{ width: 90, height: 28, marginBottom: 16 }} />
              <ColRow>
                <Thumbnail>
                  <Skeleton isLoading={true} style={{ width: 48, height: 48 }} />
                  <div>
                    <Skeleton isLoading={true} style={{ width: 126, height: 20, marginBottom: 4 }} />
                    <Skeleton isLoading={true} style={{ width: 95, height: 20 }} />
                  </div>
                </Thumbnail>
                <Thumbnail>
                  <Skeleton isLoading={true} style={{ width: 48, height: 48 }} />
                  <div>
                    <Skeleton isLoading={true} style={{ width: 126, height: 20, marginBottom: 4 }} />
                    <Skeleton isLoading={true} style={{ width: 95, height: 20 }} />
                  </div>
                </Thumbnail>
              </ColRow>
            </div>


            <div>
              <Skeleton isLoading={true} style={{ width: 94, height: 28, marginBottom: 16 }} />
              <Video>
                <Skeleton isLoading={true} />
                <div>
                  <Skeleton isLoading={true} style={{ width: 126, height: 24, marginBottom: 8, marginTop: 8 }} />
                  <Skeleton isLoading={true} style={{ width: 167, height: 24, marginBottom: 8 }} />
                  <Skeleton isLoading={true} style={{ width: 239, height: 24, marginBottom: 8 }} />
                  <Skeleton isLoading={true} style={{ width: 126, height: 24, marginBottom: 8 }} />
                </div>
              </Video>
              <Video>
                <Skeleton isLoading={true} />
                <div>
                  <Skeleton isLoading={true} style={{ width: 126, height: 24, marginBottom: 8, marginTop: 8 }} />
                  <Skeleton isLoading={true} style={{ width: 167, height: 24, marginBottom: 8 }} />
                  <Skeleton isLoading={true} style={{ width: 239, height: 24, marginBottom: 8 }} />
                  <Skeleton isLoading={true} style={{ width: 126, height: 24, marginBottom: 8 }} />
                </div>
              </Video>
            </div>

          </Container>
        </div>
      ))}
    </>
  )
}

const Title = styled(Skeleton)`
  width: 118px;
  height: 38px;
  border-radius: 0 16px 16px 0;
  @media ${device.tabletL} {
    margin-bottom: 16px;
  }
`;
const Container = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  gap: 24px;
  > div:first-child {
    min-width: 340px;
  }
  > div:last-child {
    flex: 1;
  }
  @media ${device.tabletL} {
    flex-direction: column;
    gap: 16px;
    padding: 0;
  }
`
const ColRow = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.tabletL} {
    flex-direction: row;
  }
`
const Thumbnail = styled.div`
  display: flex;
  margin-bottom: 16px;
  > div:first-child {
    border-radius: 50%;
    margin-right: 8px;
  }
  @media ${device.tabletL} {
    margin-right: 8px;
    > div:last-child {
      display: none;
    }
  }
`
const Video = styled.div`
  display: flex;
  margin-bottom: 16px;
  > div:first-child {
    margin-right: 16px;
    width: 267px;
    aspect-ratio: 1.91/1;
    border-radius: 6px;
    height: unset;
  }
  @media ${device.tabletL} {
    flex-direction: column;
    > div:first-child {
      width: 100%;
      margin-right: 0px;
      aspect-ratio: 1.91/1;
    }
  }
`