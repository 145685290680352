import { useContext } from 'react';
import { PublicModalContext } from '../../Contexts/PublicModalContenxt';
import { PublicPopupVariant } from '../../Interfaces';
import LoginPopup from './Modals/LoginPopup';
import { Message2Talent } from '../Channel/Message2Talent';
import { MessageChannel } from '../../Components/Talent/MessageChannel';
import { ProposalFormModal } from '../../Pages/ChannelList/proposal-form';

const PublicModals = () => {
  const { activeModalOpen } = useContext(PublicModalContext);

  switch (activeModalOpen) {
    case PublicPopupVariant.LOGIN_CHANNEL:
      return <LoginPopup role={'CHANNEL'} />;
    case PublicPopupVariant.LOGIN_TALENT:
      return <LoginPopup role={'TALENT'} />;
    case PublicPopupVariant.MESSAGE_TO_TALLENT:
      return <Message2Talent />;
    case PublicPopupVariant.MESSAGE_TO_CHANNEL:
      return <MessageChannel />;
    case PublicPopupVariant.MESSAGE_TO_CHANNEL_V2:
      return <ProposalFormModal />;
    case PublicPopupVariant.NONE:
      return null;
    default:
      return null;
  }
};
export default PublicModals;
