import { useLocation } from "react-router-dom";
import { UserRoles } from "../../../Interfaces";
import useUser from "../../../hooks/useUser"
import { useState } from "react";
import useDevice from "../../../hooks/useDevice";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import ErrorToast from "../../../Elements/Toast/Error";
import { sendSupportReq } from "../../../api/fetchers/Channel";
import { useChannelOwnJobs } from "../../Menu/Utils/useChannelOwnJobs";
import { JobStatus } from "../../../Interfaces/Job";

export enum SupportTypes {
  STARTER = 'STARTER',
  NEED_INVOCE = 'invoice',
  REPORT_BUG = 'bug',
  FEEDBACK = 'feedback',
  OTHER = 'other',
  DONE = 'DONE',
}

export const useSupportUtils = () => {

  const { role, channelInfo } = useUser();
  const { pathname } = useLocation();
  const { isMobile } = useDevice();

  const { jobsQuery } = useChannelOwnJobs(!!channelInfo)

  const hoverLike = useState(false);
  const openMain = useState(false);
  const StepsState = useState<SupportTypes>(SupportTypes.STARTER);
  const Body = useState('');

  const onSubmit = useMutation(
    () => {
      return sendSupportReq(Body[0], StepsState[0]);
    },
    {
      onSuccess: (res: { success: boolean; message: string; }) => {
        if (!res?.success) {
          ErrorToast(res?.message || 'Something went wrong!');
          return undefined;
        }
        StepsState[1](SupportTypes.DONE)
      },
      onError: (err: AxiosError<{ message: string; }>) => {
        ErrorToast(err.response?.data.message || 'Something went wrong!');
      }
    }
  )


  return {
    hoverLikeState: hoverLike,
    openMain,
    StepsState,
    isMobile,
    Body,
    onSubmit,
    isValidToShow: (
      role === UserRoles.CHANNEL
      && channelInfo
      && jobsQuery.data
      && (
        !!jobsQuery.data.some(job => job.status !== JobStatus.DRAFT)
        || channelInfo.canSearchAdvanced
      )
      && !pathname.startsWith('/job/create')
      && !pathname.endsWith('/checkout')
      && !pathname.endsWith('/pre-publish')
      && !pathname.startsWith('/payment')
      && !pathname.startsWith('/channel/verified-channels-description')
    )
  }
}