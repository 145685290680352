import { HTMLAttributes, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import LogoPlaceholder from '../../Assests/Images/logoPlaceholder';
import { MdUpload } from 'react-icons/md';
import { deviceMin } from '../../consts/device';

interface Props extends HTMLAttributes<HTMLImageElement> {
  onSuccess: (file: File) => void;
  selectedFile: File | null | undefined;
  image?: string;
}
export const ImageUpload: React.FC<Props> = ({
  id,
  selectedFile,
  onSuccess,
  image,
  ...props
}) => {
  const [preview, setPreview] = useState<string>();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <div>
      <ImageContainer {...props}>
        <Img
          src={preview || image || LogoPlaceholder}
          alt={`logo`}
          loading='lazy'
        />
        <PlaceHolderContainer htmlFor='file-upload'></PlaceHolderContainer>
        <UploadIcon />
      </ImageContainer>
      <input
        style={{ display: 'none' }}
        type='file'
        accept='image/*'
        onChange={(e) => {
          if (e?.target?.files && e?.target?.files.length > 0) {
            onSuccess(e?.target?.files[0]);
          }
        }}
        data-testid='file-upload'
        id={id}
      />
    </div>
  );
};
const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  @media ${deviceMin.tabletS} {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;
const PlaceHolderContainer = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--color-gray4);
  opacity: 0.4;
  cursor: pointer;
`;
const UploadIcon = styled(MdUpload)`
  position: absolute;
  fill: var(--color-bg2);
  width: 1.5rem;
  height: 2rem;
  pointer-events: none;
`;
