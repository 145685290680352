import { HTMLAttributes } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Body16Style } from '../../../Elements/Typo';
import {
  hoverLineState1_inBefore,
  hoverLineState2_inBefore,
} from '../../../Styles/Animation';

interface Props extends HTMLAttributes<HTMLDivElement> {
  label: string | React.ReactElement;
  destination: string;
  className?: string;
  isActive?: boolean;
}

const SecondaryItem: React.FC<Props> = ({
  label,
  destination,
  className,
  isActive,
}) => {
  const location = useLocation();

  return (
    <Container className={className}>
      <Item
        to={destination}
        isActive={
          isActive !== undefined ? isActive : location.pathname.startsWith(destination)
        }
      >
        {label}
      </Item>
    </Container>
  );
};
export default SecondaryItem;
const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 1rem;
  transition: border 0.1s linear;
  cursor: pointer;
`;
const Item = styled(Link)<{ isActive: boolean }>`
  ${Body16Style};
  margin-top: 4px;
  color: var(--color-gray2);
  padding: 0 0.5rem;
  ///////active with url//////////
  ${({ isActive }) => isActive && 'color: var(--color-primary);'}
  position: relative;
  //
  &:before {
    ${hoverLineState1_inBefore}
  }
  &:hover {
    &:before {
      ${hoverLineState2_inBefore}
    }
  }
`;
