import { PaginateQueryResponse } from '.';
import { JobDetailForOwner, JobListForOther } from '../../Interfaces/Job';

export interface CreateJobResponse {
  success: string;
  paymentRequired: boolean;
  job: JobDetailForOwner;
}
export interface EditJobResponse {
  success: string;
  paymentRequired: boolean;
  job: JobDetailForOwner;
}
export interface AddStripeProductsToJobs_Response {
  success: string;
  paymentRequired: boolean;
  job: JobDetailForOwner;
  paymentUrl: string | null;
}

export type GetJobListResponse = PaginateQueryResponse<JobListForOther>;

export enum JobQueryLabels {
  getInfiniteJobList = "getInfiniteJobList",
  getJobDetail = "getJobDetail",
  getJobMAtts = "getJobMAtts",
  getJobDetailByOwner = "getJobDetailByOwner",
  getRecentedJobs = "getRecentedJobs",
  getFeaturedJobs = "getFeaturedJobs",
  getJobProductsinStripe = "getJobProductsinStripe",
  getVerificationTalent = "getVerificationTalent",
  getLanguages = "getLanguages",
}
