import { JobFormQuestions } from "./Job";

/* eslint-disable no-unused-vars */
export enum Theme {
  DARK = "DARK",
  LIGHT = "LIGHT",
}
export enum UserRoles {
  CHANNEL = "channel",
  TALENT = "talent",
  NOT_LOGIN = "not_login",
}
export enum PublicPopupVariant {
  LOGIN_CHANNEL = "LOGIN_CHANNEL",
  LOGIN_TALENT = "LOGIN_TALENT",
  SHARE_IN_SOCIAL = "SHARE_IN_SOCIAL",
  MESSAGE_TO_TALLENT = 'MESSAGE_TO_TALLENT',
  MESSAGE_TO_CHANNEL = 'MESSAGE_TO_CHANNEL',
  MESSAGE_TO_CHANNEL_V2 = 'MESSAGE_TO_CHANNEL_V2',
  NONE = "NONE",
}
export enum LocalStorageKeys {
  TalentToken = "UserToken",
  ChannelToken = "ChannelToken",

  //for fix lazyload bug
  "page-has-been-force-refreshed" = "page-has-been-force-refreshed",
}
export enum SessionStorageKeys {
  VouchersPopupArrowPressed = "VouchersPopupArrowPressed",
}
export type PostPayloads = JobFormQuestions | { email: string }; // apply job

//use in both of Job and Talent
export enum ExpertiseID {
  VideoEditing = "1",
  CreativeDirector = "2",
  ThumbnailDesigner = "3",
  ChannelManager = "4",
  YouTubeStrategist = "6",
  ScriptWriter = "8",
  youTubeProducer = "9",
  other = "11",
}
export enum YtjobsIcons {
  error = "error",
  discoverHire = "discoverHire",
  videoEditor = "videoEditor",
  jobOtherType = "jobOtherType",
  creativeDirector = "creativeDirector",
  thumbnailDesigner = "thumbnailDesigner",
  channelManager = "channelManager",
  youTubeStrategist = "youTubeStrategist",
  scriptWriter = "scriptWriter",
  youTubeProducer = "youTubeProducer",
  externalLink = "externalLink",
  userCircle = "userCircle",
  menuIcon = "menuIcon",
  loading = "loading",
  arrowRight = "arrowRight",
  close = "close",
  salary = "salary",
  time = "time",
  location = "location",
  edit = "edit",
  link = "link",
  delete = "delete",
  sendMessage = "sendMessage",
  checkMark = "checkmark",
  checkMarkFill = "checkmarkFill",
  reject = "reject",
  rejectFill = "rejectFill",
  twitter = "twitter",
  hide = "hide",
  show = "show",
  setting = "setting",
  share = "share",
  verified = "verified",
  flag = "flag",
}
//shared data between talent and company
export interface SharedProfileData {
  id?: string;
  name?: string;
  email?: string;
  slug?: string;
  avatar?: string | File | null;
  unreadMessageCount?: number;
}


// Auth with google 
export enum GoogleAuthModes {
  CompanyRegis = 'company-register',
  CompanyLogin = 'company-register',
  CompanyVerifyYT = 'verify-channel-company',
  UserRegis = 'user-register',
  UserLogin = 'user-login',
  UserVerifyYT = 'verify-channel-user',
  UserVerifyEmail = 'verify-user-email'
}
// Auth with google 
export enum AppleAuthModes {
  UserRegis = 'user-register-apple',
  UserLogin = 'user-login-apple',
}
export interface IGoogleAuthBody {
  mode: GoogleAuthModes;
  channelUrl?: string;
  functionalArea?: ExpertiseID;
  referringSource?: string;
  utmCampaign?: string;
  firstReferer?: string;
} 
export interface IGoogleAuthRes {
  success: boolean;
  message: string;
  authUrl: string;
} 
export interface IAppleAuthRes {
  success: boolean;
  message: string;
  authUrl: string;
} 