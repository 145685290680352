import { FC, useMemo } from "react";
import { IReviews, TalentInfo } from "../../../../../Interfaces/Talent";
import styled from "styled-components";
import ChannelThumbnail from "../../../../Channel/ChannelThumbnail";
import { isACompany } from "../../../../../Utils/channel";



interface ReviewListItemProps {
  review: IReviews;
  talentInfo: TalentInfo | undefined;
  isOwner: boolean;
}
export const ReviewListItem: FC<ReviewListItemProps> = ({ review }) => {

  const channel = useMemo(() => review.reviewer, [review])

  return (
    <>
      <Row>
        <StyledChannelThumbnail
          variant='TYPE1'
          channelName={channel?.name}
          isCompany={isACompany(channel)}
          abvSubscribers={channel?.abvSubscribers}
          avatar={channel?.avatar}
          viewer='OTHERS'
          isActive={true}
          isVerified={channel?.isVerified}
          location='OTHER_PROFILE_PAGE'
          channelId={channel?.id}
          isClickable={true}
        />
        <div />
      </Row>
      <Quote>“{review.body}“</Quote>
    </>
  )
}
const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const Quote = styled.div`
  color: var(--color-gray2);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
  padding-left: 56px;
  margin-top: -16px;
`
const StyledChannelThumbnail = styled(ChannelThumbnail)`
  width: max-content;
  img {
    width: 48px;
    height: 48px;
  }
`