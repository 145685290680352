import { useCallback, useContext } from "react";
import { PublicModalContext } from "../../../Contexts/PublicModalContenxt";
import { PublicPopupVariant } from "../../../Interfaces";
import { ChannelInfo } from "../../../Interfaces/Channel";



export const useMessageChannel = () => {
  const { setActiveModalOpen, activeModalOpen, targetChannel, setTargetChannel } = useContext(PublicModalContext);

  const close = useCallback(() => {
    setActiveModalOpen(PublicPopupVariant.NONE);
    setTargetChannel(undefined);
  }, [setActiveModalOpen, setTargetChannel]);

  const open = useCallback((chInfo: ChannelInfo | undefined) => {
    setTargetChannel(chInfo);
    setActiveModalOpen(PublicPopupVariant.MESSAGE_TO_CHANNEL_V2);
  }, [setActiveModalOpen, setTargetChannel]);

  const toggle = useCallback((chInfo: ChannelInfo | undefined) => {
    if (activeModalOpen === PublicPopupVariant.MESSAGE_TO_CHANNEL_V2) {
      close();
    } else {
      open(chInfo);
    }
  }, [activeModalOpen, close, open]);

  return {
    open,
    close,
    toggle,
    targetChannel
  }
}