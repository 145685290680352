import {
  getJobsByOwner,
} from "../../../api/fetchers/User/Channel";
import { useQuery } from 'react-query';
import { GetJobListByOwnerResponse, UserChannelQueryLabels } from '../../../api/interfaces/User/Channel';
import { useEffect, useMemo } from "react";
import { JobStatus } from "../../../Interfaces/Job";
import { WeakStorage } from "../../../Utils/SaveFor";
import { GoogleAnalytic } from "../../../Utils/ga";

const _3DAYS = 3 * 24 * 3600 * 1000; // in nano seconds

export const useChannelOwnJobs = (isChannel: boolean) => {

  const jobsQuery = useQuery<GetJobListByOwnerResponse, Error>(
    [UserChannelQueryLabels.getJobsByOwner],
    () => getJobsByOwner(),
    {
      staleTime: 600000,
      enabled: isChannel,
    }
  )

  const cartItem = useMemo(() => {
    if (!jobsQuery.data || jobsQuery.data.some(job => (job.status !== JobStatus.DRAFT))) return undefined;
    return jobsQuery.data.filter(job => ((+new Date() - +new Date(job.publishedAt as string)) < _3DAYS))[0]
  }, [jobsQuery.data])

  useEffect(() => {
    if (cartItem) {
      const ws = new WeakStorage('crticn-sh')
      if (!ws.getValue()) {
        ws.setValue('shwn')
        GoogleAnalytic.CartIconShown()
      }
    }
  }, [cartItem])


  return {
    jobsQuery,
    cartItem
  };
};
