import { useQuery } from "react-query"
import { UserRoles } from "../../Interfaces"
import { MachingAPI, getUserTestimony } from "../../api/fetchers"
import useUser from "../../hooks/useUser"

export const USER_GET_OWN_TESTIMONY = 'USER_GET_OWN_TESTIMONY';
export const USER_GET_MATCHINGS = 'USER_GET_MATCHINGS';

export const useData = () => {

  const { role } = useUser();

  const { data: matchingList } = useQuery(
    [USER_GET_MATCHINGS],
    () => MachingAPI.getList(),
    { enabled: role !== UserRoles.NOT_LOGIN }
  )

  const { data: testimonyData } = useQuery(
    [USER_GET_OWN_TESTIMONY],
    () => getUserTestimony(),
    { enabled: role !== UserRoles.NOT_LOGIN && (!!matchingList && !!matchingList.length) }
  )

  return {
    matchingList,
    testimonyData
  };
}