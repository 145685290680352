import { ytJobsApi } from "../../api/clients";
import { isItOnLive } from "../../api/utils";
import { ChatUploadFileResponse, ListCategories, MailListResponse, MailThreadItem, SendMessageRequest, SendMessageResponse } from "./Interfaces";


type getMailBoxThreadsArgs = {
  page?: number;
  category?: ListCategories
}
export const getMailBoxThreads = async ({
  page = 1,
  category = ListCategories.Inbox
}: getMailBoxThreadsArgs): Promise<MailListResponse> => {
  const dir = category === ListCategories.Archive ? 'archived' : category === ListCategories.Sent ? 'sent' : 'inbox'
  const { data } = await ytJobsApi.get(`/chat/messages`, {
    params: {
      limit: 25,
      page,
      dir
    }
  });
  return data;
};
export const getMailBoxThreadDetail = async (id: number | string) : Promise<{
  data: MailThreadItem[];
}> => {
  const { data } = await ytJobsApi.get(`/chat/messages/${id}`);
  return data;
};

export const setArchivedUnArchived = async (idList: number[], status: 0 | 1) : Promise<{
  success: boolean;
  message: string;
}> => {

  const formData = new FormData();
  formData.append('status', status.toString());
  idList.forEach((id, i) => {
    formData.append(`messageIds[${i}]`, id.toString());
  })
  const { data } = await ytJobsApi.post(`/chat/messages/set-archived`, formData, {
    headers: {
      Accept: "application/json",
    },
  });
  return data;
};






// upload
export const MBUploadImage = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const url = isItOnLive() ? `https://chat.ytjobs.co/api/attachments/upload` : `https://chatdev.ytjobs.co/api/attachments/upload`
  const { data } = await ytJobsApi.post<ChatUploadFileResponse>(url, formData);
  return data;
};


export const MBSendMessage = async (params: SendMessageRequest) => {

  const formData = new FormData();

  formData.append("receiverType", params.receiverType);
  formData.append("receiverId", params.receiverId);
  formData.append("message", params.message);
  formData.append("replyTo", params.replyTo.toString());
  formData.append("subject", params.subject);
  params.attachments.forEach((at, i) => {
    formData.append(`attachments[${i}]`, at);
  });

  const url = `/chat/messages`
  const { data } = await ytJobsApi.post<SendMessageResponse>(url, formData);
  return data;
};