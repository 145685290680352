import { Form } from "formik";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { deviceMin } from "../../../../consts/device";
import Icon from "../../../../Elements/YtIcons";
// import { fadeinAnimation } from "../../../../Styles/Animation";
import { ImageUpload } from "../../../UploadImage";

export const Container = styled.section`
  width: 100%;
  // padding: 1rem 0rem;
  margin: auto;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  @media ${deviceMin.tabletS} {
    justify-content: space-between;
  }
`;
export const StyledForm = styled(Form)`
  width: 100%;
`;
export const Row = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
  //desktop
  @media ${deviceMin.tabletS} {
    margin-bottom: 2rem;
    //margin-top: 1rem
  }
`;
export const Avatar = styled(ImageUpload)`
  margin-right: 1.5rem;
`;
export const ChannelContainer = styled.div`
  // display: flex;
  // align-items: center;
`;
export const ChannelName = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--color-gray2);
  width: fit-content;
  margin-bottom: 2px;
  //desktop
  @media ${deviceMin.tabletS} {
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 4px;
  }
`;

export const Caption = styled.h3`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  width: fit-content;
  color: var(--color-gray3);
  @media ${deviceMin.tabletS} {
    font-size: 16px;
    line-height: 26px;
    width: auto;
  }
`;
export const Img = styled.img`
  width: 5.5rem;
  height: 5.5rem;
  box-sizing: border-box;
  border-radius: 50%;
  margin-right: 0.5rem;
  object-fit: cover;
  //desktop
  @media ${deviceMin.tabletS} {
    width: 7.5rem;
    height: 7.5rem;
    margin-right: 1rem;
  }
`;
export const Body = styled.section`
  display: flex;
`;
// export const Desc = styled.p<{ isEclipseText: boolean; moreOrLess: 'more' | 'less' }>`
//   ${Body16Style}
//   line-height: 28px;
//   color: var(--color-gray2);
//   margin-bottom: 0.25rem;
//   ${({ isEclipseText, moreOrLess }) =>
//     isEclipseText &&
//     moreOrLess === 'more' &&
//     `
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 3;
//   -webkit-box-orient: vertical;
//   `}

//   @media ${deviceMin.tabletS} {
//     ${Body18Style}
//     line-height: 31px;
//   }
// `;
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1.5rem;
  width: 100%;

  @media ${deviceMin.tabletS} {
    margin-top: 0;
    margin-left: auto;
    width: auto;
  }
`;
export const DataSection = styled.div`
  width: 100%;
  margin-top: 0;
  @media ${deviceMin.tabletS} {
    margin-right: 2rem;
    margin-top: 24px;
  }
`;
export const LinkToShowAll = styled(Link)`
  width: max-content;
  height: max-content;
`;
export const PosterWrapper = styled.div`
  height: max-content;
  position: relative;
  padding-bottom: 8px;
`;

export const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
export const LinkLike = styled.div`
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  color: var(--color-accent5);
  cursor: pointer;
  @media ${deviceMin.tabletS} {
    font-size: 14px;
  }
`;
export const Title = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--color-gray1);
  @media ${deviceMin.tabletS} {
    font-size: 22px;
    line-height: 33px;
  }
`;
export const EditIcon = styled(Icon)`
  color: var(--color-gray2);
  width: 1.25rem;
  height: auto;
  cursor: pointer;
  &:hover {
    color: var(--color-primary-dark);
  }
`;
export const Desc = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  white-space: pre-line;
  color: var(--color-gray2);
  margin-bottom: 1rem;
  @media ${deviceMin.tabletS} {
    margin-bottom: 1.5rem;
  }
`;
export const TopicsContainer = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;
export const TopicItem = styled.div`
  padding: 0 0.75rem;
  min-height: 34px;
  border-radius: 8px;
  background-color: var(--color-gray6);
  border: 1px solid var(--color-gray3);
  color: var(--color-gray3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
`;