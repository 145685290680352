import styled from 'styled-components/macro';
import React, { useEffect } from 'react';
import {
  ModalContainer,
  ModalWrapper,
  ModalGrayBlank,
  ModalContent,
  ModalGrayDash,
} from '../../../../Styles/ModalStyles';
import { device, deviceMin } from '../../../../consts/device';
import { IoCloseSharp, IoCopy } from 'react-icons/io5';
import { copyToClipBoard } from '../../../../Utils';
import { GoogleAnalytic } from '../../../../Utils/ga';

interface Props {
  close: () => void;
  text: string;
  title: string;
  link: string;
  type: 'VERIFY' | 'REVIEW';
  getIcon: () => React.ReactElement;
}
export const ShareLinksPopup: React.FC<Props> = ({ close, text, link,  getIcon, title, type }) => {

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  useEffect(() => {
    if (type) {
      GoogleAnalytic.AskReviewCountFireEvent(type === 'REVIEW' ? 'ask-to-review' : 'ask-to-verify')
    }
  }, [type]);

  return (
    <ModalContainer style={{zIndex: 9999999}}>
      <ModalWrapper>
        <ModalGrayBlank
          onClick={close}
        />

        <StyledModalContent>
          <ContentWrapper>
            <ModalGrayDash />
            <Row>
                {getIcon()}
                <Title>{title}</Title>
                <CloseIcon onClick={close} />
            </Row>

            <TextBody>{text}</TextBody>

            <Row2>
                <LinkText>{link}</LinkText>
                <CopyContainer onClick={() => {
                  copyToClipBoard(link);
                  GoogleAnalytic.AskLinkCopiedFireEvent(type === 'REVIEW' ? 'ask-to-review' : 'ask-to-verify')
                }}><IconCopy /></CopyContainer>
            </Row2>

          </ContentWrapper>
        </StyledModalContent>
      </ModalWrapper>
    </ModalContainer>
  );
};

const StyledModalContent = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 0px 16px 72px 16px;
  @media ${deviceMin.tabletL} {
    width: 636px;
    max-width: 80%;
    padding: 24px;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: fit-content;
  width: 100%;
`;
const Row = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Title = styled.h1`
    font-weight: 600;
    font-size: 22px;
    flex: 1;
    text-align: left;
    padding-left: 10px;
    color: var(--color-gray1);
    @media ${device.mobileL} {
      font-size: 16px;
    }
`;
const CloseIcon = styled(IoCloseSharp)`
    font-size: 24px;
    cursor: pointer;
    color: var(--color-gray2);
`;
const TextBody = styled.p`
    font-size: 16px 0 8px 0;
    margin: 16px 0;
    color: var(--color-gray2);
`;
const Row2 = styled(Row)`
    padding: 8px 0;
    border-bottom: 1px solid var(--color-gray5);
`;
const LinkText = styled(TextBody)`
    color: var(--color-primary);
    flex: 1;
    text-align: left;
    margin: 0;
    @media ${device.mobileL} {
      font-size: 14px;
      max-width: calc(100% - 40px);
      word-wrap: break-word;
    }
`;
const CopyContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid var(--color-primary);
    cursor: pointer;
    @media ${device.mobileL} {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      margin-left: 8px;
    }
`;
const IconCopy = styled(IoCopy)`
    color: var(--color-primary);
    @media ${device.mobileL} {
      width: 18px;
      height: 18px;
    }
`
