import { ExpertiseID } from "../../../Interfaces";
import {
  TalentInfo,
  TalentPortfolio,
  TalentWork,
  HireMeUpdateSetting,
  VouchedByChannel,
  Talent,
} from "../../../Interfaces/Talent";

export interface TalentRegisterSuccess {
  success: boolean;
  userToken: string;
}
export interface TalentProfileSuccess {
  success: boolean;
}

export interface ExpressTalentLoginResponse {
  userToken: string;
  user: TalentInfo;
}
export interface TalentSignupReq {
  functionalArea: ExpertiseID;
  email: string;
  referrer?: string;
  referringSource?: string;
  utmCampaign?: string;
  firstReferer?: string;
}

export type UpdateTalentProfileReq = {
  name: string;
  about: string | null;
  avatar: string | File;
  customTitle: string;
  works: TalentWork[];
  portfolio: TalentPortfolio;
  languages?: number[];
};
/////
export type SwapPosterImageReq = {
  originVideoId: string;
  targetVideoId: string;
  talentId: string;
};
export type SubmitYtVideoReq = {
  url: string;
  description?: string;
  talentId: string;
};
export type PatchYtVideoReq = {
  url: string;
  description?: string;
  videoId: string;
  talentId: string;
};
export type SubmitYtVideoResponse = {
  id: string;
  publishedAt: string;
  channelId: string;
  title: string;
  thumbnail: string;
  channelTitle: string;
  channelThumbnail: string;
  views: string;
  likes: string;
  comments: string;
  abvViews: string;
  abvLikes: string;
  abvComments: string;
  abvChannelSubscribers: string;
};
export enum UserTalentQueryLabels {
  getCurrentTalent = "getCurrentTalent",
  getHireMeSetting = "getHireMeSetting",
  getTalentSettings = "getTalentSettings",
  getReachoutList = "getReachoutList",
}

export enum UserAttributeQueryLabels {
  getProfileCategories = 'getProfileCategories',
  getProfileStyles = 'getProfileStyles',
  getProfileSkills = 'getProfileSkills',
}
///////ٰVouch///////////

export type Voucher = {
  id: string;
  vouchedBy: VouchedByChannel | Talent;
  vouchedByType: "Company" | "User";
  recommendationText: string;
  isHidden: boolean;
};
export type UpdateSettingHireMeReq = HireMeUpdateSetting;
