import { useCallback, useEffect, useMemo, useState } from "react";
import { useData } from "./useData"
import { WeakStorage } from "../../Utils/SaveFor";
import { useLocation } from "react-router-dom";



const STORE_KEY = 'K2STR_SMRTBNR';

export const useTestimonySmartBanner = () => {

  const { pathname } = useLocation();
  const [showTestimonySBanner, setShowSmartBanner] = useState(false);
  const { matchingList, testimonyData } = useData();
  const weekStorage = useMemo(() => new WeakStorage(STORE_KEY, 15), []);

  const close = useCallback(() => {
    weekStorage.setValue('1');
    setShowSmartBanner(false);
  }, [setShowSmartBanner, weekStorage]);

  useEffect(() => {
    if (
      !!pathname &&
      !pathname.startsWith('/recent-placements') &&
      !pathname.startsWith('/review') &&
      !pathname.endsWith('/checkout') &&
      !pathname.startsWith('/job/create') &&
      !pathname.endsWith('/applicants') &&
      !pathname.startsWith('/talent/vitrine/') &&
      !pathname.startsWith('/talent/timeline/') &&
      !pathname.startsWith('/talent/profile-wizard') &&
      !pathname.startsWith('/videos') &&
      !!matchingList?.length &&
      !testimonyData?.testimony &&
      !weekStorage.getValue()
    ) {
      if (!!matchingList && !!testimonyData) {
        setShowSmartBanner(true);
      }
    } else {
      setShowSmartBanner(false);
    }
  }, [pathname, matchingList, testimonyData, weekStorage]);

  return {
    showTestimonySBanner,
    setShowSmartBanner,
    close,
  }

}