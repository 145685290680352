import styled, { css } from "styled-components/macro";
import { hoverSmallAnimation, slideFadeInLeftAnimation } from "../../../Styles/Animation";
import { IoMailOpenOutline, IoMailUnreadOutline } from "react-icons/io5";
import Logo from "../../Logo";
import UserAvatar from "../../UserAvatar";
import SecondaryItem from "./SecondaryItem";
import ChannelThumbnail from "../../Channel/ChannelThumbnail";
import { device } from '../../../consts/device';
import Icon from '../../../Elements/YtIcons';
import { Link } from 'react-router-dom';
import { FiLogOut, FiUserPlus } from "react-icons/fi";
import { MdChevronRight } from "react-icons/md";

export const StyledLogo = styled(Logo)`
  height: 1.2rem;
  margin-right: 2.5rem;
`;
export const PagesWrapper = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const SignIn = styled(SecondaryItem)`
  color: var(--color-gray1);
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  margin-left: auto;
  margin-right: 0;
  * {
    padding-right: 0;
  }
  a {
    margin-top: 0 !important;
  }
`;
export const SignUp = styled(SecondaryItem)`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  padding: 4px 8px;
  background-color: var(--color-primary-light2);
  border: 1px solid var(--color-primary);
  border-radius: 8px;
  height: 34px;
  margin: 0 0 0 12px;
  > a {
    margin: 0;
    color: var(--color-primary);
    &::before {
      content: unset;
    }
  }

`;
export const EndSection = styled.div`
  display: flex;
  position: relative;
  margin-left: auto;
  align-items: center;
`;
export const StyledUserAvatar = styled(UserAvatar)`
  cursor: pointer;
  filter: contrast(90%);
  div {
    &:hover {
      ${hoverSmallAnimation}
    }
  }
`;
export const AllReadMessageIcon = styled(IoMailOpenOutline)`
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-gray3);
  margin-right: 0.5rem;
  cursor: pointer;

  &:hover {
    color: var(--color-primary-dark);
  }
`;
export const UnreadMessageIcon = styled(IoMailUnreadOutline)`
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-primary);
  margin-right: 0.5rem;
  cursor: pointer;

  &:hover {
    color: var(--color-primary-dark);
  }
`;
export const PopupContainer = styled.div`
  position: absolute;
  top: 2.7rem;
  right: 1.2rem;
  background-color: var(--color-bg1);
  border: 1px solid var(--color-gray4);
  z-index: 999999;
  box-shadow: -2px 2px 4px 0 rgba(0,0,0,0.3);
  border-radius: 24px;
  padding: 16px 0 0;
  width: 364px;
  > .row {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 4px 16px;
    min-height: 35px;
    margin-bottom: 4px;
    color: var(--color-gray1);
    font-size: 16px;
    line-height: 19px; /* 118.75% */
    cursor: pointer;
    &:hover {
      background-color: var(--color-gray6);
    }
    .toggle-theme {
      margin: 0;
      width: unset;
      height: unset;
      cursor: unset;
      display: flex;
      align-items: center;
      pointer-events: none;
    }
    svg.icon {
      width: 16px;
      height: 16px;
      margin: 0;
      color: var(--color-gray3);
      &.mdi {
        > path {
          fill: var(--color-gray3);
        }
      }
      &.theme {
        transform: scale(1.25);
      }
    } 
  }
  ${slideFadeInLeftAnimation}
`;
export const ThumbnailContainer = styled.div`
  padding: 8px 16px;
`;
export const ChannelThumbnailContainer = styled(ThumbnailContainer)`
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--color-gray5);
  background: var(--color-bg1);
  padding: 8px;
  width: calc(100% - 32px);
  margin: 0 16px;
  margin-bottom: 8px;
  .channel-menu-thumbnail {
    justify-content: flex-start;
  }
  @media ${device.mobileL} {
    /* width: calc(100% - 16px); */
    width: 100%;
    margin: 0;
    > a {
      flex: 1;
    }
  }
`
export const ChevRight = styled(MdChevronRight)`
  width: 32px;
  height: 32px;
  color: var(--color-gray3);
`
export const LogoutContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid var(--color-gray4);
  padding: 12px 16px;
  cursor: pointer;
  gap: 16px;
`;

export const LogoutContainer2 = styled(LogoutContainer)`
  border-top: 1px solid var(--color-gray5);
  border-top-width: 0;
  border-bottom: 1px solid var(--color-gray5);
`
export const LogoutContainer3 = styled(LogoutContainer)<{hasHover?: boolean}>`
  border-top: 0;
  ${({hasHover}) => hasHover && css`
    &:hover {
      cursor: pointer;
      background-color: var(--color-gray6);
    }
  `}
  > img {
    --avatar-account-size: 24px;
    min-height: var(--avatar-account-size);
    max-height: var(--avatar-account-size);
    min-width: var(--avatar-account-size);
    max-width: var(--avatar-account-size);
    border-radius: 50%;
  }
  @media ${device.mobileL} {
    justify-content: flex-start;
  }
`
export const MobileOtherAccountsContainer = styled.div`
  width: 100%;
  /* padding: 1rem 0;
  margin-top: -1rem;
  background-color: var(--color-gray6); */
`

export const LogoutIcon = styled(FiLogOut)`
  color: var(--color-gray3);
`;
export const AddAccountIcon = styled(FiUserPlus)`
  color: var(--color-gray3);
  width: 14px;
  height: 14px;
`;

export const LogoutTitle = styled.h3`
  color: var(--color-gray1);
  font-size: 16px;
  line-height: 19px;
`;
export const StyledChannelThumbnail = styled(ChannelThumbnail)`
  #channel-name {
    white-space: nowrap;
  }
  #channel-subscriber {
    font-size: 10px;
    margin-top: 0.3rem;
  }
`;
export const TeammatesLink = styled(Link)`
  color: var(--color-primary);
  background-color: var(--color-gray6);
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 34px;
  border: 1px solid var(--color-primary);
  font-size: 14px;
  margin-right: 1rem;
  font-weight: 600;
  padding: 0 10px 0 16px;
  @media ${device.tabletS} {
    margin-right: 0;
  }
`;
export const ArrowRight = styled(Icon)`
  align-self: center !important;
  color: var(--color-primary) !important;
  fill: var(--color-primary) !important;
  width: 1rem !important;
  height: 1rem !important;
  @media ${device.tabletS} {
    margin-top: 2px;
  }
`;
export const MobileButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const PendingReviewCount = styled.div`
  display: flex;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--color-primary-light2);
  color: var(--color-secondary);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`