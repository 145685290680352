import CountUp from 'react-countup';
import styled from 'styled-components/macro';
import YtIcon from '../../../../Elements/YtIcons';
import Texture from '../images/texture.png';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1e1e1f;
  background-image: url(${Texture});
  background-size: cover;
  height: fit-content;
  margin-left: 0;
  position: relative;
  margin-bottom: 0.5rem;
  padding: 1.5rem;
  border-radius: 14px;
  width: 672px;
  min-height: 506px;
`;
export const Top = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Texts = styled.div`
  width: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2.5rem;
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 100%;
`;
export const VitrineTitle = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: white;
  width: fit-content;
  margin-bottom: 0.5rem;
  text-align: center;
`;
export const EditIcon = styled(YtIcon)`
  color: white;
  width: 1.25rem;
  height: auto;
  margin-left: 0.5rem;
`;
export const Views = styled.h4`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: white;
  padding-right: 1rem;
  padding-left: 1rem;
`;
export const Likes = styled(Views)`
  border-right: 2px solid white;
  border-left: 2px solid white;
`;
export const Videos = styled(Views)``;
export const Thumbnails = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 2rem;
  column-gap: 1rem;
`;

export const ChannelSection = styled.div`
  width: 100%;
  height: 9rem;
  padding: 1rem;
  border: 1px solid white;
  background: linear-gradient(
    92.54deg,
    rgba(255, 255, 255, 0.2) -17.02%,
    rgba(255, 255, 255, 0.08) 119.57%
  );
  backdrop-filter: blur(8px);

  border-radius: 14px;
`;
export const ChanneslTitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
`;
export const ChannelProfiles = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
`;
export const ChannelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
`;
export const ChannelAvatar = styled.img`
  width: 4rem;
  height: 4rem;
  border: 2px solid white;
  border-radius: 50%;
`;
export const ChannelSubscribers = styled.h3`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: white;
  margin-top: -1.5rem;
  background: var(--color-bg1);
  width: 100%;
  text-align: center;
  color: var(--color-gray1);
  height: 2rem;
  padding-top: 0.25rem;
  font-weight: bold;
`;
export const StyledCountUp = styled(CountUp)`
  color: white;
`;
