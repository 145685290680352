import { FC, HTMLAttributes } from "react";


export const CongratesIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = ({...props}) => (
  <svg style={{ width: 40, height: 40 }} {...props} width="88" height="90" viewBox="0 0 88 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6405 72.5033L9.5035 76.6403C8.9549 77.1889 8.61535 77.9016 8.55954 78.6216C8.50372 79.3416 8.73622 80.01 9.20589 80.4797C9.67556 80.9493 10.3439 81.1818 11.0639 81.126C11.784 81.0702 12.4967 80.7307 13.0453 80.1821L17.1823 76.0451C17.7309 75.4965 18.0704 74.7838 18.1262 74.0638C18.182 73.3437 17.9495 72.6754 17.4799 72.2057C17.0102 71.736 16.3418 71.5035 15.6218 71.5593C14.9018 71.6152 14.1891 71.9547 13.6405 72.5033Z" fill="#BDFF8A" />
    <path d="M27.6992 80.4028L27.6992 86.2534C27.6992 87.0293 27.9631 87.7733 28.4327 88.3219C28.9024 88.8705 29.5394 89.1787 30.2036 89.1787C30.8678 89.1787 31.5048 88.8705 31.9745 88.3219C32.4442 87.7733 32.708 87.0293 32.708 86.2534L32.708 80.4028C32.708 79.627 32.4442 78.8829 31.9745 78.3343C31.5048 77.7857 30.8678 77.4775 30.2036 77.4775C29.5394 77.4775 28.9024 77.7857 28.4327 78.3343C27.9631 78.8829 27.6992 79.627 27.6992 80.4028Z" fill="#BDFF8A" />
    <path d="M3.43506 61.9873L9.28564 61.9873C10.0615 61.9873 10.8055 61.7234 11.3541 61.2538C11.9027 60.7841 12.2109 60.1471 12.2109 59.4829C12.2109 58.8187 11.9027 58.1817 11.3541 57.712C10.8055 57.2423 10.0615 56.9785 9.28564 56.9785L3.43506 56.9785C2.65922 56.9785 1.91516 57.2423 1.36656 57.712C0.817965 58.1817 0.509765 58.8187 0.509765 59.4829C0.509766 60.1471 0.817965 60.7841 1.36656 61.2538C1.91516 61.7234 2.65922 61.9873 3.43506 61.9873Z" fill="#BDFF8A" />
    <path d="M74.4013 16.6226L78.5243 12.4997C79.071 11.9529 79.4094 11.2427 79.465 10.5251C79.5206 9.8075 79.2889 9.14141 78.8208 8.67334C78.3528 8.20526 77.6867 7.97356 76.9691 8.02918C76.2515 8.0848 75.5412 8.4232 74.9945 8.96994L70.8716 13.0929C70.3248 13.6396 69.9864 14.3499 69.9308 15.0675C69.8752 15.785 70.1069 16.4511 70.575 16.9192C71.043 17.3873 71.7091 17.619 72.4267 17.5634C73.1443 17.5078 73.8546 17.1693 74.4013 16.6226Z" fill="#BDFF8A" />
    <path d="M60.3906 8.74602L60.3906 2.91531C60.3906 2.14211 60.1277 1.40057 59.6596 0.853839C59.1915 0.307104 58.5567 -5.00176e-05 57.8947 -5.00782e-05C57.2328 -5.01388e-05 56.5979 0.307103 56.1299 0.853838C55.6618 1.40057 55.3988 2.14211 55.3988 2.91531L55.3988 8.74602C55.3988 9.51922 55.6618 10.2608 56.1299 10.8075C56.5979 11.3542 57.2328 11.6614 57.8947 11.6614C58.5567 11.6614 59.1915 11.3542 59.6596 10.8075C60.1277 10.2608 60.3906 9.51922 60.3906 8.74602Z" fill="#BDFF8A" />
    <path d="M84.5768 27.0974L78.7461 27.0974C77.9729 27.0974 77.2314 27.3604 76.6846 27.8284C76.1379 28.2965 75.8308 28.9314 75.8308 29.5933C75.8308 30.2553 76.1379 30.8901 76.6846 31.3582C77.2314 31.8263 77.9729 32.0892 78.7461 32.0892L84.5768 32.0892C85.35 32.0892 86.0916 31.8263 86.6383 31.3582C87.185 30.8901 87.4922 30.2553 87.4922 29.5933C87.4922 28.9314 87.185 28.2965 86.6383 27.8284C86.0916 27.3604 85.35 27.0974 84.5768 27.0974Z" fill="#BDFF8A" />
    <g clipPath="url(#clip0_14871_7101)">
      <rect x="16.9961" y="17.2051" width="53.5874" height="53.5874" rx="26.7937" fill="url(#paint0_linear_14871_7101)" />
      <path d="M39.0958 51.2553L33.5468 45.5313C32.9231 44.888 31.9156 44.888 31.292 45.5313C30.6683 46.1746 30.6683 47.2139 31.292 47.8572L37.9764 54.7525C38.6001 55.3958 39.6075 55.3958 40.2312 54.7525L57.1502 37.2999C57.7738 36.6565 57.7738 35.6173 57.1502 34.974C56.5265 34.3306 55.519 34.3306 54.8954 34.974L39.0958 51.2553Z" fill="white" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_14871_7101" x1="43.7898" y1="17.2051" x2="43.7898" y2="70.7925" gradientUnits="userSpaceOnUse">
        <stop stopColor="#11A433" />
        <stop offset="1" stopColor="#7CB757" />
      </linearGradient>
      <clipPath id="clip0_14871_7101">
        <rect x="16.9961" y="17.2051" width="53.5874" height="53.5874" rx="26.7937" fill="white" />
      </clipPath>
    </defs>
  </svg>
)