import { FC, useMemo } from "react";
import { GetTalentDetailNotSuspended } from "../../../api/interfaces/Talent";
import styled, { css, keyframes } from "styled-components";
import { device } from "../../../consts/device";
import Skeleton from "../../../Elements/Skeleton";
import { useActivityBoardData } from "./useData";
import { MdExpandMore, MdShare } from "react-icons/md";
import { TimeLineSkelleton } from "./TimeLineSkelleton";
import { TimelineItem } from "./TimelineItem";
import { Link } from "react-router-dom";
import useUser from "../../../hooks/useUser";

interface Props {
  talentDetail: GetTalentDetailNotSuspended | undefined;
  loadingUserDetail: boolean;
  onlyHistoryBoard?: boolean;
}

export const ActivityBoard: FC<Props> = ({ talentDetail, loadingUserDetail, onlyHistoryBoard = false }) => {

  const { talentInfo } = useUser();

  const {
    isLoading,
    data,
    firstVideoDate,
    videosNumber,
    Months,
    Years,
    showMore,
    setShowMore,
    hasSeeMore,
    selectedYear,
    setSelectedYear,
    // 
    loadingVideoList,
    vidByMonth
    //
  } = useActivityBoardData(talentDetail?.id);

  const verifiedClientsNumber = useMemo(() => {
    return Object.values(talentDetail?.badges || {}).reduce((a, i) => a + i, 0);
  }, [talentDetail]);

  const vidsGroupByMonth = useMemo(() => {
    if (vidByMonth) {
      return Object.entries(vidByMonth).filter(([key, vlist]) => !!key && vlist.length).reverse();
    }
  }, [vidByMonth]);

  const shareLink = useMemo(() => {
    return `/talent/share-timeline/${talentDetail?.id}`
  }, [talentDetail]);

  if (onlyHistoryBoard) {
    return (
      <ActivityBoardContainer>

        <ActivityBoardContainerInner>
          {Years.length && (
            <div className="col">
              <AxisBox />
              {Years.map(y => (
                <AxisBox key={y}>{y}</AxisBox>
              ))}
            </div>
          )}
        </ActivityBoardContainerInner>

        <ActivityBoardContainerInner>
          <div>
            {Months.map(monthName => (
              <AxisBox key={monthName}>{monthName}</AxisBox>
            ))}
          </div>
          {Years.map(y => (
            <YearRow key={y} active={selectedYear === y && !(loadingUserDetail || isLoading)} onClick={() => setSelectedYear(y)} className="activity-row-item">
              {Months.map((m, i) => {
                const numberOfVideos = data?.[`${y}-${(i + 1).toString().padStart(2, '0')}`] || 0;
                if (numberOfVideos) {
                  return (
                    <Box
                      key={m}
                      vids={numberOfVideos}
                      loading={isLoading || loadingUserDetail}
                    >
                      {numberOfVideos}
                    </Box>
                  )
                } else {
                  return <Box key={m} loading={isLoading || loadingUserDetail} className="activity-empty-box"/>
                }
              })}
            </YearRow>
          ))}
        </ActivityBoardContainerInner>

      </ActivityBoardContainer>
    )
  }


  return (
    <>
      {/* Activity Board */}
      <Container>
        <InfoContainer>
          {
            loadingUserDetail || isLoading
              ? (
                <>
                  {/* <Skeleton isLoading={true} style={{ width: '233px', height: '40px' }} /> */}
                  <Title>Activity Board</Title>
                  <Skeleton isLoading={true} style={{ width: '82px', height: '24px' }} />
                  <Skeleton isLoading={true} style={{ width: '126px', height: '24px' }} />
                  <Skeleton isLoading={true} style={{ width: '111px', height: '24px' }} />
                </>
              )
              : (
                <>
                  <SimpleRow>
                    <Title>Activity Board</Title>
                    {(talentInfo?.id === talentDetail?.id) && (
                      <ShareAction to={shareLink}>
                        <ShareIcon />
                        Share
                      </ShareAction>
                    )}
                  </SimpleRow>
                  <TypoInfo>{videosNumber} Videos</TypoInfo>
                  <TypoInfo>{verifiedClientsNumber} Verified clients</TypoInfo>
                  <TypoInfo>Since {firstVideoDate}</TypoInfo>
                </>
              )
          }
        </InfoContainer>

        <div style={{ maxWidth: '100%' }}>

          <ActivityBoardContainer>

            <ActivityBoardContainerInner>
              {Years.length && (
                <div className="col">
                  <AxisBox />
                  {Years.map(y => (
                    <AxisBox key={y}>{y}</AxisBox>
                  ))}
                </div>
              )}
            </ActivityBoardContainerInner>

            <ActivityBoardContainerInner>
              <div>
                {Months.map(monthName => (
                  <AxisBox key={monthName}>{monthName}</AxisBox>
                ))}
              </div>
              {Years.map(y => (
                <YearRow key={y} active={selectedYear === y && !(loadingUserDetail || isLoading)} onClick={() => setSelectedYear(y)} >
                  {Months.map((m, i) => {
                    const numberOfVideos = data?.[`${y}-${(i + 1).toString().padStart(2, '0')}`] || 0;
                    if (numberOfVideos) {
                      return (
                        <Box
                          key={m}
                          vids={numberOfVideos}
                          loading={isLoading || loadingUserDetail}
                        >
                          {numberOfVideos}
                        </Box>
                      )
                    } else {
                      return <Box key={m} loading={isLoading || loadingUserDetail} />
                    }
                  })}
                </YearRow>
              ))}
            </ActivityBoardContainerInner>

          </ActivityBoardContainer>


          <SowMoreRow>
            {hasSeeMore ? (
              <SeeMore open={showMore} onClick={() => setShowMore(!showMore)}>See {showMore ? 'Less' : 'More'} <MdExpandMore /></SeeMore>
            ) : <div />}
            <Guide>
              <p>Less</p>
              <div /><div /><div /><div />
              <p>More</p>
            </Guide>
          </SowMoreRow>
        </div>
      </Container>

      {/* Timeline */}
      <HeaderContainer>
        <Title>Timeline</Title>
      </HeaderContainer>

      <TimelineContainer loading={loadingVideoList || loadingUserDetail || isLoading}>
        {
          loadingVideoList || loadingUserDetail || isLoading
            ? <TimeLineSkelleton />
            : (
              <>
                {vidsGroupByMonth?.map(([ym, vList]) => (
                  <TimelineItem
                    key={ym}
                    ytVideoList={vList}
                    mList={Months}
                    yearMonth={ym}
                    talentDetail={talentDetail}
                  />
                ))}
              </>
            )
        }

      </TimelineContainer>


      <div style={{ minHeight: 100 }} />

    </>
  )
}

const Container = styled.div`
  padding: 24px 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
  /* border-top: 1px solid var(--color-gray5); */
  border-bottom: 1px solid var(--color-gray5);
  background: var(--color-gray6);
  @media ${device.tabletL} {
    gap: 8px;
    flex-direction: column;
    width: 100vw;
    margin-left: -16px;
    padding: 16px;
  }
`
const InfoContainer = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media ${device.tabletL} {
    padding-top: 0;
  }
`
const ActivityBoardContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  /* gap: 16px; */
  @media ${device.tabletL} {
    gap: 8px;
    > div:last-child {
      overflow-x: auto;
    }
    > div {
      padding-bottom: 8px;
    }
  }
`
const ActivityBoardContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    padding: 4px 4px;
    display: flex;
    gap: 16px;
  }
  > div.col {
    padding: 4px 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  @media ${device.tabletL} {
    > div {
      gap: 8px;
    }
    > div.col {
      padding: 4px 0;
    }
  }
`
const YearRow = styled.div<{ active?: boolean; }>`
  border: 1px solid transparent;
  transition: border 0.4s ease;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  width: max-content;
  &:hover {
    border: 1px solid var(--color-gray5);
  }
  ${({ active }) => active && css`
    border: 1px solid var(--color-gray4) !important;
  `}
`
const Box = styled.div<{ vids?: number; loading?: boolean; }>`
  color: white;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  min-width: 37px;
  min-height: 35px;
  /* border: 1px solid darkgray; */
  border-radius: 4px;
  background: var(--color-bg2);
  ${({ vids }) => (vids && vids >= 1) && css`
    background: #B8D6B0;
  `}
  ${({ vids }) => (vids && vids >= 2) && css`
    background: #79B669;
  `}
  ${({ vids }) => (vids && vids >= 3) && css`
    background: #1F8505;
  `}
  ${({ vids }) => (vids && vids >= 5) && css`
    background: #104502;
  `}
  ${({ loading }) => loading && css`
    animation: ${progressKeyframes} 1.2s ease-in-out infinite;
    background-color: var(--color-gray6);
    background-image: linear-gradient(90deg, var(--color-gray6), var(--color-gray5), var(--color-gray6));
    background-size: 200px 100%;
    background-repeat: no-repeat;
  `}

  @media ${device.tabletL} {
    min-width: 23px;
    min-height: 21px;
  }
`
const AxisBox = styled(Box)`
  color: var(--color-gray2);
  background: transparent;
  border-radius: 0px;
`
const Title = styled.div`
  color: var(--color-gray1);
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.08px;
  white-space: nowrap;
  @media ${device.tabletL} {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.05px;
  }
`
const TypoInfo = styled.div`
  color: var(--color-gray2);
  font-size: 16px;
  line-height: 24px;
`
const SowMoreRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 26px;
  margin-top: 4px;
  padding-left: 50px !important;
  width: 100%;
  @media ${device.tabletL} {
    padding-left: 0 !important;
  }
`
const FlexRow = styled.div`
  display: flex;
  align-items: center;
`
const SeeMore = styled(FlexRow) <{ open: boolean }>`
  cursor: pointer;
  color: var(--color-gray2);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  > svg {
    margin-left: 2px;
    width: 18px;
    height: 18px;
    transition: transform 0.5s ease;
  }
  ${({ open }) => open && css`
    > svg {
      transform: scaleY(-1);
    }
  `}
`
const Guide = styled(FlexRow)`
  gap: 4px;
  > p {
    color: var(--color-gray2);
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  > div {
    min-width: 8px;
    min-height: 8px;
    border-radius: 2px;
    background: var(--color-bg2);
    margin-top: 2px;
  }
  > div:nth-child(3) {
    background: #B8D6B0;
  }
  > div:nth-child(4) {
    background: #66BB50;
  }
  > div:nth-child(5) {
    background: #104502;
  }
`
const progressKeyframes = keyframes`
   0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
`;

// Time Line
const HeaderContainer = styled.div`
  width: 100%;
  margin: 30px 0 32px;
  @media ${device.tabletL} {
    margin: 24px 0 30px;
  }
`
const TimelineContainer = styled.div<{ loading: boolean }>`
  width: 100%;
  position: relative;
  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    min-width: 1px;
    background-image: linear-gradient(var(--color-third) 50%, rgba(255,255,255,0) 0%);
    background-position: right;
    background-size: 1px 4px;
    background-repeat: repeat-y;
    top: 0;
    bottom: 0;
    left: 0;
  }
  @media ${device.tabletL} {
    &::before {
      display: none;
    }
  }
  ${({ loading }) => loading && css`
    &::before {
      display: none;
    }
  `}
`

const SimpleRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: min-content;
`
const ShareAction = styled(Link)`
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;
  padding: 2px 8px;
  border-radius: 18px;
  border: 0.5px solid var(--color-gray2);
  color: var(--color-gray2);
  font-size: 12px;
  line-height: 19px;
  cursor: pointer;
`
const ShareIcon = styled(MdShare)``
