import { useQuery } from "react-query";
import { getHiddenReviewListList } from "../../../../../api/fetchers/Talent";
import { TalentQueryLabels } from "../../../../../api/interfaces/Talent";


export const useHiddenReviewData = (isOwner: boolean) => {
  const query = useQuery(
    [TalentQueryLabels.getHiddenReviewList],
    () => getHiddenReviewListList(),
    { enabled: isOwner }
  );
  return query;
}