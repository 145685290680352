import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { Body16Style } from '../../../Elements/Typo';
import ChannelThumbnail from '../../Channel/ChannelThumbnail';
import UserAvatar from '../../UserAvatar';

import TalentPopupThumbnail from '../TalentPopupThumbnail';
export const StyledUserAvatar = styled(UserAvatar)`
  margin: 1rem auto;
  filter: contrast(90%);
`;
export const MenuItem = styled(Link) <{ active?: boolean; }>`
  ${Body16Style};
  width: 100%;
  margin-bottom: 1.5rem;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
  ${({ active }) => active && css`
    color: var(--color-primary);
    path {
      fill: var(--color-primary);
    }
  `}
`;
export const PrimaryItem = styled(Link)`
  ${Body16Style};
  color: var(--color-primary);
  cursor: pointer;
  padding-bottom: 1.5rem;
  width: 100%;
  text-align: center;
`;
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--color-gray5);
  margin-bottom: 0;
`
export const ThumbnailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid var(--color-gray5);
  padding-bottom: 4px;
  margin-bottom: 1rem;
  > .row {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 4px 8px;
    min-height: 35px;
    margin-bottom: 4px;
    color: var(--color-gray1);
    &.red {
      color: var(--color-primary);
    }
    font-size: 16px;
    line-height: 19px; /* 118.75% */
    cursor: pointer;
    &:hover {
      background-color: var(--color-gray6);
    }
    .toggle-theme {
      margin: 0;
      width: unset;
      height: unset;
      cursor: unset;
      display: flex;
      align-items: center;
      pointer-events: none;
    }
    svg.icon {
      width: 16px;
      height: 16px;
      margin: 0;
      color: var(--color-gray3);
      &.mdi {
        > path {
          fill: var(--color-gray3);
        }
      }
      &.theme {
        transform: scale(1.25);
      }
    } 
  }
`;
export const StyledChannelThumbnail = styled(ChannelThumbnail)`
  justify-content: center;
`;

export const LogoutContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid var(--color-gray5);
  border-bottom: 1px solid var(--color-gray5);
  padding: 0.8rem 0 0.8rem 8px;
  gap: 16px;
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;
    margin: 0;
    color: var(--color-gray3);
  }
`;
export const LogoutTitle = styled.h3`
  color: var(--color-gray1);
  font-size: 16px;
  line-height: 19px; /* 118.75% */
`;

export const StyledTalentPopupThumbnail = styled(TalentPopupThumbnail)`
  justify-content: center;
`;
