import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components/macro";
import { device, deviceMin } from '../../../consts/device';
import useUser from '../../../hooks/useUser';
import {
  ModalContainer,
  ModalWrapper,
  ModalGrayBlank,
  ModalGrayDash,
  ModalContent,
} from '../../../Styles/ModalStyles';
import { MdClose } from "react-icons/md";
import { useMessageChannel } from "./useMessageChannel";
import { Button } from "../../../Elements/Button";
import { handleKeyDown } from "../../../Utils/TextAreaExpander";
import { useMutation, useQueryClient } from "react-query";
import ErrorToast from "../../../Elements/Toast/Error";
import { AxiosError } from "axios";
import { useQuill } from "react-quilljs";
import MagicUrl from 'quill-magic-url';
import DOMPurify from 'dompurify';
import '../../../Styles/_quil.scss';
import { TalentPurchasePopup } from "./PurchasePopup";
import { makeOutreach } from "../../../api/fetchers/Talent";
import { CongratesIcon } from "../../../Elements/YtIcons/CongratesIcon";
import { UserTalentQueryLabels } from "../../../api/interfaces/User/Talent";
import { ProposalsAction } from "../../../Pages/ChannelList/Components";


export const MessageChannel: React.FC = () => {

  const { quill, quillRef, Quill } = useQuill(
    {
      modules: {
        toolbar: [
          [],
        ],
        magicUrl: true,
      },
      placeholder: 'e.g. I am looking for a ...',
      theme: 'snow',  // or 'bubble',
    }
  )
  if (Quill && !quill) {
    try {
      Quill.register('modules/magicUrl', MagicUrl);
      const Link = Quill.import('formats/link');
      class MyLink extends Link {
        // @ts-ignore
        static create(value) {
          const node = super.create(value);
          node.setAttribute('target', '_blank');
          return node;
        }
      }
      Quill.register(MyLink, true);
    } catch (error) {
      console.error(error)
    }
  }

  const { talentInfo, refetchTalentInfo } = useUser();
  const { close, targetChannel } = useMessageChannel();
  const [step, setStep] = useState<1 | 2 | 3>(2);

  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    ({ message, subjectText }: { message: string; subjectText: string; }) => makeOutreach(
      subjectText, message, targetChannel?.id || ''
    ),
    {
      onSuccess: ({ success, error }) => {
        if (success) {
          refetchTalentInfo();
          queryClient.refetchQueries([UserTalentQueryLabels.getReachoutList])
          setStep(3)
        } else {
          ErrorToast(error || 'Something went wrong!');
        }
      },
      onError: (error: AxiosError) => {
        ErrorToast(error.response?.data?.error || 'Something went wrong!');
      }
    }
  );
  const submitSendMessage = useCallback(() => {
    if (!quill) return;
    const hyperMessage = DOMPurify.sanitize(quill.root.innerHTML)
    mutate({
      message: hyperMessage,
      subjectText: subject,
    });
  }, [mutate, subject, quill]);

  const hasChatQuota = useMemo(() => !!talentInfo?.outreachQuota, [talentInfo]);

  const [hyperText, setHyperText] = useState('')

  useEffect(() => {
    if (!quill) return;
    quill.on('text-change', function (delta, oldDelta, source) {
      if (source == 'user') {
        setHyperText((quill?.getText() || ''))
      }
    });
  }, [quill]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <ModalContainer>
      <ModalWrapper>
        <ModalGrayBlank onClick={close} />
        <StyledModalContent>
          <ModalGrayDash />

          {/* Congrates */}
          {step === 3 && (
            <>
              <Row>
                <HSpacer />
                <CloseIcon onClick={close} />
              </Row>
              <VSpacer />

              <RowCenter style={{ gap: 8 }}>
                <CongratesIcon />
              </RowCenter>

              <RowCenter style={{ gap: 8 }}>
                <Title>Message Sent Successfully!</Title>
              </RowCenter>
              <RowCenter style={{ gap: 8 }}>
                <Subtitle>Your cover letter and proposal have been delivered. If the channel is interested , they will reach out to you. You can continue the conversation and share any additional documents through your YT Jobs Mailbox.</Subtitle>
              </RowCenter>
              <VSpacer />
              <RowCenter>
                <Action
                  onClick={() => close()}
                >Done!</Action>
              </RowCenter>
            </>
          )}

          {/* Messaging Util */}
          {step === 2 && (
            <>
              <Row>
                <StyledProposalsAction>{talentInfo?.outreachQuota} proposal left</StyledProposalsAction>
                <HSpacer />
                <CloseIcon onClick={close} />
              </Row>
              <div style={{minHeight: 8}} />
              <Row>
                <Title>Message {targetChannel?.name}</Title>
                <HSpacer />
              </Row>
              <VSpacer />
              <Subtitle>
                Please craft a clear, personalized cover letter in this box. Include a link to your detailed proposal on platforms like Google Docs.
              </Subtitle>
              <VSpacer />
              <Label>Subject</Label>
              <SubjectArea
                autoFocus={true}
                placeholder={'e.g. I am looking for a full-time Video Editor ...'}
                onChange={(e) => e.target.value.length <= 100 && setSubject(e.target.value)}
                value={subject}
              />
              <Typo>{subject?.length || 0}/100</Typo>
              <Label>Message</Label>
              <EditorContainer>
                <div ref={quillRef} />
              </EditorContainer>
              <MessageArea
                onFocus={handleKeyDown}
                onKeyDown={(e: React.SyntheticEvent<HTMLTextAreaElement, KeyboardEvent>) => {
                  if (
                    ((message as string)?.length >= 1000 && e.nativeEvent.key !== 'Backspace')
                  ) {
                    e.stopPropagation();
                    e.preventDefault();
                  }
                  handleKeyDown(e);
                }}
                placeholder={'e.g. I am looking for a full-time Video Editor ...'}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                style={{ display: 'none' }}
              />
              <Typo>{hyperText?.length || 0}/1000</Typo>
              <Row>
                <Button
                  onClick={close}
                  variant="NAKE"
                >
                  Cancel
                </Button>
                <HSpacer />
                <Button
                  disabled={(hyperText.replace(' ', '').replace('\n', '')).length < 4 || !subject.length || isLoading}
                  isLoading={isLoading}
                  style={{ minWidth: 167 }}
                  onClick={submitSendMessage}
                >
                  Send Message
                </Button>
              </Row>
            </>
          )}


          {/* Show Purchace modal */}
          {step === 1 && (
            <>
              <PurchaseContainer>
                <TalentPurchasePopup
                  isModal={false}
                  isCloseAble={true}
                  close={close}
                  onAction={() => setStep(2)}
                  subtitle={hasChatQuota ? undefined : `You've used all your free messages.\nTo explore more talents and streamline your hiring process, unlock Discover & Hire. Activate it for 30 days to efficiently connect with and evaluate potential team members.`}
                />
              </PurchaseContainer>
            </>
          )}

        </StyledModalContent>
      </ModalWrapper>
    </ModalContainer >
  );
};

const EditorContainer = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  > .ql-toolbar {
    display: none !important;
  }
  .ql-editor {
    border: 0.5px solid var(--color-gray4);
    border-radius: 16px;
    border-radius: 10px;
    padding: 8px 16px;
    &:focus {
      border: 0.5px solid var(--color-gray3);
    }
    &::before {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: var(--color-gray4);
      padding: 1px 16px;
    }
    > p {
      color: var(--color-gray1);
      font-size: 16px;
      font-weight: 600;
      font-family: "Open Sans", "Poppin", "Helvetica", "Arial", "sans-serif";
    }
    @media ${deviceMin.desktop} {
      &::-webkit-scrollbar {
        width: 8px;
        background-color: var(--color-gray5);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--color-gray4);
        border-radius: 5px;
      }
    }
    @media ${device.tabletS} {
      min-height: 250px;
      max-height: 250px;
      > p {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  @media ${device.tabletS} {
    min-height: 250px;
  }
`
const StyledModalContent = styled(ModalContent)`
  width: 613px;
  padding: 1.5rem 1.25rem 1.5rem;
  max-height: 80vh;
  overflow-y: auto;
  @media ${device.mobileL} {
    padding: 1rem 1rem 7rem;
    width: 100%;
  }
`;
const Title = styled.h2`
  color: var(--color-gray1);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const Subtitle = styled.p`
  color: var(--color-gray2);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  > span {
    font-weight: 600;
    color: var(--color-primary);
    text-decoration-line: underline;
    cursor: pointer;
  }
`;
const CloseIcon = styled(MdClose)`
  width: 24px;
  height: 24px;
  color: var(--color-gray1);
  cursor: pointer;
  @media ${device.mobileL} {
    display: none;
  }
`
const Row = styled.div`
  display: flex;
  align-items: center;
`
const HSpacer = styled.div`
  flex: 1;
`
const VSpacer = styled.div`
  min-height: 16px;
`
const Label = styled.label`
  margin-bottom: 8px;
  color: var(--color-gray2);
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
`;
const inputStyles = css`
  line-height: 30px;
  margin-bottom: 0;
  width: 100%;
  min-height: 40px;
  color: var(--color-gray1);
  resize: none;
  font-size: 16px;
  font-weight: 600;
  font-family: "Open Sans", "Poppin", "Helvetica", "Arial", "sans-serif";
  border: 0.5px solid var(--color-gray4);
  border-radius: 10px;
  padding: 8px 16px;
  ::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-gray4);
  }
  &:focus {
    border: 0.5px solid var(--color-gray3);
  }
`
const SubjectArea = styled.input`
  ${inputStyles}
`;
const MessageArea = styled.textarea`
  ${inputStyles};
  min-height: 160px;
  @media ${deviceMin.desktop} {
    &::-webkit-scrollbar {
      width: 6px;
      background-color: var(--color-gray5);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray4);
      border-radius: 5px;
    }
  }
`;
const Typo = styled.p`
  font-size: 12px;
  color: var(--color-gray3);
  margin-top: 4px;
  margin-bottom: 1rem;
`;
const Action = styled(Button)`
  @media ${device.mobileL} {
    width: 100%;
  }
`
const PurchaseContainer = styled.div`
  > div {
    position: unset;
    transform: unset;
    padding: 0;
    > p {
      white-space: pre-line;
    }
  }
`
const RowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  > * {
    text-align: center;
  }
  h2 {
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
  }
  p {
    line-height: 26px; 
  }
  svg {
    width: 52px !important;
    height: 52px !important;
  }
`
const StyledProposalsAction = styled(ProposalsAction)`
  transform: scale(1) !important;
  cursor: default;
`


