import { ButtonHTMLAttributes, ReactNode, useMemo } from "react";
import styled, { css } from "styled-components/macro";
import Loading from "../YtIcons/Loading";
import { PopupPosition } from "reactjs-popup/dist/types";
import { device } from "../../consts/device";
import HoverPopup from 'reactjs-popup';

/**
 * Primary UI component for user interaction
 */
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  variant?: "PRIMARY" | "SECONDARY" | "NAKE" | "OUTLINE" | "PRIMARY-BLACK";
  children?: ReactNode;
  isLoading?: boolean;
  tooltip?: string;
  tooltipPosition?: PopupPosition;
}
export const Button = ({
  variant = "PRIMARY",
  children,
  disabled = false,
  isLoading = false,
  type = "button",
  tooltip,
  tooltipPosition = 'bottom left',
  ...props
}: ButtonProps) => {

  const loadingColor = useMemo(() => {
    switch (variant) {
      case 'PRIMARY':
        return 'white';
      case 'PRIMARY-BLACK':
        return 'var(--color-bg1)';
      case 'OUTLINE':
        return 'var(--color-gray1)';
      default:
        return "var(--color-primary)";
    }
  }, [variant]);

  const Component = useMemo(() => (
    <Container
      type={type}
      variant={variant}
      {...props}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <Loading
          fill={loadingColor}
        />
      ) : (
        children
      )}
    </Container>
  ), [children, loadingColor, isLoading, disabled, props, type, variant])

  if (disabled && tooltip) {
    return (
      <HoverPopup
        trigger={() => <div>{Component}</div>}
        position={tooltipPosition}
        arrow={true}
        on={['hover']}
      >
        <TooltipText>
          {tooltip}
        </TooltipText>
      </HoverPopup>
    )
  }

  return Component;
};

export const ButtonCss = css<{
  disabled: boolean | undefined;
  variant: "PRIMARY" | "SECONDARY" | "NAKE" | "OUTLINE" | "PRIMARY-BLACK";
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  width: auto;
  height: 3.5rem;
  padding: 0 28px;
  border-radius: 10px;
  background-color: var(--color-primary);
  color: white;
  font-weight: bold;
  transition: all 0.3s;
  > svg {
    width: 16px;
    height: 16px;
  }
  ///////////////secondary button ///////////////////
  ${({ variant }) =>
    variant === "SECONDARY" && css`
      background: var(--color-primary-light2);
      border: 1px solid var(--color-primary-light1);
      color:var(--color-primary);
    `
  }
  ///////////////primary black ///////////////////
  ${({ variant }) =>
    variant === "PRIMARY-BLACK" && css`
      background: var(--color-gray1);
      border: 1px solid transparent;
      color: var(--color-bg1);
      height: 38px;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      gap: 4px;
      padding: 0 14px 0 12px;
      min-width: 75px;
    `
  }
  ///////////////outline ///////////////////
  ${({ variant }) =>
    variant === "OUTLINE" && css`
      background: var(--color-gray6);
      border: 1px solid var(--color-gray2);
      color: var(--color-gray2);
      height: 38px;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      gap: 4px;
      padding: 0 14px 0 12px;
      min-width: 75px;
    `
  }
  ///////////////Nake button ///////////////////
  ${({ variant }) =>
    variant === "NAKE" && css`
      background: transparent;
      border: none;
      color:var(--color-gray3);
      text-decoration: underline;
      padding:0;
  `}


  //////////////disabled///////////////////////
  ${({ disabled, variant }) => {
    if (disabled && variant === "PRIMARY")
      return `background-color:var(--color-primary-light1);
      cursor:not-allowed;`;
    if (disabled && variant === "SECONDARY")
      return `color:var(--color-primary-light1);
      cursor:not-allowed;`;
    if (disabled && variant === 'PRIMARY-BLACK')
      return css`
    opacity: 0.5;
    cursor:not-allowed;
    `
    if (disabled && variant === 'OUTLINE')
      return css`
    opacity: 0.5;
    cursor:not-allowed;
    `
  }}
  //////////////hover///////////////////////
    ${({ disabled, variant }) => {
    if (!disabled && variant === "PRIMARY")
      return `:hover{background-color:var(--color-primary-dark);}`;
    if (!disabled && variant === "SECONDARY")
      return `:hover{background-color:var(--color-primary);
      color:white;
      }`;
    if (!disabled && variant === "NAKE")
      return `:hover{font-weight:bold;
      }`;
    if (!disabled && variant === "PRIMARY-BLACK")
      return css`
      :hover{
        font-weight: bold;
      }
      `;
    if (!disabled && variant === "OUTLINE")
      return css`
      :hover{
        font-weight: bold;
      }
      `;
  }}
`;

const Container = styled.button`
  ${ButtonCss}
`;

const TooltipText = styled.p`
  font-size: 14px;
  line-height: 28px;
  background-color: var(--color-tooltip-bg);
  color: white;
  border-radius: 10px;
  padding: 8px 16px;
  margin-left: -16px;
  position: relative;
  max-width: 290px;
  @media ${device.tabletS} {
    max-width: 280px;
    margin-left: -6rem;
  }
`;
