import { useMutation, useQuery } from "react-query"
import { TalentQueryLabels } from "../../api/interfaces/Talent"
import { buyProduct, getTalentProductList, getTalentSubscriptions } from "./api"
import useUser from "../../hooks/useUser"
import { UserRoles } from "../../Interfaces"
import { AxiosError } from "axios"
import ErrorToast from "../../Elements/Toast/Error"



export const useTalentProductList = (random: number = 0) => {

  const { role } = useUser();

  const query = useQuery(
    [TalentQueryLabels.getProductList, random],
    () => getTalentProductList(),
    {
      enabled: role === UserRoles.TALENT
    }
  )

  return query
}
export const useTalentSubscriptions = (random: number = 0) => {

  const { role } = useUser();

  const query = useQuery(
    [TalentQueryLabels.getSubscriptions, random],
    () => getTalentSubscriptions(),
    {
      enabled: role === UserRoles.TALENT
    }
  )

  return query
}

export const useBuyProductAction = () => {
  const action = useMutation(
    (pId: string) => buyProduct(pId),
    {
      onSuccess: ({paymentUrl}) => {
        window.location.href = paymentUrl;
      },
      onError: (e: AxiosError<{message: string}>) => {
        ErrorToast(e.response?.data.message || 'Something went wrong!')
      }
    }
  )
  return action;
}