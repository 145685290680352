import React, { HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import {
  StyledLogo,
  CopyRight,
} from './StyledComponents';
import { device } from '../../consts/device';

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}
export const MinimalFooter: React.FC<FooterProps> = ({ className, ...props }) => {
  return (
    <Container {...props} className={className}>
      <Wrapper>
        <StyledLogo variant='LIGHT' />
        <CopyRight>Copyright © {(new Date().getFullYear())} ytjobs.co, All Rights Reserved</CopyRight>
      </Wrapper>
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  background: #1e1e1f;
  margin-top: auto;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--max-width-page);
  padding: 30px 0;
  margin: auto;
  img {
    margin: 0 !important;
  }
  h3 {
    position: unset;
    margin: 0 !important;
    font-size: 14px;
  }
  @media ${device.mobileL} {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    padding: 24px 16px;
    h3 {
      text-align: left;
    }
  }
`;