import { PaginateQueryResponse } from ".";
import { TalentInfo, YoutubeVideosCollection, YtVideo } from "../../Interfaces/Talent";
import { Voucher } from "./User/Talent";

export interface GetTalentDetailNotSuspended extends TalentInfo {
  vouchedByCurrentUser: boolean | null;
}
export interface SuspendAccountResponse {
  isSuspended: boolean;
}
export type GetTalentDetailResponse =
  | GetTalentDetailNotSuspended
  | SuspendAccountResponse;
export type GetTalentListResponse = PaginateQueryResponse<TalentInfo>;

export type Add2DiscoveryListRes = {
  success: boolean;
  succeeded: number[];
  failed: number[];
}

export type GetTalentVideosListResponse = PaginateQueryResponse<YtVideo>;

export enum TalentQueryLabels {
  getInfinitTalentList = 'getInfinitTalentList',
  getInfinitTalentListDiscovery = 'getInfinitTalentListDiscovery',
  getTalentDetail = 'getTalentDetail',
  getTalentActivityBoard = 'getTalentActivityBoard',
  getTalentTimelineVideos = 'getTalentTimelineVideos',
  getVouchesList = 'getVouchesList',
  getHiddenReviewList = 'getHiddenReviewList',
  getInfiniteTalentVideos = 'getInfiniteTalentVideos',
  getVotedBannerInfo = 'getVotedBannerInfo',
  getProductList = 'getProductList',
  getSubscriptions = 'getSubscriptions',
  get1mPackage = 'get1mPackage',
}
///////ٰVouch///////////

export type GetVouchesListResponse = {
  success: boolean;
  vouches: Voucher[];
}; ////////////////

export type TalentPersonalYT = {
  abvSubscribers: string;
  abvVideoCount: string;
  abvViews: string;
  avatar: string;
  id: string;
  name: string;
}

export type TalentVerifiedInfo = {
  linkTwitter: string;
  linkYoutube: TalentPersonalYT;
  location: string;
  success: boolean;
  message: string;
}
export type TCountryCode = {
  id: string;
  name: string;
  iso2: string;
  phoneCode: string;
  emoji: string;
  query: string;
}


// User Multi Import
export enum ImportLinkStatus {
  error = 'error',
  waiting = 'waiting',
  imported = 'imported'
}
export enum ImportStatus {
  progressing = 'progressing',
  ready = 'ready'
}
export interface ImportLinkResource {
  url: string;
  status: ImportLinkStatus;
  errorMessage: string;
}

export interface AskImportResponse {
  success: boolean;
  status: ImportStatus;
  importLinks: ImportLinkResource[];
  message?: string
}

export interface TalentYouTubeVideos {
  youtubeVideos: Pick<YoutubeVideosCollection, 'statistics' | 'videos'>
}

// export type TalentStatistics =  Pick<YoutubeVideosCollection, 'statistics'>
export type TalentStatistics =  {
  abvComments: string;
  abvLikes: string;
  abvViews: string;
  counts: number;
  views?: number;
}