export const siteMetadata = {
  defaultTitle: `YT Jobs | The YouTube Creators Job Board`,
  defaultDescription: `Whether a talented YouTube professional or creator, here you can find your dream job or next ideal teammate.`,
  siteUrl: `https://ytjobs.co`,
  keywords:
    'YT Jobs, Youtube, Youtuber, youtube professional, youtube creator, youtube job board',
  legalName: 'YT Jobs',
  email: 'support@ytjobs.co',
};

export const pagesMetadata = {
  login: {
    title: `Login to YT Jobs`,
    description: `Login to YT Jobs by express link...`,
    keywords: `YT Jobs, Youtube, login, express link, Youtuber`,
  },
  talentSignup: {
    title: `Join YT Jobs!`,
    description: `Sign up on YT Jobs as a talent to apply for many jobs posted by YouTubers...`,
    keywords: `YT Jobs, Youtube, sign up, join, Youtube creator`,
  },
  channelSignup: {
    title: `Start Hiring on YT Jobs!`,
    description: `Start Hiring the best-talented professionals for your YouTube channel...`,
    keywords: `YT Jobs, Youtube, Youtuber, find teammate, post job, job posting`,
  },
};
