import { convertToCurrency } from '.';
import { ExpertiseID, YtjobsIcons } from '../Interfaces';
import { JobLocationTypes, JobTypes, TVideoType } from '../Interfaces/Job';

export function getSmartSalaryLabel(jobType: JobTypes): string {
  if (jobType === JobTypes.FullTime) return 'Per Year';
  if (jobType === JobTypes.PartTime) return 'Per Hour';
  return 'Per Project';
}
export function getSmartSalary(
  minSalary: number | undefined | null,
  maxSalary: number | undefined | null,
  jobType?: JobTypes
): string {
  // نه مین سالاری مشخص شده نه ماکس
  if (!!!minSalary && !!!maxSalary) return '-';

  // ماکس سالاری مشخص شده ولی مین سالاری خیر
  if (!!!minSalary && !!maxSalary)
    return `${convertToCurrency.format(maxSalary)} ${jobType ? getSmartSalaryLabel(jobType) : ''
      }`;

  //مین سالاری مشخص شده ولی ماکس سالاری خیر
  if (!!minSalary && !!!maxSalary)
    return `${convertToCurrency.format(minSalary)} ${jobType ? getSmartSalaryLabel(jobType) : ''
      }`;
  // مین سالاری و مکس سالاری مشخص شده
  return `${convertToCurrency.format(
    minSalary || 0
  )}-${convertToCurrency.format(maxSalary || 0)} ${jobType ? getSmartSalaryLabel(jobType) : ''
    }`;
}
export function getSmartVideoType(vType: TVideoType): string {
  if (vType === 'all') return 'All Formats';
  if (vType === 'normal') return 'Long-form';
  if (vType === 'short') return 'Short-form';
  return 'All Formats';
}
export function convertExpertidToJobTitle(
  expertise: ExpertiseID | number | undefined
): string {
  const stringExpertise = expertise?.toString();
  switch (stringExpertise) {
    case ExpertiseID.VideoEditing:
      return 'Video Editor';
    case ExpertiseID.CreativeDirector:
      return 'Creative Director';
    case ExpertiseID.ThumbnailDesigner:
      return 'Thumbnail Designer';
    case ExpertiseID.ChannelManager:
      return 'Channel Manager';
    case ExpertiseID.YouTubeStrategist:
      return 'YouTube Strategist';
    case ExpertiseID.ScriptWriter:
      return 'Scriptwriter';
    case ExpertiseID.youTubeProducer:
      return 'YouTube Producer';
    case ExpertiseID.other:
      return 'Other';

    default:
      return '';
  }
}
export function convertExpertidFromUrlToJobTitle(
  expertiseInUrl: string | undefined
): string {
  switch (expertiseInUrl) {
    case 'video_editor':
      return 'Video Editor';
    case 'creative_director':
      return 'Creative Director';
    case 'thumbnail_designer':
      return 'Thumbnail Designer';
    case 'channel_manager':
      return 'Channel Manager';
    case 'youtube_strategist':
      return 'YouTube Strategist';
    case 'scriptwriter':
      return 'Scriptwriter';
    case 'youtube_producer':
      return 'YouTube Producer';
    case 'other':
      return 'Other';
    default:
      return 'All Categories';
  }
}
export function converExpertidToSocialHashtag(
  expertise: ExpertiseID | number | undefined
): string {
  const stringExpertise = expertise?.toString();
  switch (stringExpertise) {
    case ExpertiseID.VideoEditing:
      return 'VideoEditor';
    case ExpertiseID.CreativeDirector:
      return 'CreativeDirector';
    case ExpertiseID.ThumbnailDesigner:
      return 'ThumbnailDesigner';
    case ExpertiseID.ChannelManager:
      return 'ChannelManager';
    case ExpertiseID.YouTubeStrategist:
      return 'YouTubeStrategist';
    case ExpertiseID.ScriptWriter:
      return 'Scriptwriter';

    default:
      return '';
  }
}
export function convertExpertidFromUrlToId(
  label: string
): ExpertiseID | undefined {
  switch (label) {
    case 'video_editor':
      return ExpertiseID.VideoEditing;
    case 'creative_director':
      return ExpertiseID.CreativeDirector;
    case 'thumbnail_designer':
      return ExpertiseID.ThumbnailDesigner;
    case 'channel_manager':
      return ExpertiseID.ChannelManager;
    case 'youtube_strategist':
      return ExpertiseID.YouTubeStrategist;
    case 'scriptwriter':
      return ExpertiseID.ScriptWriter;
    case 'youtube_producer':
      return ExpertiseID.youTubeProducer;

    default:
      return undefined;
  }
}
export function convertJobTitleToExpertid(
  label: string
): ExpertiseID | undefined {
  switch (label) {
    case 'Video Editor':
      return ExpertiseID.VideoEditing;
    case 'Creative Director':
      return ExpertiseID.CreativeDirector;
    case 'Thumbnail Designer':
      return ExpertiseID.ThumbnailDesigner;
    case 'Channel Manager':
      return ExpertiseID.ChannelManager;
    case 'YouTube Strategist':
      return ExpertiseID.YouTubeStrategist;
    case 'Scriptwriter':
      return ExpertiseID.ScriptWriter;
    case 'YouTube Producer':
      return ExpertiseID.youTubeProducer;
    case 'Other':
      return ExpertiseID.other;

    default:
      return undefined;
  }
}
export function convertJobLocationTypeId(
  locationType: JobLocationTypes | undefined
): string {
  if (!locationType) return '';
  if (locationType === JobLocationTypes.Hybrid) return 'Hybrid';
  else if (locationType === JobLocationTypes.Onsite) return 'Onsite';
  return 'Remote';
}
export function convertJobType(type: string | undefined): string {
  if (!type) return '';
  if (type === '3') {
    return 'Full-time';
  } else if (type === '5') {
    return 'Part-time';
  }
  return 'Per project';
}
export function convertExpertiseIdFromIdToIconVariant(
  expertise: ExpertiseID | undefined
): YtjobsIcons {
  const smartExperties =
    typeof expertise != 'string'
      ? (String(expertise) as ExpertiseID)
      : expertise;
  switch (smartExperties) {
    case ExpertiseID.VideoEditing:
      return YtjobsIcons.videoEditor;
    case ExpertiseID.CreativeDirector:
      return YtjobsIcons.creativeDirector;
    case ExpertiseID.ThumbnailDesigner:
      return YtjobsIcons.thumbnailDesigner;
    case ExpertiseID.ChannelManager:
      return YtjobsIcons.channelManager;
    case ExpertiseID.ScriptWriter:
      return YtjobsIcons.scriptWriter;
    case ExpertiseID.YouTubeStrategist:
      return YtjobsIcons.youTubeStrategist;
    case ExpertiseID.youTubeProducer:
      return YtjobsIcons.youTubeProducer;
    case ExpertiseID.other:
      return YtjobsIcons.jobOtherType;

    default:
      return YtjobsIcons.error;
  }
}
