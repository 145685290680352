import { useCallback, useMemo, useState } from "react";

export const useData = () => {

  const [nextStep, setNextStep] = useState(1);
  const [prevStep, setPrevStep] = useState(1);

  const [message, setMessage] = useState({
    subject: '',
    introduction: '',
    channelAnalysis: '',
    workExperience: '',
    collaboration: ''
  })

  const finalMessage = useMemo(() => {
    let finalMessage = ''
    if (message.introduction) {
      finalMessage += 
      `<h3>Introduction and Background</h3>${message.introduction}\n`
    }
    if (message.channelAnalysis) {
      finalMessage += 
      `<h3>Channel Analysis and Solutions</h3>${message.channelAnalysis}\n`
    }
    if (message.workExperience) {
      finalMessage += 
      `<h3>Relevant Work Experience and Links</h3>${message.workExperience}\n`
    }
    if (message.collaboration) {
      finalMessage += 
      `<h3>Collaboration Terms</h3>${message.collaboration}\n`
    }
    return finalMessage
  }, [message])
  // Subject
  const [subject, setSubject] = useState('')
  const [introduction, setIntroduction] = useState('')
  const [channelAnalysis, setChannelAnalysis] = useState('')
  const [workExperience, setWorkExperience] = useState('')
  const [collaboration, setCollaboration] = useState('')


  const changeStepCallback = useCallback(() => {
    setSubject(message.subject)
    setIntroduction(message.introduction)
    setChannelAnalysis(message.channelAnalysis)
    setWorkExperience(message.workExperience)
    setCollaboration(message.collaboration)
  }, [message])

  const goToNextStep = useCallback(() => {
    setPrevStep(nextStep);
    setNextStep(nextStep + 1);
    changeStepCallback()
  }, [nextStep, changeStepCallback]);
  const goToPrevStep = useCallback(() => {
    setPrevStep(nextStep);
    setNextStep(nextStep - 1);
    changeStepCallback()
  }, [nextStep, changeStepCallback]);
  const goToStep = useCallback((step: number) => {
    setPrevStep(nextStep);
    setNextStep(step);
    changeStepCallback()
  }, [nextStep, changeStepCallback]);

  return {
    nextStep, setNextStep,
    prevStep, setPrevStep,
    goToNextStep,
    goToPrevStep,
    goToStep,
    //
    subject, setSubject,
    introduction, setIntroduction,
    channelAnalysis, setChannelAnalysis,
    workExperience, setWorkExperience,
    collaboration, setCollaboration,
    //
    message,
    setMessage,
    //
    finalMessage
  }

}

export type TUtils = {
  utils: ReturnType<typeof useData>;
  cancelAll?: () => void;
};