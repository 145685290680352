import { useQuery } from "react-query";
import { TalentInfo } from "../../../../../Interfaces/Talent";
import { getVouchesList } from "../../../../../api/fetchers/Talent";
import { GetVouchesListResponse, TalentQueryLabels } from "../../../../../api/interfaces/Talent";


export const useVouchesData = (talentInfo: TalentInfo | undefined) => {
  const voucheQuery = useQuery<GetVouchesListResponse>(
    [TalentQueryLabels.getVouchesList, talentInfo?.id],
    () => getVouchesList(talentInfo?.id as string),
    { enabled: !!talentInfo }
  );
  return voucheQuery;
}